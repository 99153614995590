import {ILogin} from "../../models/login";
import {LoginAction, LoginActionTypes} from "../actionTypes/login.actionTypes";

export interface ILoginState {
    login: ILogin,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string,
    emailRecover: string,
    codeRecoverSent: boolean,
    codeRecoverConfirm: boolean,
    passwordChanged: boolean
}

const initialState: ILoginState = {
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    errorMessage: "",
    successMessage: "",
    login: {
        fullName: "",
        username: "",
        email:"",
        department: "",
        token:"",
        roles: [],
        free: false
    },
    emailRecover: "",
    codeRecoverSent: false,
    codeRecoverConfirm: false,
    passwordChanged: false
}

const loginReducer = (state: ILoginState = initialState, action: LoginAction) => {
    switch (action.type) {
        case LoginActionTypes.LOGIN_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case LoginActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                login: action.payload
            }
        case LoginActionTypes.LOGIN_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage: 'Ocorreu um erro ao iniciar sessao. Tente novamente!',
                successMessage: ''
            }
        case LoginActionTypes.LOGOUT_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
            }
        case LoginActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: false,
                login: {}
            }
        case LoginActionTypes.LOGOUT_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage: 'Occoreu um erro ao efectuar o logout. Tente novamente!',
                successMessage: ''
            }
        case LoginActionTypes.RECOVER_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage: '',
                successMessage: ''
            }
        case LoginActionTypes.RECOVER_SUCCESS:
            return  {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                successMessage: "Codigo de recuperacao de senha enviado com sucesso para o e-mail.",
                errorMessage: '',
                emailRecover: action.payload.email,
                codeRecoverSent: action.payload.codeSent
            }
        case LoginActionTypes.RECOVER_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage: 'Ocorreu um erro ao enviar o codigo de recuperacao. Tente novamente!',
                successMessage: ''
            }
        case LoginActionTypes.RECOVER_CONFIRM_CODE_SAVING:
            return {
                ...state,
                errorMessage: "",
                isSaving: true,
                error: false,
                success: false,
                successMessage: ''
            }
        case LoginActionTypes.RECOVER_CONFIRM_CODE_SUCCESS:
            return  {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                successMessage: "Codigo confirmado com successo.",
                errorMessage: '',
                emailRecover: action.payload.email,
                codeRecoverConfirm: action.payload.codeConfirm
            }
        case LoginActionTypes.RECOVER_CONFIRM_CODE_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                successMessage: '',
                errorMessage: "Occoreu um erro na confirmacao do codigo. Tente novamente!"
            }
        case LoginActionTypes.RECOVER_NEW_PASSWORD_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
            }
        case LoginActionTypes.RECOVER_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage: '',
                successMessage: "Senha recuperada com sucesso",
                login: action.payload,
                passwordChanged: true
            }
        case LoginActionTypes.RECOVER_NEW_PASSWORD_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                successMessage: '',
                errorMessage: "Occoreu um erro na definicao da nova senha. Tente novamente!"
            }
        default:
            return state;
    }
}

export default loginReducer;
