import {Dispatch} from "redux";
import {RoleAction, RoleActionTypes} from "../actionTypes/role.actionTypes";
import {Api} from "../../services/api";
import {IRole} from "../../models/role";

export const getRoles = () =>
    async (dispatch: Dispatch<RoleAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: RoleActionTypes.GET_ROLES_LOADING
            });

            await api.get<IRole[]>("/applicationRoles")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: RoleActionTypes.GET_ROLES_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                      type: RoleActionTypes.GET_ROLES_FAIL,
                      payload: e
                    })
                })
        }catch (e: any) {
            dispatch({
                type: RoleActionTypes.GET_ROLES_FAIL,
                payload: e
            })
        }
}
