import {Dispatch} from "redux";
import {IncidenceMessageAction, IncidenceMessageActionType} from "../actionTypes/incidenceMessage.actionType";
import {Api} from "../../services/api";
import {IIncidenceMessage} from "../../models/incidenceMessage";

export const getIncidenceMessage = (incidenceId: string) =>
    async (dispatch: Dispatch<IncidenceMessageAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_LOADING
            })

            await api.get<IIncidenceMessage[]>(`/incidenceMessages/${incidenceId}`)
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_FAIL,
                payload: e
            })
        }

}
