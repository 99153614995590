import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./assets/css/style.css";

import AuthLayout from "./layouts/Auth";
import AdminLayout from "./layouts/Admin";
import {Provider} from "react-redux";
import {store} from "./store/store";
import PrivateRoute from "./PrivateRoutes";
import 'font-awesome/css/font-awesome.css';
import {SnackbarProvider} from "notistack";

ReactDOM.render(
    <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        autoHideDuration={7000}
    >
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" render={(props) => <AuthLayout props={props} />} />
                    <PrivateRoute path="/admin" render={(props) => <AdminLayout props={props} />} />
                    <Redirect path="/" to="/auth/login"/>
                </Switch>
            </BrowserRouter>
        </Provider>
    </SnackbarProvider>
  ,
  document.getElementById("root")
);

