import axios from "axios";
import auth from "../helpers/auth";

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_HOST}/api`
})

//Interceptor for Request
api.interceptors.request.use((config) => {
    if (auth.isAuthenticated() && config.headers){
        config.headers.Authorization =`Bearer ${auth.getToken()}`;
    }
    return config;
}, error => {
    return Promise.reject(error)
});

export class Api {

    public get<T>(url: string){
        return api.get<T>(url);
    }

    public post<T>(url: string, data: any){
        return api.post<T>(url, data);
    }

    public put<T>(url: string, data: any){
        return api.put<T>(url, data);
    }

    public delete<T>(url: string){
        return api.delete<T>(url);
    }

    public postUpload<T>(url: string, data: any){
        return api.post<T>(url, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    }
}
