import {IIncidencePriority} from "../../models/incidencePriority";
import {IncidencePriorityAction, IncidencePriorityActionType} from "../actionTypes/incidencePriority.actionType";

export interface IIncidencePriorityState {
    incidencePriority: Array<IIncidencePriority>,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: IIncidencePriorityState = {
    incidencePriority: [],
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    errorMessage: '',
    successMessage: ''
}

const incidencePriorityReducer = (state: IIncidencePriorityState = initialState,
                                  action: IncidencePriorityAction) => {
    switch (action.type) {
        case IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_LOADING:
            return {
                ...state,
                isLoading: true,
                errorMessage: "",
                successMessage: "",
                isSaving: false,
                error: false,
                success: false,
            }
        case IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                incidencePriority: action.payload
            }
        case IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default incidencePriorityReducer;
