import {IUser} from "../../models/user";
import {IError} from "../../models/error";

export enum UserActionTypes {
    GET_USERS_LOADING = "GET_USERS_LOADING",
    GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
    GET_USERS_FAIL = "GET_USERS_FAIL",
    GET_USER_LOADING = "GET_USER_LOADING",
    GET_USER_SUCCESS = "GET_USER_SUCCESS",
    GET_USER_FAIL = "GET_USER_FAIL",
    CREATE_USER_SAVING = "CREATE_USER_SAVING",
    CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
    CREATE_USER_FAIL = "CREATE_USER_FAIL",
    EDIT_USER_SAVING = "EDIT_USER_SAVING",
    EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS",
    EDIT_USER_FAIL = "EDIT_USER_FAIL",
    REMOVE_USER_SAVING = "REMOVE_USER_SAVING",
    REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS",
    REMOVE_USER_FAIL = "REMOVE_USER_FAIL"
}

interface IUserLoading {
    type: UserActionTypes.GET_USER_LOADING
}

interface IUserSuccess {
    type: UserActionTypes.GET_USER_SUCCESS,
    payload: IUser
}

interface IUserFail {
    type: UserActionTypes.GET_USER_FAIL
    payload: IError
}

interface IUsersLoading {
    type: UserActionTypes.GET_USERS_LOADING
}

interface IUsersSuccess {
    type: UserActionTypes.GET_USERS_SUCCESS,
    payload: Array<IUser>
}

interface IUsersFail {
    type: UserActionTypes.GET_USERS_FAIL
    payload: IError
}

interface ICreateUserSaving {
    type: UserActionTypes.CREATE_USER_SAVING
}

interface ICreateUserSuccess {
    type: UserActionTypes.CREATE_USER_SUCCESS,
    payload: IUser
}

interface ICreateUserFail {
    type: UserActionTypes.CREATE_USER_FAIL
    payload: IError
}

interface IEditUserSaving {
    type: UserActionTypes.EDIT_USER_SAVING
}

interface IEditUserSuccess {
    type: UserActionTypes.EDIT_USER_SUCCESS,
    payload: IUser
}

interface IEditUserFail {
    type: UserActionTypes.EDIT_USER_FAIL
    payload: IError
}

interface IRemoveUserSaving {
    type: UserActionTypes.REMOVE_USER_SAVING
}

interface IRemoveUserSuccess {
    type: UserActionTypes.REMOVE_USER_SUCCESS,
    payload: IUser
}

interface IRemoveUserFail {
    type: UserActionTypes.REMOVE_USER_FAIL
    payload: IError
}

export type UserAction =
    IUserLoading | IUserSuccess | IUserFail |
    IUsersLoading | IUsersSuccess | IUsersFail |
    ICreateUserSaving | ICreateUserSuccess | ICreateUserFail |
    IEditUserSaving | IEditUserSuccess | IEditUserFail |
    IRemoveUserSaving | IRemoveUserSuccess | IRemoveUserFail;

