import {Dispatch} from "redux";
import {IncidencePriorityAction, IncidencePriorityActionType} from "../actionTypes/incidencePriority.actionType";
import {Api} from "../../services/api";
import {IIncidencePriority} from "../../models/incidencePriority";

export const getIncidencePriority = () =>
    async (dispatch: Dispatch<IncidencePriorityAction>) => {
        let api = new Api();
        try {
            dispatch({
                type: IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_LOADING
            })

            await api.get<IIncidencePriority[]>("/incidencePriority")
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_FAIL,
                payload: e
            })
        }
    }
