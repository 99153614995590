import React from "react";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";

interface IDraftWysiwygComponent {
    detailsDraft: EditorState
}

const DraftWysiwygComponent = ({detailsDraft}: IDraftWysiwygComponent) => {

    return(
        <>
            <Editor
                editorState={detailsDraft}
                toolbarClassName="toolbarClassName"
                wrapperClassName="card"
                editorClassName="card-body"
                readOnly
                toolbarHidden
            />
        </>
    )
}

export default DraftWysiwygComponent;
