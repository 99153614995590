import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import auth from "./helpers/auth";

const PrivateRoute: React.FC<RouteProps> = ({ ...rest }) => {
    if(!auth.isAuthenticated())  return <Redirect to="/"/>;
    return <Route {...rest}/>;
}

export default PrivateRoute;
