import {Dispatch} from "redux";
import {IncidenceAction, IncidenceActionType} from "../actionTypes/incidence.actionType";
import {Api} from "../../services/api";
import {IIncidence} from "../../models/incidence";
import {IRequestSupport} from "../../views/admin/Support/RequestSupport";
import {IStatistic} from "../../models/statistic";
import {IIncidenceRatingStatistic} from "../../models/incidenceRatingStatistic";
import {ITechnicianWithIncidentNumbers} from "../../models/TechnicianWithIncidentNumbers";

export const getIncidences = () =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_LOADING
            });

            await api.get<IIncidence[]>("/incidence")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceActionType.GET_INCIDENCES_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.GET_INCIDENCES_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_FAIL,
                payload: e
            })
        }
}

export const getTechnicianWithIncidentNumbers = () =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_LOADING
            });

            await api.get<ITechnicianWithIncidentNumbers[]>("/incidence/technicianWithIncidentNumbers")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_FAIL,
                payload: e
            })
        }
    }

export const allocateTechnician = (incidenceId: number, technicianId: string) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_SAVING
            })

            await api.post<IIncidence>(`/incidence/allocateTechnician/${incidenceId}/${technicianId}`,{})
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_FAIL,
                payload: e
            })
        }
    }


export const changeIncidencePriority = (id: number, priorityId: number) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.CHANGE_PRIORITY_SUPPORT_SAVING
            })

            await api.put<IIncidence>(`/incidence/changePriority/${id}/${priorityId}`,{})
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.CHANGE_PRIORITY_SUPPORT_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.CHANGE_PRIORITY_SUPPORT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.CHANGE_PRIORITY_SUPPORT_FAIL,
                payload: e
            })
        }
    }

export const requestSupport = (requestSupport: IRequestSupport) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.REQUEST_SUPPORT_SAVING
            })

            await api.post<IIncidence>("/incidence", requestSupport)
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.REQUEST_SUPPORT_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.REQUEST_SUPPORT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.REQUEST_SUPPORT_FAIL,
                payload: e
            })
        }
}


export const cancelIncidence = (id: number) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.CANCEL_INCIDENCE_SAVING
            })

            await api.put<IIncidence>(`/incidence/cancel/${id}`,{})
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.CANCEL_INCIDENCE_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.CANCEL_INCIDENCE_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.CANCEL_INCIDENCE_FAIL,
                payload: e
            })
        }
}

export const startSupport = (id: number) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.START_SUPPORT_SAVING
            })

            await api.put<IIncidence>(`/incidence/start/${id}`,{})
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.START_SUPPORT_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.START_SUPPORT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.START_SUPPORT_FAIL,
                payload: e
            })
        }
}

export const finishSupport = (id: number) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.FINISH_SUPPORT_SAVING
            })

            await api.put<IIncidence>(`/incidence/finish/${id}`,{})
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.FINISH_SUPPORT_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.FINISH_SUPPORT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.FINISH_SUPPORT_FAIL,
                payload: e
            })
        }
}

export const sheduleSupport = (incidenceId: number, sheduleId: number) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.SHEDULE_SUPPORT_SAVING
            })

            await api.put<IIncidence>(`/incidence/shedule/${incidenceId}/${sheduleId}`,{})
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.SHEDULE_SUPPORT_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.SHEDULE_SUPPORT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.SHEDULE_SUPPORT_FAIL,
                payload: e
            })
        }
    }

export const restartSupport = (id: number) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.RESTART_SUPPORT_SAVING
            })

            await api.put<IIncidence>(`/incidence/restart/${id}`,{})
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: IncidenceActionType.RESTART_SUPPORT_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.RESTART_SUPPORT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.RESTART_SUPPORT_FAIL,
                payload: e
            })
        }
    }

export const getIncidencesStatisticForWeek = () =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_LOADING
            });

            await api.get<IStatistic[]>("/incidence/statisticsWeek")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_FAIL,
                payload: e
            })
        }
    }

export const getIncidencesStatisticForYear = () =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_LOADING
            });

            await api.get<IStatistic[]>("/incidence/statisticsYear")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_FAIL,
                payload: e
            })
        }
    }

export const addIncidenceRating = (id: number, ratingId: number, description: string) =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.ADD_INCIDENCE_RATING_SAVING
            });

            await api.post<IIncidence>(`/incidence/rating/${id}/${ratingId}`, {description})
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceActionType.ADD_INCIDENCE_RATING_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.ADD_INCIDENCE_RATING_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.ADD_INCIDENCE_RATING_FAIL,
                payload: e
            })
        }
    }


export const getIncidencesRatingStatistic = () =>
    async (dispatch: Dispatch<IncidenceAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_LOADING
            });

            await api.get<IIncidenceRatingStatistic>("/incidence/incidenceRating/statistic")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_FAIL,
                payload: e
            })
        }
    }
