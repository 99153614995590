import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col, Form, FormGroup, Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import MaterialTable from "material-table";
import {IFaq} from "../../../models/faq";
import {addFaq, deleteFaq, getFaqs} from "../../../store/actionCreators/faq.actionCreator";
import {useDispatch} from "react-redux";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {useSnackbar} from "notistack";

const SettingsFAQ = () => {
    const [allFaqs,setAllFaqs] = useState<Array<IFaq>>([]);
    const [faqId, setFaqId] = useState<string>('');
    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [modalRemove, setModalRemove] = useState<boolean>(false)
    const [description,setDescription] = useState<string>('');
    const [file,setFile] = useState<File>(new File([],''));
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        dispatch(getFaqs())
    }, [dispatch])

    const {faqs, success, successMessage, error,
        errorMessage, isSaving, isLoading} = useTypeSelector(
        (state) => state.faq
    )

    useEffect(() => {
        if (faqs){
            setAllFaqs(faqs)
        }
    },[faqs])

    const redirect = (url: string) => {
        window.open(url, '_blank');
    }

    const clear = () => {
        setFaqId('');
        setDescription('');
    }

    const toggle = () => {
        clear();
        setModalAdd(!modalAdd)
    }

    const toggleRemove = () => {
        setModalRemove(!modalRemove);
        setFaqId('');
    }

    const removeFaq = (id: string) => {
        setFaqId(id);
        setModalRemove(true);
    }

    const removeHandler = () => {
        dispatch(deleteFaq(parseInt(faqId)))
    }

    const addFaqHandler = () => {
        dispatch(addFaq(description, file))
    }

    useEffect(() => {
        if(success){
            enqueueSnackbar(successMessage, {
                variant: 'success'
            })
        }

        if (error){
            enqueueSnackbar(errorMessage, {
                variant: 'error'
            })
        }

    },[success, error, successMessage, errorMessage, enqueueSnackbar])

    useEffect(() => {
        if(success){
            clear();
        }
    },[success])

    const handleFile = (event: any) => {
        setFile(event.target.files[0])
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <CardTitle tag="h4" className="col-md-9">Lista de Respostas das Perguntas Frequentes</CardTitle>
                                    <Button className="ml-auto mr-3" onClick={toggle}>
                                        <i className='fas fa-solid fa-plus mr-2'/>  Adicionar novo
                                    </Button>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    isLoading={isLoading}
                                    title="Lista de Respostas das Perguntas Frequentes"
                                    columns={[
                                        {title: 'Id', field: 'id'},
                                        {title: 'Descrição', field: 'description' },
                                    ]}
                                    data={allFaqs}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Adicionar',
                                            isFreeAction: true,
                                            onClick: () => toggle()
                                        },
                                        {
                                            icon: 'download',
                                            tooltip: 'Baixar',
                                            onClick: (event, rowData: any) => redirect(rowData.url)
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (event, rowData:any) => removeFaq(rowData.id)
                                        }
                                    ]}
                                    options={{
                                        exportButton: true,
                                        actionsColumnIndex: -1,
                                        loadingType: "overlay",
                                        showTitle: false,
                                        headerStyle: {fontWeight: 'bold'}
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalAdd}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                >
                    Adicior Resposta de Perguntas Frequentes
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col className="col-md-8">
                                <FormGroup>
                                    <label>Descrição</label>
                                    <Input
                                        placeholder="Descrição"
                                        type="text"
                                        value={description}
                                        onChange={(e) => {setDescription(e.target.value);}}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label>Ficheiro</label>
                                    <Input
                                        placeholder="Ficheiro"
                                        type="file"
                                        onChange={(e) => {handleFile(e)}}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                disabled={!description || !file}
                                onClick={addFaqHandler}
                            >
                                Adicionar
                            </Button>
                    }

                    <Button onClick={toggle} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>

            {/*Modal for remove user*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalRemove}
                toggle={toggleRemove}
            >
                <ModalHeader
                    toggle={toggleRemove}
                >
                    Remover Resposta de uma pergunta frequente
                </ModalHeader>
                <ModalBody>
                    <p>Deseja remover ?</p>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={removeHandler}
                            >
                                Remover
                            </Button>
                    }

                    <Button onClick={toggleRemove} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default SettingsFAQ;
