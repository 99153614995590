import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup, Input,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import MaterialTable from "material-table";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {
    addIncidenceType,
    deleteIncidenceType,
    editIncidenceType,
    getIncidenceType
} from "../../../store/actionCreators/incidenceType.actionCreator";
import {IIncidenceType} from "../../../models/incidenceType";

const SettingsIncidenceType = () => {
    const [modal,setModal] = useState<boolean>(false);
    const [modalRemove,setModalRemove] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [incidenceType, setIncidenceType] = useState<string>('');
    const [allIncidenceType, setAllIncidenceType] = useState<Array<IIncidenceType>>([])
    const [incidenceTypeId, setIncidenceTypeId] = useState<string>('');
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    const {incidenceTypes, isLoading, isSaving, success,
        error, successMessage, errorMessage} = useTypeSelector(
        (state) => state.incidenceType
    )
    const {userLogged} = useTypeSelector(
        (state) => state.user
    );
    const {roles} = userLogged;
    const role = roles[0];

    useEffect(() => {
        dispatch(getIncidenceType())
    },[dispatch])

    const clear = () => {
        setIncidenceType('');
        setIncidenceTypeId('');
    }

    const toggle = () => {
        clear();
        setModal(!modal);
        setEdit(false);
    }

    const toggleRemove = () => {
        setModalRemove(!modalRemove)
        setIncidenceTypeId('');
    }

    const editIncidenceTypeData = (id: string) => {
        allIncidenceType.forEach((incType) => {
            if(incType.id === parseInt(id)){
                setIncidenceType(incType.description)
                setIncidenceTypeId(id);
            }
        })
        setModal(true);
        setEdit(true);
    }

    useEffect(() => {
        if (incidenceTypes) {
            setAllIncidenceType(incidenceTypes)
        }
    },[incidenceTypes])

    const incidenceTypeHandler = () => {
        if (edit){
            dispatch(editIncidenceType(parseInt(incidenceTypeId), incidenceType))
        }else{
            dispatch(addIncidenceType(incidenceType))
        }
    }

    const removeIncidenceType = (id: string) => {
        setIncidenceTypeId(id);
        setModalRemove(true);
    }

    const removeHandler = () => {
        dispatch(deleteIncidenceType(parseInt(incidenceTypeId)))
    }

    useEffect(() => {
        if(success){
            enqueueSnackbar(successMessage, {
                variant: 'success'
            })
        }

        if (error){
            enqueueSnackbar(errorMessage, {
                variant: 'error'
            })
        }

    },[success, error, successMessage, errorMessage, enqueueSnackbar])

    useEffect(() => {
        if (success && !edit){
            clear();
        }
    },[success,edit])

    return (
        <>
            <div className="content">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <CardTitle tag="h4" className="col-md-9">Lista de Tipos de Incidencias</CardTitle>
                                    <Button disabled={role !== "SystemAdministrator"} className="ml-auto mr-3" onClick={toggle}>
                                        <i className='fas fa-solid fa-plus mr-2'/>  Adicionar novo
                                    </Button>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    isLoading={isLoading}
                                    title="Lista de Tipos de Incidencias"
                                    columns={[
                                        {title: 'Id', field: 'id'},
                                        {title: 'Descrição', field: 'description'}
                                    ]}
                                    data={allIncidenceType}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Adicionar Tipo de Incidencia',
                                            isFreeAction: true,
                                            disabled: role !== "SystemAdministrator",
                                            onClick: () => toggle()
                                        },
                                        {
                                            icon: 'edit',
                                            tooltip: 'Editar',
                                            disabled: role !== "SystemAdministrator",
                                            onClick: (event, rowData: any) => editIncidenceTypeData(rowData.id)
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            disabled: role !== "SystemAdministrator",
                                            onClick: (event, rowData:any) => removeIncidenceType(rowData.id)
                                        }
                                    ]}
                                    options={{
                                        exportButton: true,
                                        actionsColumnIndex: -1,
                                        loadingType: "overlay",
                                        showTitle: false,
                                        headerStyle: {fontWeight: 'bold'}
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                >
                    {edit ?
                        <>Editar descricao do tipo</>
                        :
                        <>Adicior Tipo de Incidencia</>
                    }
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col className="col-md-8">
                                <FormGroup>
                                    <label>Nome do Tipo </label>
                                    <Input
                                        placeholder="Nome do tipo de incidencia"
                                        type="text"
                                        value={incidenceType}
                                        onChange={(e) => {setIncidenceType(e.target.value);}}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                disabled={!incidenceType}
                                onClick={incidenceTypeHandler}
                            >
                                {edit ?
                                    <>Editar</>
                                    :
                                    <>Adicionar</>
                                }
                            </Button>
                    }

                    <Button onClick={toggle} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>

            {/*Modal for remove user*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalRemove}
                toggle={toggleRemove}
            >
                <ModalHeader
                    toggle={toggleRemove}
                >
                    Remover tipo de incidencia
                </ModalHeader>
                <ModalBody>
                    <p>Deseja remover tipo de incidencia ?</p>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={removeHandler}
                            >
                                Remover
                            </Button>
                    }

                    <Button onClick={toggleRemove} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default SettingsIncidenceType;

