import React from "react";
import {Col, Form, FormGroup, Row} from "reactstrap";
import DraftWysiwygComponent from "./DraftWysiwygComponent";
import {convertFromRaw, EditorState} from "draft-js";
import {IIncidenceMessage} from "../../models/incidenceMessage";
import {useTypeSelector} from "../../hooks/useTypeSelector";

interface IViewMessagesComponent {
    messages: Array<IIncidenceMessage>
}

const ViewMessagesComponent = ({messages}: IViewMessagesComponent) => {

    const convertToJson = (message: string) => {
        return EditorState.createWithContent(convertFromRaw(JSON.parse(message)));
    }
    const {userLogged} = useTypeSelector(
        (state) => state.user
    );
    const {email} = userLogged;

    const convertDate = (dateToConvert: Date = new Date()) => {
        const date = new Date(dateToConvert);

        const viewDate = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        const viewMonth = date.getMonth();

        const viewDateHour = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
        const viewDateMinute = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`

        return viewDate + '/' + viewMonth + '/' + date.getFullYear() + ' as '+ viewDateHour +':'+ viewDateMinute;
    }

    return(
        <>
            {
                messages?.map((m, key) =>
                    <>
                        {
                            email !== m.user.email ?
                                <Col className="mt-4 col-md-8" key={key}>
                                    <Form>
                                        <FormGroup>
                                            <label style={{fontSize: "15px"}}>{m.user.fullName}
                                                <span style={{color: "#C5C7CD"}}>  {convertDate(m.date)}</span>
                                            </label>
                                            <DraftWysiwygComponent detailsDraft={convertToJson(m.description)}/>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                :
                                <Col className="mt-4 col-md-8 ml-auto" key={key}>
                                    <Form>
                                        <FormGroup>
                                            <Row className="ml-auto">
                                                <label className="ml-auto" style={{fontSize: "15px"}}>{m.user.fullName}
                                                    <span style={{color: "#C5C7CD"}}> {convertDate(m.date)}</span>
                                                </label>
                                            </Row>
                                            <DraftWysiwygComponent detailsDraft={convertToJson(m.description)}/>
                                        </FormGroup>
                                    </Form>
                                </Col>
                        }
                    </>
                )
            }

        </>
    )
}

export default ViewMessagesComponent;
