
import React from "react";
// javascript plugin used to create scrollbars on windows
import {Link, Route, Switch} from "react-router-dom";
import {ExtractRouteParams, RouteComponentProps, useHistory} from "react-router";
import routes from "../routes";
import {Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from "reactstrap";
import uemLogo from "../assets/img/logo-uem.png";
import stFEUEM from "../assets/img/ST-FEUEM.png";
import {MdOutlineHelpOutline} from "react-icons/md";

interface IAuthProps {
  props: RouteComponentProps<ExtractRouteParams<string,string>>
}

function Auth({props} : IAuthProps) {
	const history = useHistory();
	React.useEffect(()=> {
		// reset basic parameters on reload
		if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {

			//redirect to dashboard and reload data to redux state
			history.push("/auth/login");
		}
	}, [history]);

	const [dropdownOpen, setDropdownOpen] = React.useState(false);

	const dropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

  return (
		<Container className="bg-success p-0 row justify-content-end" style={{height:"100vh"}} fluid>
			<Col className="text-center justify-content-end row" >
				<div style={{position:"fixed",bottom:'0', zIndex:"10"}}>
					<Dropdown
						flip="true"
						end="true"
						direction="up"
						isOpen={dropdownOpen}
						toggle={() => dropdownToggle()}

						style={{position:"relative",zIndex:"12", bottom:"20px"}}
					>
						<DropdownToggle  nav>
							<MdOutlineHelpOutline color="white" size={40}/>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem tag="a">O que é a ST-FEUEM?</DropdownItem>
							<DropdownItem tag="a">Como ter acesso ao ST-FEUEM?</DropdownItem>
							<DropdownItem tag="a">Suporte</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</Col>
			<div className="bg-white auth-panel">
				<Container className="pt-4 pr-lg-5 pr-md-2" fluid>
					<Row className="justify-content-md-end justify-content-center mb-3">
						<img alt="FEUEM logo" src={uemLogo} height="120"/>
					</Row>
					<Row className="justify-content-center mb-3">
						<img alt="Ecofeuem logo" src={stFEUEM} height="60"/>
					</Row>
					<Row className="justify-content-center px-md-5">
						<Col xl="4" lg="5" md="6" sm="8" className="text-center  ml-auto mr-auto ">
							<Switch>
								{routes.map((prop, key) => {
									if (prop.layout === "/auth") {
										return (
											<Route
												path={prop.layout + prop.path}
												component={prop.component}
												key={key}
											/>
										);
									} else {
										return null;
									}
								})}
							</Switch>
						</Col>
					</Row>
					{
						(props.location.pathname === "/auth/login") &&
						<Row className="justify-content-center mb-5">
							<Link to="/auth/recover" className="link-danger" style={{fontSize:"17px"}}>
								<u>Não consegue iniciar a sessão?</u>
							</Link>
						</Row>
					}
					<Row className="justify-content-center mb-5 mt-5 text-muted">
						<Col xl="6" lg="5" md="6" sm="8" className="text-center  ml-auto mr-auto ">
							<b className="mr-1">ST-FEUEM </b> é um sistema de suporte que visa auxiliar na
							interação entre os colaboradores da FEUEM e os técnicos do DTICs
						</Col>
					</Row>
					<Row className="justify-content-center text-muted pb-3">
						© 2022, feito pelo DTCs da
						<a href="http://www.engenharia.uem.mz/" target="_blank" rel="noreferrer" className="text-muted ml-1 mr-1">
							<u>Faculdade de Engenharia</u>
						</a> 
						- 
						<a href="https://www.uem.mz/" target="_blank" rel="noreferrer" className="text-muted ml-1">
							<u>Universidade Eduardo Mondlane</u>
						</a>.
					</Row>
				</Container>
			</div>
		</Container>
	);
}

export default Auth;
