import {Dispatch} from "redux";
import {DepartmentAction, DepartmentActionTypes} from "../actionTypes/department.actionTypes";
import {Api} from "../../services/api";
import {IDepartment} from "../../models/department";

export const getDepartments = () =>
    async (dispatch: Dispatch<DepartmentAction>) => {
        let api = new Api();

        try {
            dispatch({
               type: DepartmentActionTypes.GET_DEPARTMENTS_LOADING
            });

            await api.get<IDepartment[]>("/departments")
                .then(result => {
                    if(result.status === 200){
                        dispatch({
                            type: DepartmentActionTypes.GET_DEPARTMENTS_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: DepartmentActionTypes.GET_DEPARTMENTS_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: DepartmentActionTypes.GET_DEPARTMENTS_FAIL,
                payload: e
            })
        }
}

export const addDepartment = (description: string) =>
    async (dispatch: Dispatch<DepartmentAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: DepartmentActionTypes.ADD_DEPARTMENT_SAVING
            });

            await api.post<IDepartment>("/departments", {description})
                .then(result => {
                    if(result.status === 200){
                        dispatch({
                            type: DepartmentActionTypes.ADD_DEPARTMENT_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: DepartmentActionTypes.ADD_DEPARTMENT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: DepartmentActionTypes.ADD_DEPARTMENT_FAIL,
                payload: e
            })
        }
    }

export const editDepartment = (id: number, description: string) =>
    async (dispatch: Dispatch<DepartmentAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: DepartmentActionTypes.EDIT_DEPARTMENT_SAVING
            });

            await api.put<IDepartment>(`/departments/${id}`, {description})
                .then(result => {
                    if(result.status === 200){
                        dispatch({
                            type: DepartmentActionTypes.EDIT_DEPARTMENT_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: DepartmentActionTypes.EDIT_DEPARTMENT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: DepartmentActionTypes.EDIT_DEPARTMENT_FAIL,
                payload: e
            })
        }
    }

export const deleteDepartment = (id: number) =>
    async (dispatch: Dispatch<DepartmentAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: DepartmentActionTypes.REMOVE_DEPARTMENT_SAVING
            });

            await api.delete<IDepartment>(`/departments/${id}`)
                .then(result => {
                    if(result.status === 200){
                        dispatch({
                            type: DepartmentActionTypes.REMOVE_DEPARTMENT_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: DepartmentActionTypes.REMOVE_DEPARTMENT_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: DepartmentActionTypes.REMOVE_DEPARTMENT_FAIL,
                payload: e
            })
        }
    }


