import React from 'react';
// @ts-ignore
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import {useHistory} from "react-router";

const ChatbotSupport = () => {
    const history = useHistory();
    // all available props
    const theme = {
        background: '#f5f8fb',
        headerBgColor: '#007F60',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#007F60',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#4a4a4a',
    };

    const steps=[
            {
                id: '1',
                message: 'Olá! Bem-vindo ao serviço de suporte da FEUEM.',
                trigger: '2',
            },
            {
                id: '2',
                message: 'O problema tem haver com: ',
                trigger: '3',
            },
            {
                id: '3',
                    options: [
                        { value: 1, label: 'Computador', trigger: '4' },
                        { value: 2, label: 'Internet', trigger: '3' },
                        { value: 3, label: 'Impressora', trigger: '3' },
                        { value: 4, label: 'Comunicação entre departamentos', trigger: '3' },
                        { value: 5, label: 'Outro', trigger: '9' },
                    ],
            },
            {
                id: '4',
                message: 'O Computador:',
                trigger: '5',
            },
            {
                id: '5',
                options: [
                    { value: 1, label: 'Não liga', trigger: '6' },
                    { value: 2, label: 'Liga e desliga', trigger: '7' },
                    { value: 3, label: 'Monstra uma tela azul/preta', trigger: '7' },
                    { value: 4, label: 'Outro', trigger: '9' },
                ],
            },
            {
                id: '6',
                message: 'Verifica se o computador esta ligado na tomada ',
                trigger: '5',
            },
            {
                id: '7',
                message: 'Fale com o tecnico. Clicando em Outro',
                trigger: '8',
            },
            {
                id: '8',
                options: [
                    { value: 1, label: 'Outro', trigger: '10' },
                ],
            },
            {
                id: '9',
                message: 'Será redirecionado para uma página para entrar em contacto com o técnico.',
                trigger: '10',
            },
            {
                id: '10',
                message: 'Awesome! You are a telepath!',
                end: true,
            },
    ]

    const other = () => {
        history.push("/admin/support/request")
    }

    return(
        <>
            <div className="content">

                <ThemeProvider theme={theme}>
                    <ChatBot
                        handleEnd={other}
                        width="1200px"
                        headerTitle="FEUEM BOT"
                        steps={steps}
                    />
                </ThemeProvider>
            </div>
        </>
    )
}

export default ChatbotSupport;
