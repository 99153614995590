import {IIncidenceMessage} from "../../models/incidenceMessage";
import {IncidenceMessageAction, IncidenceMessageActionType} from "../actionTypes/incidenceMessage.actionType";

export interface IIncidenceMessageState {
    incidenceMessages: Array<IIncidenceMessage>,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: IIncidenceMessageState = {
    incidenceMessages: [],
    isSaving: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: ''
}

const incidenceMessageReducer = (state: IIncidenceMessageState = initialState, action: IncidenceMessageAction) => {
    switch (action.type) {
        case IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_LOADING:
            return {
                isLoading: true,
                isSaving: false,
                error: false,
                success: false,
                successMessage: '',
                errorMessage: ''
            }
        case IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_SUCCESS:
            return {
                isLoading: false,
                isSaving: false,
                error: false,
                success: false,
                incidenceMessages: action.payload
            }
        case IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_FAIL:
            return {
                isLoading: false,
                isSaving: false,
                error: true,
                success: false,
                errorMessage: "Occorreu um erro ao carregar as mensagens"
            }

        default:
            return state;
    }
}

export default incidenceMessageReducer;
