import {Dispatch} from "redux";
import {Api} from "../../services/api";
import {LoginAction, LoginActionTypes} from "../actionTypes/login.actionTypes";
import {ILogin} from "../../models/login";
import auth from "../../helpers/auth";
import {IRecoverPassword} from "../../models/RecoverPassword";

export const loginAction = (email: string, password: string) =>
    async (dispatch: Dispatch<LoginAction>) => {
    let api = new Api();

    try {
        dispatch({
            type: LoginActionTypes.LOGIN_SAVING
        });

        await api.post<ILogin>("/auth", {email, password})
            .then(result => {

                if (result.status === 200) {
                    auth.login(result.data.token);
                    localStorage.setItem('user', JSON.stringify(result.data));

                    dispatch({
                        type: LoginActionTypes.LOGIN_SUCCESS,
                        payload: result.data
                    });
                }
            })
            .catch(e => {
               dispatch({
                   type: LoginActionTypes.LOGIN_FAIL,
                   payload: e
               })
            });
    }catch (e: any) {
        dispatch({
            type: LoginActionTypes.LOGIN_FAIL,
            payload: e
        })
    }
}

export const recoverAction = (email: string) =>
    async (dispatch: Dispatch<LoginAction>) => {
    let api = new Api();

    try {
        dispatch({
            type: LoginActionTypes.RECOVER_SAVING
        });

        await api.post<IRecoverPassword>(`/auth/recoveryPasswordCode/${email}`, {})
            .then(result => {

                if (result.status === 200) {
                    dispatch({
                        type: LoginActionTypes.RECOVER_SUCCESS,
                        payload: result.data
                    })
                }
            })
            .catch(e => {
               dispatch({
                   type: LoginActionTypes.RECOVER_FAIL,
                   payload: e
               })
            });
    }catch (e: any) {
        dispatch({
            type: LoginActionTypes.RECOVER_FAIL,
            payload: e
        })
    }
}


export const recoverConfirmCodeAction = (email: string, code: string) =>
    async (dispatch: Dispatch<LoginAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: LoginActionTypes.RECOVER_CONFIRM_CODE_SAVING
            });

            await api.get<IRecoverPassword>(`/auth/confirmRecoveryPasswordCode/${email}/${code}`)
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: LoginActionTypes.RECOVER_CONFIRM_CODE_SUCCESS,
                            payload: result.data
                        })
                    }
                })
                .catch(e => {
                    dispatch({
                        type: LoginActionTypes.RECOVER_CONFIRM_CODE_FAIL,
                        payload: e
                    })
                });
        }catch (e: any) {
            dispatch({
                type: LoginActionTypes.RECOVER_CONFIRM_CODE_FAIL,
                payload: e
            })
        }
}

export const recoverNewPasswordAction = (email: string, passwordNew: string) =>
    async (dispatch: Dispatch<LoginAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: LoginActionTypes.RECOVER_NEW_PASSWORD_SAVING
            });

            await api.put<ILogin>(`/auth/changePassword`, {email,passwordNew})
                .then(result => {

                    if (result.status === 200) {
                        auth.login(result.data.token);
                        localStorage.setItem('user', JSON.stringify(result.data));

                        dispatch({
                            type: LoginActionTypes.RECOVER_NEW_PASSWORD_SUCCESS,
                            payload: result.data
                        })
                    }
                })
                .catch(e => {
                    dispatch({
                        type: LoginActionTypes.RECOVER_NEW_PASSWORD_FAIL,
                        payload: e
                    })
                });
        }catch (e: any) {
            dispatch({
                type: LoginActionTypes.RECOVER_NEW_PASSWORD_FAIL,
                payload: e
            })
        }
    }

export const logoutAction = () =>
    async (dispatch: Dispatch<LoginAction>) => {
        //let api = new Api();

        try {
            dispatch({
                type: LoginActionTypes.LOGOUT_SAVING
            });

            localStorage.clear();
            dispatch({
                type: LoginActionTypes.LOGOUT_SUCCESS
            });

        }catch (e: any) {
            dispatch({
                type: LoginActionTypes.LOGOUT_FAIL,
                payload: e
            })
        }
    }
