import {ILogin} from "../../models/login";
import {IError} from "../../models/error";
import {IRecoverPassword} from "../../models/RecoverPassword";

export enum LoginActionTypes {
    LOGIN_SAVING = "LOGIN_SAVING",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAIL = "LOGIN_FAIL",
    LOGOUT_SAVING = "LOGOUT_SAVING",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
    LOGOUT_FAIL = "LOGOUT_FAIL",
    RECOVER_SAVING = "RECOVER_SAVING",
    RECOVER_SUCCESS = "RECOVER_SUCCESS",
    RECOVER_FAIL = "RECOVER_FAIL",
    RECOVER_CONFIRM_CODE_SAVING = "RECOVER_CONFIRM_CODE_SAVING",
    RECOVER_CONFIRM_CODE_SUCCESS = "RECOVER_CONFIRM_CODE_SUCCESS",
    RECOVER_CONFIRM_CODE_FAIL = "RECOVER_CONFIRM_CODE_FAIL",
    RECOVER_NEW_PASSWORD_SAVING = "RECOVER_NEW_PASSWORD_SAVING",
    RECOVER_NEW_PASSWORD_SUCCESS = "RECOVER_NEW_PASSWORD_SUCCESS",
    RECOVER_NEW_PASSWORD_FAIL = "RECOVER_NEW_PASSWORD_FAIL"
}

interface ILoginSaving {
    type: LoginActionTypes.LOGIN_SAVING
}

interface ILoginSuccess {
    type: LoginActionTypes.LOGIN_SUCCESS,
    payload: ILogin
}

interface ILoginFail {
    type: LoginActionTypes.LOGIN_FAIL,
    payload: {error: IError}
}

interface ILogoutSaving {
    type: LoginActionTypes.LOGOUT_SAVING
}

interface ILogoutSuccess {
    type: LoginActionTypes.LOGOUT_SUCCESS
}

interface ILogoutFail {
    type: LoginActionTypes.LOGOUT_FAIL,
    payload: IError
}

interface IRecoverSaving {
    type: LoginActionTypes.RECOVER_SAVING
}

interface IRecoverSuccess {
    type: LoginActionTypes.RECOVER_SUCCESS,
    payload: IRecoverPassword
}

interface IRecoverFail {
    type: LoginActionTypes.RECOVER_FAIL,
    payload: IError
}

interface IRecoverConfirmCodeSaving {
    type: LoginActionTypes.RECOVER_CONFIRM_CODE_SAVING
}

interface IRecoverConfirmCodeSuccess {
    type: LoginActionTypes.RECOVER_CONFIRM_CODE_SUCCESS,
    payload: IRecoverPassword
}

interface IRecoverConfirmCodeFail {
    type: LoginActionTypes.RECOVER_CONFIRM_CODE_FAIL,
    payload: IError
}

interface IRecoverNewPasswordSaving {
    type: LoginActionTypes.RECOVER_NEW_PASSWORD_SAVING
}

interface IRecoverNewPasswordSuccess {
    type: LoginActionTypes.RECOVER_NEW_PASSWORD_SUCCESS,
    payload: ILogin
}

interface IRecoverNewPasswordFail {
    type: LoginActionTypes.RECOVER_NEW_PASSWORD_FAIL,
    payload: IError
}

export type LoginAction =
    ILoginSaving| ILoginFail | ILoginSuccess |
    ILogoutSaving | ILogoutFail | ILogoutSuccess |
    IRecoverSaving | IRecoverSuccess | IRecoverFail |
    IRecoverConfirmCodeSaving | IRecoverConfirmCodeSuccess | IRecoverConfirmCodeFail |
    IRecoverNewPasswordSaving | IRecoverNewPasswordSuccess | IRecoverNewPasswordFail;
