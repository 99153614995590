import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Row} from "reactstrap";
import MaterialTable from "material-table";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {statisticYearForReport} from "./ReportAux";

const ReportsYear = () => {
    const [reportsYear,setReportsYear] = useState<Array<any>>([]);

    const {statisticYear, isLoading} = useTypeSelector(
        (state) => state.incidence
    );

    useEffect(()=> {
        setReportsYear(statisticYearForReport(statisticYear))
    }, [statisticYear])


    return(
        <>
            <div className="content">
                <Row>
                   <Col>
                      <Card>
                          <CardHeader>
                              <Row>
                                  <Col className="col-md-9">
                                      <CardTitle tag="h4">Relatório Anual</CardTitle>
                                  </Col>
                                  <Col className="ml-auto">
                                      <FormGroup>
                                          <Input
                                              type={"select"}
                                          >
                                              <option value="" hidden> 2022</option>
                                          </Input>
                                      </FormGroup>
                                  </Col>
                              </Row>
                          </CardHeader>
                          <CardBody>
                              <MaterialTable
                                  isLoading={isLoading}
                                  title="Relatório Anual"
                                  columns={[
                                      {title: "Mês", field: 'mes'},
                                      {title: "Pendente", field: 'pendente'},
                                      {title: "Em Andamento", field: 'andamento'},
                                      {title: "Agendado", field: 'agendado'},
                                      {title: "Cancelado", field: 'cancelado'},
                                      {title: "Terminado", field: 'terminado'},
                                      {title: "Total", field: 'totalMonth'},
                                  ]}
                                  data={reportsYear}
                                  options={{
                                      exportButton: true,
                                      actionsColumnIndex: -1,
                                      loadingType: "overlay",
                                      showTitle: false,
                                      headerStyle: {fontWeight: 'bold'}
                                  }}
                              />
                          </CardBody>
                      </Card>
                   </Col>
                </Row>
            </div>
        </>
    )

}
export default ReportsYear;
