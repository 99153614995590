/*eslint-disable*/
import React from "react";
import { Row } from "reactstrap";

function Footer() {
  return (
		<footer className="footer">
			<div className="container-fluid" >
				<Row className="text-success">
					<nav className="footer-nav">
						<ul>
							<li>
								<a href="http://www.engenharia.uem.mz/" target="_blank"> FEUEM </a>
							</li>
						</ul>
					</nav>
					<div className="credits ml-auto">
						<div className="copyright">
							&copy; { new Date().getFullYear()}, feito com{" "}
							<i className="fa fa-heart heart" /> pela FEUEM
						</div>
					</div>
				</Row>
			</div>
		</footer>
	);
}

export default Footer;
