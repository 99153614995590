import {IIncidenceType} from "../../models/incidenceType";
import {IncidenceTypeAction, IncidenceTypeActionType} from "../actionTypes/incidenceType.actionType";

export interface IIncidenceTypeState {
    incidenceTypes: Array<IIncidenceType>,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: IIncidenceTypeState = {
    incidenceTypes: [],
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    errorMessage: '',
    successMessage: ''
}

const incidenceTypeReducer = (state: IIncidenceTypeState = initialState, action: IncidenceTypeAction) => {
    switch (action.type) {
        case IncidenceTypeActionType.GET_INCIDENCE_TYPES_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false,
                errorMessage: '',
                successMessage: ''
            }
        case IncidenceTypeActionType.GET_INCIDENCE_TYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                incidenceTypes: action.payload
            }
        case IncidenceTypeActionType.GET_INCIDENCE_TYPES_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload
            }
        case IncidenceTypeActionType.ADD_INCIDENCE_TYPE_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceTypeActionType.ADD_INCIDENCE_TYPE_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Tipo de incidencia adicionado com successo',
                incidenceTypes: [...state.incidenceTypes, action.payload]
            }
        case IncidenceTypeActionType.ADD_INCIDENCE_TYPE_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao adicionar o tipo de incidencia',
                successMessage: '',
            }
        case IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_SUCCESS:
            const incidenceTypeUpdate = state.incidenceTypes.map(incidenceType => {
                if(incidenceType.id === action.payload.id)
                    return action.payload
                else return incidenceType
            });
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Tipo de incidencia actualizada com successo',
                incidenceTypes: incidenceTypeUpdate
            }
        case IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao actualizar o tipo de incidencia',
                successMessage: '',
            }
        case IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Tipo de incidencia actualizada com successo',
                incidenceTypes: state.incidenceTypes.filter(incidenceType => incidenceType.id !== action.payload.id)
            }
        case IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao remover o tipo de incidencia',
                successMessage: '',
            }
        default:
            return state;
    }
}

export default incidenceTypeReducer;
