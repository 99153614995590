import React, {useEffect, useState} from 'react';
import {Box, Rating} from "@mui/material";
import {Button, Col, Form, FormGroup, Input, Row, Spinner} from "reactstrap";
import {useDispatch} from "react-redux";
import {getRatings} from "../../store/actionCreators/rating.actionCreator";
import {useTypeSelector} from "../../hooks/useTypeSelector";
import {IRating} from "../../models/rating";
import {addIncidenceRating} from "../../store/actionCreators/incidence.actionCreator";
import {IIncidence} from "../../models/incidence";

interface IRatingSupport {
    id: string,
    incidenceSelected: IIncidence
}

const RatingSupport = ({id,incidenceSelected}: IRatingSupport) => {
    const [value, setValue] = useState<number>(0);
    const [hover, setHover] = React.useState(-1);
    const [labels, setLabels] = useState<Array<string>>(['Selecione uma estrela']);
    const [allRatings, setAllRatings] = useState<Array<IRating>>([]);
    const [description, setDescription] = useState<string>('')
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRatings())
    },[dispatch])

    const {ratings} = useTypeSelector(
        (state) => state.rating
    )

    const {isSaving} = useTypeSelector(
        (state) => state.incidence
    )

    useEffect(() => {
        setAllRatings(ratings);
        let label : Array<string> = ['Selecione uma estrela'];

        if (allRatings.length > 0){
            allRatings.forEach(rating => {
                label.push(rating.description)
            })

            setLabels(label)
        }

    },[allRatings,ratings])

    useEffect(() => {
        if (incidenceSelected.ratingId){
            setValue(incidenceSelected.ratingId)
        }
    },[incidenceSelected.ratingId])

    const getLabelText = (value: number) => {
        // @ts-ignore
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    const ratingHandler = () => {
       dispatch(addIncidenceRating(parseInt(id),value,description))
    }

    return(
        <>
            <div className="ml-4">
                <Row className="mt-4">
                    {
                        incidenceSelected.ratingId ?
                            <p> <strong>Satisfação com o suporte oferecido</strong></p>
                            :
                            <p> <strong>Qual é a tua satisfação com o suporte oferecido?</strong></p>
                    }
                </Row>
                <Row className="mt-1">
                    <Rating
                        name="hover-feedback"
                        disabled={incidenceSelected.ratingId > 0}
                        value={ incidenceSelected.ratingId ? incidenceSelected.ratingId : value}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                            if (newValue)
                                setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        size='large'
                    />
                    {value !== null && (
                        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                    )}
                </Row>
                <Row className="mt-2 ">
                    {
                        incidenceSelected.ratingId ?
                            <Row>
                                {
                                    incidenceSelected.ratingDescription !== '' &&
                                        <Col className="col-md-12">
                                            <p> <strong>Descrição</strong></p>
                                        </Col>
                                }
                                <Col>
                                    <p>{incidenceSelected.ratingDescription}</p>
                                </Col>
                            </Row>

                            :
                            <Form>
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        rows="22"
                                        style={{width: 350}}
                                        value={description}
                                        onChange={(e) => {setDescription(e.target.value)}}
                                    />
                                </FormGroup>
                            </Form>
                    }
                </Row>
                {
                    !incidenceSelected.ratingId &&
                        <Row>
                        {
                            isSaving ?
                                <Button
                                    block
                                >
                                    <Spinner size="sm" color="light"/>
                                </Button>
                                :
                                <Button disabled={value === 0} onClick={ratingHandler}>Enviar</Button>
                        }
                        </Row>
                }
            </div>
        </>
    )
}

export default RatingSupport;
