import React from "react";
import SettingsDepartment from "./SettingsDepartment";
import SettingsIncidenceType from "./SettingsIncidenceType";
import SettingsFAQ from "./SettingsFAQ";

const Settings = () => {
    return (
        <>
            <div className="content">
                <SettingsFAQ/>
                <SettingsIncidenceType/>
                <SettingsDepartment/>
            </div>
        </>
    )
}

export default Settings;
