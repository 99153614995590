import React, {useEffect, useState} from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {getUser} from "../../../store/actionCreators/user.actionCreator";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {useSnackbar} from "notistack";
import {IIncidence} from "../../../models/incidence";
import {
    getIncidences, getIncidencesRatingStatistic,
    getIncidencesStatisticForWeek,
    getIncidencesStatisticForYear
} from "../../../store/actionCreators/incidence.actionCreator";
import FAQ from "../FAQ/FAQ";
import LineChart from "./Charts/LineChart";
import LineChartYear from "./Charts/LineChartYear";

const Dashboard = () => {
    const [wait,setWait] = useState<number>(0);
    const [progress,setProgress] = useState<number>(0);
    const [shedule,setShedule] = useState<number>(0);
    const [close,setClose] = useState<number>(0);
    const [allIncidences, setAllIncidences] = useState<IIncidence[]>([]);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const { successMessage, error, errorMessage, passwordChanged } = useTypeSelector(
        (state) => state.login
    );
    const {incidences, ratingStatistic} = useTypeSelector(
        (state) => state.incidence
    )

    const {userLogged} = useTypeSelector(
        (state) => state.user
    );
    const {roles} = userLogged;
    const role = roles[0];

    useEffect(() => {
        dispatch(getUser());
        dispatch(getIncidences());
        dispatch(getIncidencesRatingStatistic())
    },[dispatch])

    useEffect(() => {
        dispatch(getIncidencesStatisticForWeek())
        dispatch(getIncidencesStatisticForYear())
    },[dispatch,incidences])

    useEffect(() => {
        if (incidences){
            setAllIncidences(incidences)
        }

        if (allIncidences.length > 0){
            let waitSupport = 0;
            let progressSupport = 0;
            let sheduleSupport = 0;
            let closeSupport = 0;
            allIncidences.forEach(function (incidence) {
                switch (incidence.incidenceStatus) {
                    case "Pendente": waitSupport = waitSupport + 1 ;
                        break;
                    case "Em andamento": progressSupport = progressSupport + 1;
                        break;
                    case "Agendado": sheduleSupport = sheduleSupport + 1;
                        break;
                    case "Terminado": closeSupport = closeSupport + 1;
                        break;
                }
            })
            setWait(waitSupport);
            setProgress(progressSupport)
            setShedule(sheduleSupport)
            setClose(closeSupport);
        }

    },[incidences, allIncidences])

    useEffect(() => {
        if(passwordChanged){
            enqueueSnackbar(successMessage, {
                variant: 'success'
            })
        }

        if (error){
            enqueueSnackbar(errorMessage, {
                variant: 'error'
            })
        }
    }, [successMessage, error, errorMessage, passwordChanged, enqueueSnackbar ]);

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-button-pause text-danger" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Pendente</p>
                                            <CardTitle tag="p">{wait}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="nc-icon nc-button-pause text-danger" />
                                    Aguardando atendimento
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-user-run text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Em andamento</p>
                                            <CardTitle tag="p">{progress}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="nc-icon nc-user-run text-primary" /> Suporte em andamento
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="fa fa-calendar text-warning" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Agendado</p>
                                            <CardTitle tag="p">{shedule}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar text-warning" /> Suporte agendado
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-check-2 text-success" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Terminado</p>
                                            <CardTitle tag="p">{close}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="nc-icon nc-check-2 text-success" /> Suporte terminado
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                {
                    role !== "Collaborator" &&
                        <>
                            <Row>
                                <Col md="12">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h5">Estatística semanal</CardTitle>
                                            <p className="card-category">Estatística das incidências desta semana</p>
                                        </CardHeader>
                                        <CardBody>
                                            <LineChart/>
                                        </CardBody>
                                        <CardFooter>
                                            <div className="chart-legend">
                                                <i className="fa fa-circle text-warning" /> Incidências Submetidas{" "}
                                                <i className="fa fa-circle" style={{color: "#FF5733"}} /> Incidências Em andamento{" "}
                                                <i className="fa fa-circle text-info" /> Incidências Terminadas{" "}
                                                <i className="fa fa-circle" style={{color: "#A3EBB1"}} /> Incidências Canceladas
                                            </div>
                                            <hr />
                                            <div className="stats">
                                                <i className="fa fa-history" /> Actualizado 3 minutos atrás
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h5">Satisfação do utilizador </CardTitle>
                                            <p className="card-category">Respostas recebidas</p>
                                        </CardHeader>
                                        <CardBody style={{ height: "210px" }}>
                                            <Row>
                                                <Col>
                                                    <p>Respostas Recebidas</p>
                                                    <p style={{fontSize: 22}}> {ratingStatistic?.total}</p>
                                                </Col>
                                                <Col>
                                                    <p>Positivas</p>
                                                    <p style={{fontSize: 22}}>{ratingStatistic?.good} % <i className="fas fa-smile text-success" /> </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <p>Neutras</p>
                                                    <p style={{fontSize: 22}}>{ratingStatistic?.neutral} % <i className="fas fa-meh text-warning" /> </p>
                                                </Col>
                                                <Col>
                                                    <p>Negativas</p>
                                                    <p style={{fontSize: 22}}>{ratingStatistic?.negative} % <i className="fas fa-frown text-danger" /> </p>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                        <CardFooter>
                                            <div className="legend">
                                                <i className="fa fa-circle text-success" /> Positivas{"  "}
                                                <i className="fa fa-circle text-warning" /> Neutras{"  "}
                                                <i className="fa fa-circle text-danger" /> Negativas{"  "}
                                            </div>
                                            <hr />
                                            <div className="stats">
                                                <i className="fa fa-calendar" /> Estatística das respostas
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col md="8">
                                    <Card className="card-chart">
                                        <CardHeader>
                                            <CardTitle tag="h5">Estatística anual </CardTitle>
                                            <p className="card-category">Estatística das incidências do presente ano</p>
                                        </CardHeader>
                                        <CardBody>
                                            <LineChartYear/>
                                        </CardBody>
                                        <CardFooter>
                                            <div className="chart-legend">
                                                <i className="fa fa-circle text-warning" /> Submetidas{"  "}{"  "}
                                                <i className="fa fa-circle" style={{color: "#FF5733"}} /> Em andamento{"  "}{" "}
                                                <i className="fa fa-circle text-info" /> Terminadas{"  "}{"  "}
                                                <i className="fa fa-circle" style={{color: "#A3EBB1"}} /> Canceladas
                                            </div>
                                            <hr />
                                            <div className="card-stats">
                                                <i className="fa fa-check" /> Dados do presente ano
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                }

                {
                    role === "Collaborator" &&
                        <>
                            <FAQ/>
                        </>
                }

            </div>
        </>
    );
};

export default Dashboard;
