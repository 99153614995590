import {IError} from "../../models/error";
import {IIncidencePriority} from "../../models/incidencePriority";

export enum IncidencePriorityActionType {
    GET_INCIDENCE_PRIORITY_LOADING = "GET_INCIDENCE_PRIORITY_LOADING",
    GET_INCIDENCE_PRIORITY_SUCCESS = "GET_INCIDENCE_PRIORITY_SUCCESS",
    GET_INCIDENCE_PRIORITY_FAIL = "GET_INCIDENCE_PRIORITY_FAIL",
}

interface IGetIncidencePriorityLoading {
    type: IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_LOADING
}

interface IGetIncidencePrioritySuccess {
    type: IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_SUCCESS,
    payload: Array<IIncidencePriority>
}

interface IGetIncidencePriorityFail {
    type: IncidencePriorityActionType.GET_INCIDENCE_PRIORITY_FAIL,
    payload: IError
}

export type IncidencePriorityAction =
    IGetIncidencePriorityLoading | IGetIncidencePrioritySuccess | IGetIncidencePriorityFail;

