import {IRole} from "../../models/role";
import {RoleAction, RoleActionTypes} from "../actionTypes/role.actionTypes";

export interface IRoleState {
    applicationRoles: Array<IRole>,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    errorMessage: string,
    successMessage: string
}

const initialState: IRoleState = {
    applicationRoles: [],
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    errorMessage: '',
    successMessage: ''
}

const roleReducer = (state: IRoleState = initialState, action: RoleAction) => {
    switch (action.type) {
        case RoleActionTypes.GET_ROLES_LOADING:
            return {
                ...state,
                isLoading: true,
                errorMessage: "",
                successMessage: "",
                isSaving: false,
                error: false,
                success: false,
            }
        case RoleActionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                applicationRoles: action.payload
            }
        case RoleActionTypes.GET_ROLES_FAIL:
            return {...state, isLoading: false, errorMessage: action.payload}
        default:
            return state;
    }
}

export default roleReducer;
