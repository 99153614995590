import {IStatistic} from "../../../models/statistic";

interface IReportsYear {
    mes: string,
    pendente: number,
    andamento: number,
    agendado: number,
    cancelado: number,
    terminado: number
}

interface IReport extends IReportsYear {
    totalMonth: number
}

export const statisticYearForReport = (statisticYear: Array<IStatistic>) => {
    let january : IReport = {mes: "Janeiro", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let february : IReport = {mes: "Fevereiro", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let march : IReport = {mes: "Março", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let april : IReport = {mes: "Abril", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let may : IReport = {mes: "Maio", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let june : IReport = {mes: "Junho", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let july : IReport = {mes: "Julho", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let august : IReport = {mes: "Agosto", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let september : IReport = {mes: "Setembro", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let october : IReport = {mes: "Outubro", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let november : IReport = {mes: "Novembro", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let december : IReport = {mes: "Dezembro", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0, totalMonth: 0};
    let total : IReportsYear = {mes: "Total", pendente:0,andamento: 0, agendado: 0, cancelado: 0,terminado:0};
    let statistic: any;

    if (statisticYear.length > 0){
        statisticYear.forEach((u) => {
            switch (u.description) {
                case '1':
                    statistic = statisticStatus(u, january,total);
                    january = statistic.report;
                    total = statistic.total
                    break
                case '2':
                    statistic = statisticStatus(u, february, total);
                    february = statistic.report;
                    total = statistic.total
                    break
                case '3':
                    statistic = statisticStatus(u, march, total);
                    march = statistic.report;
                    total = statistic.total
                    break
                case '4':
                    statistic = statisticStatus(u, april,total);
                    april = statistic.report
                    total = statistic.total
                    break
                case '5':
                    statistic = statisticStatus(u, may, total);
                    may = statistic.report
                    total = statistic.total
                    break
                case '6':
                    statistic = statisticStatus(u, june, total);
                    june = statistic.report
                    total = statistic.total
                    break
                case '7':
                    statistic = statisticStatus(u, july, total);
                    july = statistic.report
                    total = statistic.total
                    break
                case '8':
                    statistic = statisticStatus(u, august, total);
                    august = statistic.report
                    total = statistic.total
                    break
                case '9':
                    statistic = statisticStatus(u, september, total);
                    september = statistic.report
                    total = statistic.total
                    break
                case '10':
                    statistic = statisticStatus(u, october, total);
                    october = statistic.report
                    total = statistic.total
                    break
                case '11':
                    statistic = statisticStatus(u, november, total);
                    november = statistic.report
                    total = statistic.total
                    break
                case '12':
                    statistic = statisticStatus(u, december, total);
                    december = statistic.report
                    total = statistic.total
                    break
            }
        })
    }

    january.totalMonth = statisticTotal(january);
    february.totalMonth = statisticTotal(february);
    march.totalMonth = statisticTotal(march);
    april.totalMonth = statisticTotal(april);
    may.totalMonth = statisticTotal(may);
    june.totalMonth = statisticTotal(june);
    july.totalMonth = statisticTotal(july);
    august.totalMonth = statisticTotal(august);
    september.totalMonth = statisticTotal(september);
    october.totalMonth = statisticTotal(october);
    november.totalMonth = statisticTotal(november);
    december.totalMonth = statisticTotal(december);

    return [
        january, february, march, april, may, june, july, august, september, october, november, december, total
    ]
}


const statisticStatus = (statistic: IStatistic, report: IReportsYear, total: IReportsYear) => {

    switch (statistic.status) {
        case 'Pendente':
            report.pendente += statistic.count;
            total.pendente += statistic.count;
            break
        case 'Em andamento':
            report.andamento +=statistic.count;
            total.andamento +=statistic.count;
            break
        case 'Agendado':
            report.agendado +=statistic.count;
            total.agendado +=statistic.count;
            break
        case 'Terminado':
            report.terminado +=statistic.count;
            total.terminado +=statistic.count;
            break
        case 'Cancelado':
            report.cancelado +=statistic.count;
            total.cancelado +=statistic.count;
            break
    }

    return {report: report, total: total};
}

const statisticTotal = (statisticStatusTotal : IReport)=> {
    return statisticStatusTotal.pendente + statisticStatusTotal.andamento +
        statisticStatusTotal.agendado + statisticStatusTotal.cancelado +
        statisticStatusTotal.terminado;
}

