import {IShedule} from "../../models/Shedule";
import {IError} from "../../models/error";

export enum SheduleActionTypes {
    GET_SHEDULES_LOADING = "GET_SHEDULES_LOADING",
    GET_SHEDULES_SUCCESS = "GET_SHEDULES_SUCCESS",
    GET_SHEDULES_FAIL = "GET_SHEDULES_FAIL",
    GET_SHEDULES_BY_TIME_LOADING = "GET_SHEDULES_BY_TIME_LOADING",
    GET_SHEDULES_BY_TIME_SUCCESS = "GET_SHEDULES_BY_TIME_SUCCESS",
    GET_SHEDULES_BY_TIME_FAIL = "GET_SHEDULES_BY_TIME_FAIL",
    ADD_SHEDULES_SAVING = "ADD_SHEDULES_SAVING",
    ADD_SHEDULES_SUCCESS = "ADD_SHEDULES_SUCCESS",
    ADD_SHEDULES_FAIL = "ADD_SHEDULES_FAIL",
    EDIT_SHEDULES_SAVING = "EDIT_SHEDULES_SAVING",
    EDIT_SHEDULES_SUCCESS = "EDIT_SHEDULES_SUCCESS",
    EDIT_SHEDULES_FAIL = "EDIT_SHEDULES_FAIL",
    REMOVE_SHEDULE_SAVING = "REMOVE_SHEDULE_SAVING",
    REMOVE_SHEDULE_SUCCESS = "REMOVE_SHEDULE_SUCCESS",
    REMOVE_SHEDULE_FAIL = "REMOVE_SHEDULE_FAIL",
}

interface IGetShedulesLoading {
    type: SheduleActionTypes.GET_SHEDULES_LOADING
}

interface IGetShedulesSuccess {
    type: SheduleActionTypes.GET_SHEDULES_SUCCESS,
    payload: Array<IShedule>
}

interface IGetShedulesFail {
    type: SheduleActionTypes.GET_SHEDULES_FAIL,
    payload: IError
}

interface IGetShedulesByTimeLoading {
    type: SheduleActionTypes.GET_SHEDULES_BY_TIME_LOADING
}

interface IGetShedulesByTimeSuccess {
    type: SheduleActionTypes.GET_SHEDULES_BY_TIME_SUCCESS,
    payload: Array<IShedule>
}

interface IGetShedulesByTimeFail {
    type: SheduleActionTypes.GET_SHEDULES_BY_TIME_FAIL,
    payload: IError
}

interface IAddSheduleSaving {
    type: SheduleActionTypes.ADD_SHEDULES_SAVING
}

interface IAddSheduleSuccess {
    type: SheduleActionTypes.ADD_SHEDULES_SUCCESS,
    payload: IShedule
}

interface IAddSheduleFail {
    type: SheduleActionTypes.ADD_SHEDULES_FAIL,
    payload: IError
}

interface IEditSheduleSaving {
    type: SheduleActionTypes.EDIT_SHEDULES_SAVING
}

interface IEditSheduleSuccess {
    type: SheduleActionTypes.EDIT_SHEDULES_SUCCESS,
    payload: IShedule
}

interface IEditSheduleFail {
    type: SheduleActionTypes.EDIT_SHEDULES_FAIL,
    payload: IError
}

interface IRemoveSheduleSaving {
    type: SheduleActionTypes.REMOVE_SHEDULE_SAVING
}

interface IRemoveSheduleSuccess {
    type: SheduleActionTypes.REMOVE_SHEDULE_SUCCESS,
    payload: IShedule
}

interface IRemoveSheduleFail {
    type: SheduleActionTypes.REMOVE_SHEDULE_FAIL,
    payload: IError
}


export type SheduleAction =
    IGetShedulesLoading | IGetShedulesSuccess | IGetShedulesFail |
    IGetShedulesByTimeLoading | IGetShedulesByTimeSuccess | IGetShedulesByTimeFail |
    IAddSheduleSaving | IAddSheduleSuccess | IAddSheduleFail |
    IRemoveSheduleSaving | IRemoveSheduleSuccess | IRemoveSheduleFail |
    IEditSheduleSaving | IEditSheduleSuccess | IEditSheduleFail;
