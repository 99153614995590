import {IFaq} from "../../models/faq";
import {IError} from "../../models/error";

export enum FaqActionTypes {
    GET_FAQS_LOADING = "GET_FAQS_LOADING",
    GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS",
    GET_FAQS_FAIL = "GET_FAQS_FAIL",
    ADD_FAQ_SAVING = "ADD_FAQ_SAVING",
    ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS",
    ADD_FAQ_FAIL = "ADD_FAQ_FAIL",
    REMOVE_FAQ_SAVING = "REMOVE_FAQ_SAVING",
    REMOVE_FAQ_SUCCESS = "REMOVE_FAQ_SUCCESS",
    REMOVE_FAQ_FAIL = "REMOVE_FAQ_FAIL",
}

interface IGetFaqsLoading {
    type: FaqActionTypes.GET_FAQS_LOADING
}

interface IGetFaqsSuccess {
    type: FaqActionTypes.GET_FAQS_SUCCESS,
    payload: Array<IFaq>
}

interface IGetFaqsFail {
    type: FaqActionTypes.GET_FAQS_FAIL,
    payload: IError
}

interface IAddFaqSaving {
    type: FaqActionTypes.ADD_FAQ_SAVING
}

interface IAddFaqSuccess {
    type: FaqActionTypes.ADD_FAQ_SUCCESS,
    payload: IFaq
}

interface IAddFaqFail {
    type: FaqActionTypes.ADD_FAQ_FAIL,
    payload: IError
}

interface IRemoveFaqSaving {
    type: FaqActionTypes.REMOVE_FAQ_SAVING
}

interface IRemoveFaqSuccess {
    type: FaqActionTypes.REMOVE_FAQ_SUCCESS,
    payload: IFaq
}

interface IRemoveFaqFail {
    type: FaqActionTypes.REMOVE_FAQ_FAIL,
    payload: IError
}

export type FaqAction =
    IGetFaqsLoading | IGetFaqsSuccess | IGetFaqsFail |
    IAddFaqSaving | IAddFaqSuccess | IAddFaqFail |
    IRemoveFaqSaving | IRemoveFaqSuccess | IRemoveFaqFail;
