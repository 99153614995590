import {IStatistic} from "../models/statistic";

//Month
export const statisticHandler = (statisticWeek: Array<IStatistic>) => {
    if(statisticWeek.length > 0){
        return statisticWeekHandler(statisticWeek);
    }
}

//Year
export const statisticYearHandler = (statisticYear: Array<IStatistic>) => {
    if(statisticYear.length > 0){
        return statisticYearHandle(statisticYear );
    }
}

export const statisticWeekHandler = (statisticWeek: Array<IStatistic>) => {
    let total: Array<number> = [0,0,0,0,0,0,0];
    let monday : Array<IStatistic> = [];
    let tuesday : Array<IStatistic> = [];
    let wednesday : Array<IStatistic> = [];
    let thursday : Array<IStatistic> = [];
    let friday : Array<IStatistic> = [];
    let saturday : Array<IStatistic> = [];
    let sunday : Array<IStatistic> = [];
    let terminated: Array<number> = [];
    let canceled: Array<number> = [];
    let progress: Array<number> = [];

    if (statisticWeek.length > 0){
        statisticWeek.forEach((u) => {
            switch (u.description) {
                case 'Monday':
                    total[0] = total[0] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        monday.push(u);
                    }
                    break
                case 'Tuesday':
                    total[1] = total[1] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        tuesday.push(u);
                    }
                    break
                case 'Wednesday':
                    total[2] = total[2] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        wednesday.push(u);
                    }
                    break
                case 'Thursday':
                    total[3] = total[3] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        thursday.push(u);
                    }
                    break
                case 'Friday':
                    total[4] = total[4] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        friday.push(u);
                    }
                    break
                case 'Saturday':
                    total[5] = total[5] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        saturday.push(u);
                    }
                    break
                case 'Sunday':
                    total[6] = total[6] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        sunday.push(u);
                    }
                    break
            }
        })

        monday = empty("Monday",monday)
        progress.push(get('Em andamento',monday))
        terminated.push(get('Terminado', monday))
        canceled.push(get('Cancelado', monday))

        tuesday = empty("Tuesday",tuesday)
        progress.push(get('Em andamento',tuesday))
        terminated.push(get('Terminado', tuesday))
        canceled.push(get('Cancelado', tuesday))

        wednesday = empty("Wednesday",wednesday)
        progress.push(get('Em andamento',wednesday))
        terminated.push(get('Terminado', wednesday))
        canceled.push(get('Cancelado', wednesday))

        thursday = empty("Thursday",thursday)
        progress.push(get('Em andamento',thursday))
        terminated.push(get('Terminado', thursday))
        canceled.push(get('Cancelado', thursday))

        friday = empty("Friday", friday)
        progress.push(get('Em andamento',friday))
        terminated.push(get('Terminado', friday))
        canceled.push(get('Cancelado', friday))

        saturday = empty("Saturday", saturday)
        progress.push(get('Em andamento',saturday))
        terminated.push(get('Terminado', saturday))
        canceled.push(get('Cancelado', saturday))

        sunday = empty("Sunday",sunday)
        progress.push(get('Em andamento',sunday))
        terminated.push(get('Terminado', sunday))
        canceled.push(get('Cancelado', sunday))

        return {total, progress, terminated, canceled};
    }
}


export const statisticYearHandle = (statisticYear: Array<IStatistic>) => {
    let total: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let january : Array<IStatistic> = [];
    let february : Array<IStatistic> = [];
    let march : Array<IStatistic> = [];
    let april : Array<IStatistic> = [];
    let may : Array<IStatistic> = [];
    let june : Array<IStatistic> = [];
    let july : Array<IStatistic> = [];
    let august : Array<IStatistic> = [];
    let september : Array<IStatistic> = [];
    let october : Array<IStatistic> = [];
    let november : Array<IStatistic> = [];
    let december : Array<IStatistic> = [];
    let progress: Array<number> = [];
    let terminated: Array<number> = [];
    let canceled: Array<number> = [];

    if (statisticYear.length > 0){
        statisticYear.forEach((u) => {
            switch (u.description) {
                case '1':
                    total[0] = total[0] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        january.push(u);
                    }
                    break
                case '2':
                    total[1] = total[1] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        february.push(u);
                    }
                    break
                case '3':
                    total[2] = total[2] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        march.push(u);
                    }
                    break
                case '4':
                    total[3] = total[3] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        april.push(u);
                    }
                    break
                case '5':
                    total[4] = total[4] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        may.push(u);
                    }
                    break
                case '6':
                    total[5] = total[5] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        june.push(u);
                    }
                    break
                case '7':
                    total[6] = total[6] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        july.push(u);
                    }
                    break
                case '8':
                    total[7] = total[7] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        august.push(u);
                    }
                    break
                case '9':
                    total[8] = total[8] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        september.push(u);
                    }
                    break
                case '10':
                    total[9] = total[9] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        october.push(u);
                    }
                    break
                case '11':
                    total[10] = total[10] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        november.push(u);
                    }
                    break
                case '12':
                    total[11] = total[11] + u.count;
                    if (u.status === 'Terminado' || u.status === 'Cancelado' ||
                        u.status === 'Em andamento'){
                        december.push(u);
                    }
                    break
            }
        })

        january = empty("January",january)
        progress.push(get('Em andamento',january))
        terminated.push(get('Terminado', january))
        canceled.push(get('Cancelado', january))

        february = empty("February",february)
        progress.push(get('Em andamento',february))
        terminated.push(get('Terminado', february))
        canceled.push(get('Cancelado', february))

        march = empty("March",march)
        progress.push(get('Em andamento',march))
        terminated.push(get('Terminado', march))
        canceled.push(get('Cancelado', march))

        april = empty("April",april)
        progress.push(get('Em andamento',april))
        terminated.push(get('Terminado', april))
        canceled.push(get('Cancelado', april))

        may = empty("May", may)
        progress.push(get('Em andamento',may))
        terminated.push(get('Terminado', may))
        canceled.push(get('Cancelado', may))

        june = empty("June", june)
        progress.push(get('Em andamento',june))
        terminated.push(get('Terminado', june))
        canceled.push(get('Cancelado', june))

        july = empty("July",july)
        progress.push(get('Em andamento',july))
        terminated.push(get('Terminado', july))
        canceled.push(get('Cancelado', july))

        august = empty("August",august)
        progress.push(get('Em andamento',august))
        terminated.push(get('Terminado', august))
        canceled.push(get('Cancelado', august))

        september = empty("September",september)
        progress.push(get('Em andamento',september))
        terminated.push(get('Terminado', september))
        canceled.push(get('Cancelado', september))

        october = empty("October",october)
        progress.push(get('Em andamento',october))
        terminated.push(get('Terminado', october))
        canceled.push(get('Cancelado', october))

        november = empty("November",november)
        progress.push(get('Em andamento',november))
        terminated.push(get('Terminado', november))
        canceled.push(get('Cancelado', november))

        december = empty("November",december)
        progress.push(get('Em andamento',december))
        terminated.push(get('Terminado', december))
        canceled.push(get('Cancelado', december))

        return {total, progress, terminated, canceled};
    }
}


const get = (desc: string, data: Array<IStatistic>) : number => {
    let result = 0;
    data.forEach((value) => {
        if (value.status === desc){
            result = value.count
        }
    })
    return result;
}

const empty = (desc: string, data: Array<IStatistic>) : Array<IStatistic> => {
    if (data.length > 0 && data.length !== 0){
        if (data.length === 1){
            if (data[0].status === 'Terminado'){
                data.push({description: desc, status: 'Terminado', count: data[0].count})
            }else{
                if (data[0].status === 'Em andamento'){
                    data.push({description: desc, status: 'Em andamento', count: data[0].count})
                }else{
                    data.push({description: desc, status: 'Cancelado', count: data[0].count})
                }
            }
        }
        return data;
    }else{
        return [
            {description: desc, status: 'Terminado', count: 0},
            {description: desc, status: 'Cancelado', count: 0},
            {description: desc, status: 'Em andamento', count: 0}
        ]
    }
}

export const dashboardNASDAQChart = {
    data: (canvas: any) => {
        return {
            labels: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
            ],
            datasets: [
                {
                    data: [0, 19, 15, 20, 30, 40, 40, 50, 25, 30, 50, 70],
                    fill: false,
                    borderColor: "#fbc658",
                    backgroundColor: "transparent",
                    pointBorderColor: "#fbc658",
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    pointBorderWidth: 8,
                    tension: 0.4,
                },
                {
                    data: [0, 7, 10, 12, 20, 27, 30, 34, 42, 45, 55, 63],
                    fill: false,
                    borderColor: "#51CACF",
                    backgroundColor: "transparent",
                    pointBorderColor: "#51CACF",
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    pointBorderWidth: 8,
                    tension: 0.4,
                },
                {
                    data: [0, 2, 3, 7, 5, 1, 4, 7, 12, 6, 9, 13],
                    fill: false,
                    borderColor: "#A3EBB1",
                    backgroundColor: "transparent",
                    pointBorderColor: "#A3EBB1",
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    pointBorderWidth: 8,
                    tension: 0.4,
                },
            ],
        };
    },
    options: {
        plugins: {
            legend: { display: false },
        },
    },
};

export const dashboardEmailStatisticsChart = {
    data: (canvas: any) => {
        return {
            labels: [1, 2, 3, 4],
            datasets: [
                {
                    label: "Emails",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    backgroundColor: ["#4acccd", "#e3e3e3", "#fcc468", "#ef8157", "#A3EBB1"],
                    borderWidth: 0,
                    data: [60, 15, 10, 10, 5],
                },
            ],
        };
    },
    options: {
        plugins: {
            legend: { display: false },
            tooltip: { enabled: true },
        },
        maintainAspectRatio: false,
        pieceLabel: {
            render: "percentage",
            fontColor: ["white"],
            precision: 2,
        },
        scales: {
            y: {
                ticks: {
                    display: false,
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
            x: {
                barPercentage: 1.6,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
    },
};

export const dashboard24HoursPerformanceChart = {
    data: (canvas : any) => {
        return {
            labels: [
                "Segunda",
                "Terça",
                "Quarta",
                "Quinta",
                "Sexta",
                "Sabado",
                "Domingo",
            ],
            datasets: [
                {
                    borderColor: "#6bd098",
                    backgroundColor: "#6bd098",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 3,
                    tension: 0.4,
                    fill: true,
                    data: [300, 310, 316, 322, 330, 326, 333],
                },
                {
                    borderColor: "#f17e5d",
                    backgroundColor: "#f17e5d",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 3,
                    tension: 0.4,
                    fill: true,
                    data: [320, 340, 365, 360, 370, 385, 390],
                },
                {
                    borderColor: "#fcc468",
                    backgroundColor: "#fcc468",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 3,
                    tension: 0.4,
                    fill: true,
                    data: [370, 394, 415, 409, 425, 445, 460],
                },
            ],
        };
    },
    options: {
        plugins: {
            legend: { display: false },
            tooltip: { enabled: true },
        },
        scales: {
            y: {
                ticks: {
                    color: "#9f9f9f",
                    beginAtZero: false,
                    maxTicksLimit: 5,
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
            x: {
                barPercentage: 1.6,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    padding: 20,
                    color: "#9f9f9f",
                },
            },
        },
    },
};
