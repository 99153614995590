import {Dispatch} from "redux";
import {UserAction, UserActionTypes} from "../actionTypes/user.actionTypes";
import {Api} from "../../services/api";
import {IUser} from "../../models/user";
import {IUserData} from "../../views/admin/Users/Users";

export const getUser = () =>
    async (dispatch: Dispatch<UserAction>) => {
    let api = new Api();

    try {
        dispatch({
           type: UserActionTypes.GET_USER_LOADING
        });

        await api.get<IUser>("/users/user")
            .then(result => {

                if (result.status === 200) {
                    dispatch({
                        type: UserActionTypes.GET_USER_SUCCESS,
                        payload: result.data
                    });
                }

            })
            .catch(e => {
                dispatch({
                    type: UserActionTypes.GET_USER_FAIL,
                    payload: e
                })
            })

    }catch (e: any) {
        dispatch({
            type: UserActionTypes.GET_USER_FAIL,
            payload: e
        })
    }
}

export const getUsers = () =>
    async (dispatch: Dispatch<UserAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: UserActionTypes.GET_USERS_LOADING
            });

            await api.get<IUser[]>("/users")
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: UserActionTypes.GET_USERS_SUCCESS,
                            payload: result.data
                        });
                    }

                })
                .catch(e => {
                    dispatch({
                        type: UserActionTypes.GET_USERS_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: UserActionTypes.GET_USERS_FAIL,
                payload: e
            })
        }
}

export const createUser = (userData: IUserData) =>
    async (dispatch: Dispatch<UserAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: UserActionTypes.CREATE_USER_SAVING
            });

            await api.post<IUser>("/users", userData)
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: UserActionTypes.CREATE_USER_SUCCESS,
                            payload: result.data
                        });
                    }

                })
                .catch(e => {
                    dispatch({
                        type: UserActionTypes.CREATE_USER_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: UserActionTypes.CREATE_USER_FAIL,
                payload: e
            })
        }
}


export const editUser = (userData: IUserData) =>
    async (dispatch: Dispatch<UserAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: UserActionTypes.EDIT_USER_SAVING
            });

            await api.put<IUser>("/users", userData)
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: UserActionTypes.EDIT_USER_SUCCESS,
                            payload: result.data
                        });
                    }

                })
                .catch(e => {
                    dispatch({
                        type: UserActionTypes.EDIT_USER_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: UserActionTypes.EDIT_USER_FAIL,
                payload: e
            })
        }
}

export const deleteUser = (email: string) =>
    async (dispatch: Dispatch<UserAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: UserActionTypes.REMOVE_USER_SAVING
            });

            await api.delete<IUser>(`/users/${email}`)
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: UserActionTypes.REMOVE_USER_SUCCESS,
                            payload: result.data
                        });
                    }

                })
                .catch(e => {
                    dispatch({
                        type: UserActionTypes.REMOVE_USER_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: UserActionTypes.REMOVE_USER_FAIL,
                payload: e
            })
        }
}
