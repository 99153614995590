import {Dispatch} from "redux";
import {RatingAction, RatingActionTypes} from "../actionTypes/rating.actionTypes";
import {Api} from "../../services/api";
import {IRating} from "../../models/rating";

export const getRatings = () =>
    async (dispatch: Dispatch<RatingAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: RatingActionTypes.GET_RATINGS_LOADING
            });

            await api.get<IRating[]>("/rating")
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: RatingActionTypes.GET_RATINGS_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: RatingActionTypes.GET_RATINGS_FAIL,
                        payload: e
                    })
                })
        }catch (e: any) {
            dispatch({
                type: RatingActionTypes.GET_RATINGS_FAIL,
                payload: e
            })
        }

}
