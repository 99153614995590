import jwt from "jsonwebtoken";

class Auth {
    private authenticated: boolean;
    private readonly jwtSecret: string;

    constructor() {
        this.authenticated = false;
        this.jwtSecret = process.env.REACT_APP_JWT_SECRET as string;
    }

    login(token: string) {
        localStorage.setItem('token', token);
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
    }

    logout() {
        localStorage.clear();
    }

    isAuthenticated() {
        if (localStorage.getItem('isAuthenticated') == null || this.isTokenExpired()) {
            this.authenticated = false;
        } else {
            this.authenticated = JSON.parse(localStorage.getItem('isAuthenticated') ?? 'false')
        }

        return this.authenticated;
    }

    isTokenExpired() {
        const token = localStorage.getItem('token');
        let expired = true;

        if(token != null) {
            expired = false;

            // jwt.verify(token, this.jwtSecret, function (err) {
            //     if (err) expired = true; // Manage different errors here (Expired, untrusted...)
            // });
        }

        return expired;
    }

    getToken() {
        return localStorage.getItem("token");
    }

    decodeToken(token: string) {
        return jwt.verify(token, this.jwtSecret);
    }
}

export default new Auth();
