import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {IUser} from "../../../models/user";
import {useHistory} from "react-router";
import {IFaq} from "../../../models/faq";
import {useDispatch} from "react-redux";
import {getFaqs} from "../../../store/actionCreators/faq.actionCreator";

const FAQ = () => {
    const [user,setUser] = useState<IUser>();
    const [allFaqs,setAllFaqs] = useState<Array<IFaq>>([]);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFaqs())
    }, [dispatch])

    const {userLogged} = useTypeSelector(
        (state) => state.user
    )

    const {faqs} = useTypeSelector(
        (state) => state.faq
    )

    useEffect(() => {
        if (userLogged){
            setUser(userLogged)
        }
    },[userLogged])

    useEffect(() => {
        if (faqs){
            setAllFaqs(faqs)
        }

    },[faqs])

    const update = () => {
        history.push("/admin/settings")
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col>
                       <Card>
                           <CardHeader>
                               <Row>
                                   <CardTitle tag="h4" className="col-md-9">Base de conhecimento</CardTitle>
                                   {
                                       user?.roles[0] !== "Collaborator" &&
                                           <Button className="ml-auto mr-3" onClick={update}>
                                               <i className='nc-icon nc-settings-gear-65 mr-2'/> Actualizar
                                           </Button>
                                   }

                               </Row>
                           </CardHeader>
                           <CardBody>
                               {
                                   allFaqs.map((faq,key) => {
                                       return (
                                           <Row className="mt-2" key={key}>
                                               <Col>
                                                   <i className="fas fa-book-open text-success" />
                                                   <a target="_blank" rel="noreferrer" className="ml-3 text-muted" style={{fontSize: "medium"}} href={faq.url}>{faq.description}</a>
                                               </Col>
                                           </Row>
                                       )
                                   } )
                               }
                           </CardBody>
                       </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default FAQ;
