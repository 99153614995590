import {IShedule} from "../../models/Shedule";
import {SheduleAction, SheduleActionTypes} from "../actionTypes/shedule.actionType";

export interface ISheduleState {
    shedules: Array<IShedule>,
    shedulesByTime: Array<IShedule>,
    isLoading: boolean,
    isSavingShedule: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: ISheduleState = {
    shedules: [],
    shedulesByTime: [],
    isLoading: false,
    isSavingShedule: false,
    error: false,
    success: false,
    errorMessage: '',
    successMessage: ''
}

const sheduleReducer = (state: ISheduleState = initialState, action: SheduleAction) => {
    switch (action.type) {
        case SheduleActionTypes.GET_SHEDULES_LOADING:
            return {
                ...state,
                isLoading: true,
                isSavingShedule: false,
                error: false,
                success: false,
                successMessage: '',
                errorMessage: ''
            }
        case SheduleActionTypes.GET_SHEDULES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSavingShedule: false,
                error: false,
                success: false,
                shedules: action.payload
            }
        case SheduleActionTypes.GET_SHEDULES_FAIL:
            return {
                ...state,
                isLoading: false,
                isSavingShedule: false,
                error: false,
                success: false,
                errorMessage: action.payload
            }
        case SheduleActionTypes.GET_SHEDULES_BY_TIME_LOADING:
            return {
                ...state,
                isLoading: true,
                isSavingShedule: false,
                error: false,
                success: false,
                successMessage: '',
                errorMessage: ''
            }
        case SheduleActionTypes.GET_SHEDULES_BY_TIME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSavingShedule: false,
                error: false,
                success: false,
                shedulesByTime: action.payload
            }
        case SheduleActionTypes.GET_SHEDULES_BY_TIME_FAIL:
            return {
                ...state,
                isLoading: false,
                isSavingShedule: false,
                error: false,
                success: false,
                errorMessage: action.payload
            }
        case SheduleActionTypes.ADD_SHEDULES_SAVING:
            return {
                ...state,
                isSavingShedule: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case SheduleActionTypes.ADD_SHEDULES_SUCCESS:
            return {
                ...state,
                isSavingShedule: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Agenda adicionada com successo!',
                shedules: [...state.shedules, action.payload]
            }
        case SheduleActionTypes.ADD_SHEDULES_FAIL:
            return {
                ...state,
                isSavingShedule: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao adicionar a agenda. Tente novamente!',
                successMessage: '',
            }
        case SheduleActionTypes.EDIT_SHEDULES_SAVING:
            return {
                ...state,
                isSavingShedule: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case SheduleActionTypes.EDIT_SHEDULES_SUCCESS:
            const sheduleUpdate = state.shedules.map(shedule => {
                if(shedule.id === action.payload.id)
                    return action.payload
                else return shedule
            });
            return {
                ...state,
                isSavingShedule: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Agendamento Actualizado com successo!',
                shedules: sheduleUpdate
            }
        case SheduleActionTypes.EDIT_SHEDULES_FAIL:
            return {
                ...state,
                isSavingShedule: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao actualizar o agendamento. Tente novamente!',
                successMessage: '',
            }

        case SheduleActionTypes.REMOVE_SHEDULE_SAVING:
            return {
                ...state,
                isSavingShedule: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case SheduleActionTypes.REMOVE_SHEDULE_SUCCESS:
            return {
                ...state,
                isSavingShedule: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Agendamento Removido com successo!',
                shedules: state.shedules.filter(shed => shed.id !== action.payload.id)
            }
        case SheduleActionTypes.REMOVE_SHEDULE_FAIL:
            return {
                ...state,
                isSavingShedule: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao remover o agendamento. Tente novamente!',
                successMessage: '',
            }
        default:
            return state;
    }
}

export default sheduleReducer;
