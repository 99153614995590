import {IIncidence} from "../../models/incidence";
import {IncidenceAction, IncidenceActionType} from "../actionTypes/incidence.actionType";
import {IStatistic} from "../../models/statistic";
import {IIncidenceRatingStatistic} from "../../models/incidenceRatingStatistic";
import {ITechnicianWithIncidentNumbers} from "../../models/TechnicianWithIncidentNumbers";

export interface IIncidenceState {
    incidences: Array<IIncidence>,
    statisticWeek: Array<IStatistic>,
    statisticYear: Array<IStatistic>,
    ratingStatistic: IIncidenceRatingStatistic,
    technicianWithIncidentNumbers: Array<ITechnicianWithIncidentNumbers>,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: IIncidenceState = {
    incidences: [],
    statisticWeek: [],
    statisticYear: [],
    ratingStatistic: {
        good: 0,
        neutral: 0,
        total: 0,
        negative: 0
    },
    technicianWithIncidentNumbers: [],
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: ''
}

const incidenceReducer = (state: IIncidenceState = initialState, action: IncidenceAction) => {
    switch (action.type) {
        case IncidenceActionType.GET_INCIDENCES_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false
            }
        case IncidenceActionType.GET_INCIDENCES_SUCCESS:
            return {...state,
                isLoading: false,
                incidences: action.payload
            }
        case IncidenceActionType.GET_INCIDENCES_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: "Occorreu um erro ao carregar as incidencisas"
            }
        case IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false
            }
        case IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_SUCCESS:
            return {...state,
                isLoading: false,
                technicianWithIncidentNumbers: action.payload
            }
        case IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.errorMessage
            }

        case IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_SUCCESS:
            const allocateTechnician = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Tecnico alocado com successo!',
                incidences: allocateTechnician
            }
        case IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                successMessage: '',
                errorMessage: action.payload.errorMessage
            }

        case IncidenceActionType.CHANGE_PRIORITY_SUPPORT_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.CHANGE_PRIORITY_SUPPORT_SUCCESS:
            const changePriorityIncidence = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Prioridade alterada com successo!',
                incidences: changePriorityIncidence
            }
        case IncidenceActionType.CHANGE_PRIORITY_SUPPORT_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                successMessage: '',
                errorMessage: 'Occorreu um erro ao alterar a prioridade de suporte. ' +
                    'Tente novamente!'
            }
        case IncidenceActionType.REQUEST_SUPPORT_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.REQUEST_SUPPORT_SUCCESS:
            return {...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Solicitacao enviada com successo!',
                incidences: [action.payload, ...state.incidences]
            }
        case IncidenceActionType.REQUEST_SUPPORT_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                errorMessage: 'Occorreu um erro ao submeter a solicitacao de suporte. ' +
                    'Tente novamente!'
            }
        case IncidenceActionType.CANCEL_INCIDENCE_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.CANCEL_INCIDENCE_SUCCESS:
            const cancelIncidence = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Solicitacao cancelada com successo!',
                incidences: cancelIncidence
            }
        case IncidenceActionType.CANCEL_INCIDENCE_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                errorMessage: 'Occorreu um erro ao cancelar a solicitacao de suporte. ' +
                    'Tente novamente!'
            }
        case IncidenceActionType.START_SUPPORT_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.START_SUPPORT_SUCCESS:
            const startSupport = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Inicio de suporte efectuado com successo!',
                incidences: startSupport
            }
        case IncidenceActionType.START_SUPPORT_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                errorMessage: 'Occorreu um erro ao iniciar o suporte. Tente novamente!'
            }
        case IncidenceActionType.FINISH_SUPPORT_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.FINISH_SUPPORT_SUCCESS:
            const finishSupport = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Termino de suporte efectuado com successo!',
                incidences: finishSupport
            }
        case IncidenceActionType.FINISH_SUPPORT_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                errorMessage: 'Occorreu um erro ao terminar o suporte. Tente novamente!'
            }
        case IncidenceActionType.SHEDULE_SUPPORT_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.SHEDULE_SUPPORT_SUCCESS:
            const sheduleSupport = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Agendamento de suporte efectuado com successo!',
                incidences: sheduleSupport
            }
        case IncidenceActionType.SHEDULE_SUPPORT_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                errorMessage: 'Occorreu um erro ao agendar o suporte. Tente novamente!'
            }
        case IncidenceActionType.RESTART_SUPPORT_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.RESTART_SUPPORT_SUCCESS:
            const restartSupport = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Reinicio de suporte efectuado com successo!',
                incidences: restartSupport
            }
        case IncidenceActionType.RESTART_SUPPORT_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                errorMessage: 'Occorreu um erro ao re-iniciar o suporte. Tente novamente!'
            }
        case IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false
            }
        case IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_SUCCESS:
            return {...state,
                isLoading: false,
                statisticWeek: action.payload
            }
        case IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: "Occorreu um erro ao carregar as incidencisas"
            }
        case IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false
            }
        case IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_SUCCESS:
            return {...state,
                isLoading: false,
                statisticYear: action.payload
            }
        case IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: "Occorreu um erro ao carregar as incidencisas"
            }

        case IncidenceActionType.ADD_INCIDENCE_RATING_SAVING:
            return {
                ...state,
                isSaving: true,
                success: false,
                error: false,
                errorMessage:'',
                successMessage: ''
            }
        case IncidenceActionType.ADD_INCIDENCE_RATING_SUCCESS:
            const ratingSupport = state.incidences.map(incidence => {
                if(incidence.title === action.payload.title)
                    return action.payload
                else return incidence
            });
            return {
                ...state,
                isSaving: false,
                success: true,
                error: false,
                successMessage: 'Satisfacao de suporte enviado com successo!',
                incidences: ratingSupport
            }
        case IncidenceActionType.ADD_INCIDENCE_RATING_FAIL:
            return {
                ...state,
                isSaving: false,
                success: false,
                error: true,
                errorMessage: 'Occorreu um erro ao enviar a satisfacao. Tente novamente!'
            }

        case IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false
            }
        case IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ratingStatistic: action.payload
            }
        case IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: "Occorreu um erro ao carregar as estatisticas de satisfacao"
            }

        default:
            return state;
    }
}

export default incidenceReducer;
