import React, {useEffect, useState} from 'react';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {useTypeSelector} from "../../../../hooks/useTypeSelector";
import {statisticHandler} from "../../../../variables/charts";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = () => {
    const [statisticProgressWeek,setStatisticProgressWeek] = useState<Array<number>>([])
    const [statisticCanceledWeek,setStatisticCanceledWeek] = useState<Array<number>>([])
    const [statisticTerminatedWeek,setStatisticTerminatedWeek] = useState<Array<number>>([])
    const [statisticTotalWeek,setStatisticTotalWeek] = useState<Array<number>>([])

    const {statisticWeek} = useTypeSelector(
        (state) => state.incidence
    );

    useEffect(() => {
        let statisticData = statisticHandler(statisticWeek)
        if (statisticData){
            setStatisticProgressWeek(statisticData.progress)
            setStatisticCanceledWeek(statisticData.canceled)
            setStatisticTerminatedWeek(statisticData.terminated)
            setStatisticTotalWeek(statisticData.total)
        }

    },[statisticWeek])

    const data = {
        labels: [
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sabado",
            "Domingo",
        ],
        datasets: [
            {
                data: statisticTotalWeek ? statisticTotalWeek : [0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#fbc658",
                backgroundColor: "transparent",
                pointBorderColor: "#fbc658",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
            {
                data: statisticProgressWeek ? statisticProgressWeek : [0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#FF5733",
                backgroundColor: "transparent",
                pointBorderColor: "#FF5733",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
            {
                data: statisticTerminatedWeek ? statisticTerminatedWeek : [0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#51CACF",
                backgroundColor: "transparent",
                pointBorderColor: "#51CACF",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
            {
                data: statisticCanceledWeek ? statisticCanceledWeek : [0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#A3EBB1",
                backgroundColor: "transparent",
                pointBorderColor: "#A3EBB1",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
        ]
    }

   const options = {
        responsive: true,
            plugins: {
            legend: { display: false },
        },
   }

   return (
       <>
            <Line
                data={data}
                options={options}
                width={400}
                height={100}
            />
       </>
    )
}

export default LineChart;
