import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	CardHeader,
	Spinner,
} from "reactstrap";
import { ILogin } from "../../models/login";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { loginAction } from "../../store/actionCreators/login.actionCreator";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import auth from "../../helpers/auth";
import {useSnackbar} from "notistack";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginData, setLoginData] = useState<ILogin>();
	const { error,login, isSaving, errorMessage} = useTypeSelector(
		(state) => state.login
	);

	const dispatch = useDispatch();
	const history = useHistory();
	const {enqueueSnackbar} = useSnackbar();

	const loginHandler = () => {
		dispatch(loginAction(email, password));
	};

	useEffect(() => {
		setLoginData(login);

        if(auth.isAuthenticated()){
            history.push("/admin/dashboard")
        }

    },[login, history, loginData?.token])


	useEffect(() => {
		const message = "Ocorreu um erro ao " +
			"iniciar sessao. Tente novamente!";

		if (error && message){
			enqueueSnackbar(errorMessage, {
				variant: 'error'
			})
		}
	}, [error, errorMessage, enqueueSnackbar ]);

	return (
		<>
			<Card className="h3  border ">
				<CardHeader>
					<div className="text-center text-muted mt-2 mb-2">
						<b style={{fontSize:"20px"}}> Inicie a sessão na sua conta </b>
					</div>
				</CardHeader>
				<CardBody className="px-lg-3 py-lg-3">
					<Form role="form">
						<FormGroup className="mb-4">
							<Input
								placeholder="Email"
								type="email"
								autoComplete="new-email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</FormGroup>
						<FormGroup className="mb-4">
							<Input
								placeholder="Senha"
								type="password"
								autoComplete="new-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</FormGroup>
						<div>
							{
								isSaving ?
									<Button
										block
										className="mt-4 p-2 text-capitalize"
										color="success"
										type="button"
										style={{fontSize:"17px", fontWeight:"400"}}
									>
										<Spinner color="light">
											Loading...
										</Spinner>
									</Button>
									:
									<Button
										block
										className="mt-4 p-2 text-capitalize"
										color="success"
										type="button"
										onClick={loginHandler}
										style={{fontSize:"17px", fontWeight:"400"}}
									>
										Iniciar
									</Button>
							}

						</div>
					</Form>
				</CardBody>
			</Card>
		</>

	);
}

export default Login;
