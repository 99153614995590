import ReportsYear from "./ReportsYear";

const Reports = () => {
    return(
        <>
            <div className="content">
                <ReportsYear/>
            </div>
        </>
    )
}

export default Reports;
