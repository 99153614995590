import {IUser} from "../../models/user";
import {UserAction, UserActionTypes} from "../actionTypes/user.actionTypes";

export interface IUserState {
    users: Array<IUser>,
    userLogged: IUser,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    errorMessage: string,
    successMessage: string
}

const initialState: IUserState = {
    users: [],
    userLogged: {
        fullName: "",
        username: "",
        email:"",
        department: "",
        roles: [],
        free: false
    },
    isLoading: false,
    errorMessage: "",
    successMessage: "",
    isSaving: false,
    error: false,
    success: false,
}

const userReducer = (state: IUserState = initialState, action: UserAction) => {
    switch (action.type) {
        case UserActionTypes.GET_USER_LOADING:
            return {
                ...state,
                isLoading: true,
                errorMessage: "",
                successMessage: "",
                isSaving: false,
                error: false,
                success: false
            }
        case UserActionTypes.GET_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userLogged: action.payload
            }
        case UserActionTypes.GET_USER_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload
            }
        case UserActionTypes.GET_USERS_LOADING:
            return {
                ...state,
                isLoading: true,
                errorMessage: "",
                successMessage: "",
                isSaving: false,
                error: false,
                success: false,
            }
        case UserActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload
            }
        case UserActionTypes.GET_USERS_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload
            }
        case UserActionTypes.CREATE_USER_SAVING:
            return {
                ...state,
                isSaving: true,
                errorMessage: "",
                successMessage: "",
                error: false,
                success: false,
            }
        case UserActionTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                users: [...state.users, action.payload],
                successMessage:'Utilizador adicionado com sucesso'
            }
        case UserActionTypes.CREATE_USER_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                successMessage:'',
                errorMessage: 'Occorreu um erro ao adicionar utilizador! Tente novamente'
            }
        case UserActionTypes.EDIT_USER_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
            }
        case UserActionTypes.EDIT_USER_SUCCESS:
            const usersUpdate = state.users.map(user => {
                if(user.email === action.payload.email)
                    return action.payload
                else return user
            });
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                users: usersUpdate,
                successMessage:'Dados actualizados com sucesso'
            }
        case UserActionTypes.EDIT_USER_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                successMessage:'',
                errorMessage: 'Occorreu um erro ao editar os dados do utilizador! Tente novamente'
            }
        case UserActionTypes.REMOVE_USER_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage: '',
                successMessage: ''
            }
        case UserActionTypes.REMOVE_USER_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                users: state.users.filter(user => user.email !== action.payload.email),
                successMessage:'Utilizador removido com sucesso'
            }
        case UserActionTypes.REMOVE_USER_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                successMessage:'',
                errorMessage: 'Occorreu um erro ao remover utilizador! Tente novamente'
            }
        default:
            return state;
    }
}

export default userReducer;
