import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	CardHeader, Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { recoverAction } from "../../../store/actionCreators/login.actionCreator";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import {useSnackbar} from "notistack";

const Recover = () => {
	const [email, setEmail] = useState("");
	const { isSaving,success, successMessage,error, errorMessage,codeRecoverSent } = useTypeSelector(
		(state) => state.login
	);

	const dispatch = useDispatch();
	const history = useHistory();
	const {enqueueSnackbar} = useSnackbar();

	const sendCodeHandler = () => {
		dispatch(recoverAction(email));
	};

	useEffect(() => {
		if (codeRecoverSent){
			history.push("/auth/code");
		}
	},[codeRecoverSent, history])

	useEffect(() => {
		const message = 'Ocorreu um erro ao enviar ' +
			'o codigo de recuperacao. Tente novamente!';

		if(success){
			enqueueSnackbar(successMessage, {
				variant: 'success'
			})
		}

		if (error && errorMessage === message){
			enqueueSnackbar(errorMessage, {
				variant: 'error'
			})
		}
	}, [success, error, errorMessage,successMessage, enqueueSnackbar ]);

	return (
		<>
			<Card className="h3  border ">
				<CardHeader>
					<div className="text-center text-muted mt-2 mb-2">
						<b style={{fontSize:"20px"}}> Um codigo de Recuperação sera enviado para o e-mail</b>
					</div>
				</CardHeader>
				<CardBody className="px-lg-3 py-lg-4">
					<Form role="form">
						<FormGroup className="mb-3">
							<Input
								placeholder="Email"
								type="email"
								autoComplete="new-email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</FormGroup>

						<div>
							{
								isSaving ?
									<Button
										block
										className="mt-4 p-2 text-capitalize"
										color="success"
										type="button"
										style={{fontSize:"17px", fontWeight:"400"}}
									>
										<Spinner color="light">
											Loading...
										</Spinner>
									</Button>
									:
									<Button
										block
										className="mt-4 p-2 text-capitalize"
										size="lg"
										color="success"
										type="button"
										onClick={sendCodeHandler}
										style={{fontSize:"17px", fontWeight:"400"}}
									>
										Recuperar
									</Button>
							}

						</div>
					</Form>
				</CardBody>
			</Card>
		</>
	);
};

export default Recover;
