import {IDepartment} from "../../models/department";
import {DepartmentAction, DepartmentActionTypes} from "../actionTypes/department.actionTypes";

export interface IDepartmentState {
    departments: Array<IDepartment>,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: IDepartmentState = {
    departments: [],
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    errorMessage: '',
    successMessage: ''
}

const departmentReducer = (state: IDepartmentState = initialState, action: DepartmentAction) => {
    switch (action.type) {
        case DepartmentActionTypes.GET_DEPARTMENTS_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false,
                successMessage: '',
                errorMessage: ''
            }
        case DepartmentActionTypes.GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                error: false,
                success: false,
                departments: action.payload
            }
        case DepartmentActionTypes.GET_DEPARTMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                error: false,
                success: false,
                errorMessage: action.payload
            }
        case DepartmentActionTypes.ADD_DEPARTMENT_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case DepartmentActionTypes.ADD_DEPARTMENT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Departamento adicionado com successo!',
                departments: [...state.departments, action.payload]
            }
        case DepartmentActionTypes.ADD_DEPARTMENT_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao adicionar o departamento. Tente novamente!',
                successMessage: '',
            }

        case DepartmentActionTypes.EDIT_DEPARTMENT_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case DepartmentActionTypes.EDIT_DEPARTMENT_SUCCESS:
            const departmentUpdate = state.departments.map(department => {
                if(department.id === action.payload.id)
                    return action.payload
                else return department
            });
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Departamento actualizado com successo!',
                departments: departmentUpdate
            }
        case DepartmentActionTypes.EDIT_DEPARTMENT_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao actualizar o departamento. Tente novamente!',
                successMessage: '',
            }

        case DepartmentActionTypes.REMOVE_DEPARTMENT_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case DepartmentActionTypes.REMOVE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Departamento removido com successo!',
                departments: state.departments.filter(depart => depart.id !== action.payload.id)
            }
        case DepartmentActionTypes.REMOVE_DEPARTMENT_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao remover o departamento. Tente novamente!',
                successMessage: '',
            }

        default:
            return state;
    }
}

export default departmentReducer;
