import React, {useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Label,
} from "reactstrap";
import {ExtractRouteParams, RouteComponentProps} from "react-router";
import { useHistory } from "react-router";

import routes from "../../../routes";
import img from "../../../assets/img/default-avatar.png";
import {useDispatch} from "react-redux";
import {logoutAction} from "../../../store/actionCreators/login.actionCreator";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import auth from "../../../helpers/auth";

interface IHeaderProps {
  props: RouteComponentProps<ExtractRouteParams<string,string>>,
}

function Header({props} : IHeaderProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef<NavbarToggler & HTMLButtonElement>(null);
  const location = useLocation();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const getBrand = () => {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    if(sidebarToggle.current){
      sidebarToggle.current.classList.toggle("toggled");
    }
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind("transparent"));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      if(sidebarToggle.current){
        sidebarToggle.current.classList.toggle("toggled");
      }
    }
  }, [location]);
  ///////////////////////////////////////////////////////////////////////////////////////// end template code
  const [dropdownOpenUser, setDropdownOpenUser] = React.useState(false);
  
  const {userLogged} = useTypeSelector(
      (state) => state.user
  );

  const {login} = useTypeSelector(
      (state) => state.login
  );

  const {fullName, department} = userLogged;

  const dispatch = useDispatch();
  const history = useHistory();

  const dropdownToggleUser = () => {
    setDropdownOpenUser(!dropdownOpenUser);
  };

  const logoutHandler = () => {
    dispatch(logoutAction());
  }

  useEffect(() => {
    if(!auth.isAuthenticated()) {
      history.push("/auth/login")
    }
  },[login, history])

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "dark"
          : color
      }
      expand="lg"
      className={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="/">{getBrand()}</NavbarBrand>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={() => dropdownToggle()}
            >
              {/*<DropdownToggle caret nav>*/}
              {/*  <i className="nc-icon nc-bell-55" />*/}
              {/*  <Badge color="danger">*/}
              {/*    2*/}
              {/*  </Badge>*/}
              {/*  <p>*/}
              {/*    <span className="d-lg-none d-md-block">Some Actions</span>*/}
              {/*  </p>*/}
              {/*</DropdownToggle>*/}
              {/*<DropdownMenu right>*/}
              {/*  <DropdownItem tag="a">Notificacao 1</DropdownItem>*/}
              {/*  <DropdownItem tag="a">Notificacao 2</DropdownItem>*/}
              {/*</DropdownMenu>*/}
            </Dropdown>
            <Dropdown
                nav
                isOpen={dropdownOpenUser}
                toggle={() => dropdownToggleUser()}
            >
              <DropdownToggle nav>
                <span className="d-none d-md-inline">
                  <Label className="text-capitalize">
                  <span className="text-success mr-1 font-weight-bold text-capitalize">Departamento de {department}</span>
                  |
                  <span className="text-dark ml-1 text-capitalize">{fullName}</span>
                </Label>
                <img
                    src={img}
                    alt=""
                    className="rounded-circle img-fluid ml-2 tap-zoom-profile-pic"
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundSize: "cover",
                    }}
                />
                </span>

                <p className="d-lg-none d-md-block">
                  <Label className="text-capitalize">
                    {fullName}
                  </Label>
                  <img
                      src={img}
                      alt=""
                      className="rounded-circle img-fluid ml-2 tap-zoom-profile-pic"
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundSize: "cover",
                      }}
                  />
                </p>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={Label} header>Administrador</DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag={Link} to="/admin/user-page">Meu Perfil</DropdownItem>
                <DropdownItem tag={Label} onClick={()=> logoutHandler()}>Sair</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
