import {Dispatch} from "redux";
import {FaqAction, FaqActionTypes} from "../actionTypes/faq.actionTypes";
import {Api} from "../../services/api";
import {IFaq} from "../../models/faq";

export const getFaqs = () =>
    async (dispatch: Dispatch<FaqAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: FaqActionTypes.GET_FAQS_LOADING
            });

            await api.get<IFaq[]>("/faq")
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: FaqActionTypes.GET_FAQS_SUCCESS,
                            payload: result.data
                        })
                    }
                }).catch( e => {
                    dispatch({
                        type: FaqActionTypes.GET_FAQS_FAIL,
                        payload: e
                    })
                })
        }catch (e: any) {
            dispatch({
                type: FaqActionTypes.GET_FAQS_FAIL,
                payload: e
            })
        }
}

export const addFaq = (description: string, files: any) =>
    async (dispatch: Dispatch<FaqAction>) => {
        let api = new Api();

        let body = new FormData();
        body.append("files", files);
        body.append("command", JSON.stringify({description: description}))

        try {
            dispatch({
                type: FaqActionTypes.ADD_FAQ_SAVING
            })

            await api.postUpload<IFaq>("/faq", body)
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: FaqActionTypes.ADD_FAQ_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: FaqActionTypes.ADD_FAQ_FAIL,
                        payload: e
                    });
                })

        }catch (e: any) {
            dispatch({
                type: FaqActionTypes.ADD_FAQ_FAIL,
                payload: e
            });
        }
    
}

export const deleteFaq = (id: number) =>
    async (dispatch: Dispatch<FaqAction>) => {
        let api = new Api();
        
        try {
            dispatch({
                type: FaqActionTypes.REMOVE_FAQ_SAVING
            });

            await api.delete<IFaq>(`/faq/${id}`)
                .then(result => {
                    if (result.status === 200){
                        dispatch({
                            type: FaqActionTypes.REMOVE_FAQ_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: FaqActionTypes.REMOVE_FAQ_FAIL,
                        payload: e
                    })
                })
        }catch (e: any) {
            dispatch({
                type: FaqActionTypes.REMOVE_FAQ_FAIL,
                payload: e
            })
        }
}
