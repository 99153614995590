import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form, FormGroup,
    Input,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import MaterialTable from "material-table";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {useDispatch} from "react-redux";
import {
    addDepartment,
    deleteDepartment,
    editDepartment,
    getDepartments
} from "../../../store/actionCreators/department.actionCreator";
import {useSnackbar} from "notistack";
import {IDepartment} from "../../../models/department";

const SettingsDepartment = () => {
    const [modal,setModal] = useState<boolean>(false);
    const [modalRemove,setModalRemove] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>();
    const [department, setDepartment] = useState<string>('');
    const [allDepartments, setAllDepartments] = useState<Array<IDepartment>>([]);
    const [departmentId, setDepartmentId] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar()
    const dispatch = useDispatch();

    const {departments, isLoading, isSaving, success, error, successMessage, errorMessage} = useTypeSelector(
        (state) => state.department
    )
    const {userLogged} = useTypeSelector(
        (state) => state.user
    );
    const {roles} = userLogged;
    const role = roles[0];

    useEffect(() => {
        dispatch(getDepartments())
    },[dispatch])

    const clear = () => {
        setDepartment('');
        setDepartmentId('');
    }

    const toggle = () => {
        clear();
        setModal(!modal)
        setEdit(false)
    }

    const toggleRemove = () => {
        setModalRemove(!modalRemove);
        setDepartmentId('');
    }

    const editDepartmentData = (id: string) => {
        allDepartments.forEach((depart) => {
            if (depart.id === parseInt(id)) {
                setDepartment(depart.description)
                setDepartmentId(id);
            }
        })
        setModal(true);
        setEdit(true);
    }

    useEffect(() => {
        if (departments){
            setAllDepartments(departments);
        }
    },[departments])

    useEffect(() => {
        if(success){
            enqueueSnackbar(successMessage, {
                variant: 'success'
            })
        }

        if (error){
            enqueueSnackbar(errorMessage, {
                variant: 'error'
            })
        }

    },[success, error, successMessage, errorMessage, enqueueSnackbar])

    useEffect(() => {
        if(success && !edit){
            clear();
        }
    },[success, edit])

    const departmentHandler = () => {
        if (edit){
            dispatch(editDepartment(parseInt(departmentId),department))
        }else {
            dispatch(addDepartment(department))
        }
    }

    const removeDepartment = (id: string) => {
        setDepartmentId(id);
        setModalRemove(true);
    }

    const removeHandler = () => {
        dispatch(deleteDepartment(parseInt(departmentId)));
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <CardTitle tag="h4" className="col-md-9">Lista de Departamentos</CardTitle>
                                    <Button disabled={role !== "SystemAdministrator"} className="ml-auto mr-3" onClick={toggle}>
                                        <i className='fas fa-solid fa-plus mr-2'/>  Adicionar novo
                                    </Button>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    isLoading={isLoading}
                                    title="Lista de Departamentos"
                                    columns={[
                                        {title: 'Id', field: 'id'},
                                        {title: 'Descrição', field: 'description'}
                                    ]}
                                    data={allDepartments}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Adicionar Departamento',
                                            isFreeAction: true,
                                            disabled: role !== "SystemAdministrator",
                                            onClick: () => toggle()
                                        },
                                        {
                                            icon: 'edit',
                                            tooltip: 'Editar',
                                            disabled: role !== "SystemAdministrator",
                                            onClick: (event, rowData: any) => editDepartmentData(rowData.id)
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            disabled: role !== "SystemAdministrator",
                                            onClick: (event, rowData:any) => removeDepartment(rowData.id)
                                        }
                                    ]}
                                    options={{
                                        exportButton: true,
                                        actionsColumnIndex: -1,
                                        loadingType: "overlay",
                                        showTitle: false,
                                        headerStyle: {fontWeight: 'bold'}
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                >
                    {edit ?
                        <>Editar descricao do departamento</>
                        :
                        <>Adicior Departamento</>
                    }
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col className="col-md-8">
                                <FormGroup>
                                    <label>Nome do Departamento</label>
                                    <Input
                                        placeholder="Nome do departamento"
                                        type="text"
                                        value={department}
                                        onChange={(e) => {setDepartment(e.target.value);}}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                disabled={!department }
                                onClick={departmentHandler}
                            >
                                {edit ?
                                    <>Editar</>
                                    :
                                    <>Adicionar</>
                                }
                            </Button>
                    }

                    <Button onClick={toggle} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>

            {/*Modal for remove user*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalRemove}
                toggle={toggleRemove}
            >
                <ModalHeader
                    toggle={toggleRemove}
                >
                    Remover departamento
                </ModalHeader>
                <ModalBody>
                    <p>Deseja remover departamento ?</p>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={removeHandler}
                            >
                                Remover
                            </Button>
                    }

                    <Button onClick={toggleRemove} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default SettingsDepartment;
