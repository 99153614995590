import React, {useEffect, useState} from "react";
import {Row} from "reactstrap";
import SendMessageComponent from "./SendMessageComponent";
import ViewMessagesComponent from "./ViewMessagesComponent";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {IIncidenceMessage} from "../../models/incidenceMessage";
import {useDispatch} from "react-redux";
import {getIncidenceMessage} from "../../store/actionCreators/incidenceMessage.actionCreator";
import {useTypeSelector} from "../../hooks/useTypeSelector";

interface IChatSupport {
    incidenceId: string,
    incidenceStatus?: string
}

const ChatSupport = ({incidenceId, incidenceStatus}: IChatSupport) => {
    const [connection, setConnection] = useState<HubConnection>();
    const [messages, setMessages] = useState<Array<IIncidenceMessage>>([]);
    const dispatch = useDispatch();

    const {incidenceMessages} = useTypeSelector(
        (state) => state.incidenceMessage
    )

    useEffect(() => {
        if (!isNaN(parseInt(incidenceId))){
            dispatch(getIncidenceMessage(incidenceId))
        }
    },[incidenceId, dispatch])

    useEffect(() => {
        if (incidenceMessages) {
            setMessages(incidenceMessages)
        }
    },[incidenceMessages])


    useEffect(() => {
        if (!isNaN(parseInt(incidenceId)) && incidenceStatus === "Em andamento"){
            joinRoom(incidenceId)
        }
    },[incidenceId, incidenceStatus])

    const joinRoom = async (room: string) => {

        try {
            const token = localStorage.getItem('token') || '';

            const connection = new HubConnectionBuilder()
                .withUrl(`http://localhost:5000/api/chat`, {
                    accessTokenFactory: () => token || '',
                    withCredentials: false
                })
                .configureLogging(LogLevel.Information)
                .build();

            connection.on("ReceiveMessage", (message) => {
                setMessages(messages => [...messages, message]);
            });

            connection.onclose( e => {
                setConnection(undefined);
                setMessages([]);
            })

            await connection.start();
            await connection.invoke("JoinRoom", {room});
            setConnection(connection);

        }catch (e){
            console.log("Error", e)
        }
    }

    const sendMessage = async (message: string) => {

        try {
            await connection?.invoke("SendMessage", message);
        }catch (e) {
            console.log(e)
        }
    }

    // const closeConnection = async () => {
    //     try {
    //         await connection?.stop();
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }


    return (
        <>
            <div className="content border rounded col-md-10" style={{backgroundColor: '#D4D4D463'}} >
                <Row>
                    {
                        messages.length > 0 &&
                            <ViewMessagesComponent messages={messages}/>
                    }
                    {
                        incidenceStatus === "Em andamento" &&
                            <SendMessageComponent sendMessage={sendMessage}/>
                    }

                </Row>
            </div>
        </>
    )
}

export default ChatSupport;

