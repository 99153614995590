import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import MaterialTable from "material-table";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {getIncidenceType} from "../../../store/actionCreators/incidenceType.actionCreator";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {IIncidence} from "../../../models/incidence";
import {IUser} from "../../../models/user";
import {getUser} from "../../../store/actionCreators/user.actionCreator";
import {getTechnicianWithIncidentNumbers} from "../../../store/actionCreators/incidence.actionCreator";

export interface ISupport {
    id: number,
    title: string
    type: string,
    department: string,
    author: string,
    priority: string,
    status: string,
    date: string,
    technician: string
}

const ListSupport = () => {
    const dispatch = useDispatch();
    const [user,setUser] = useState<IUser>();
    const [allIncidences, setAllIncidences] = useState<IIncidence[]>([]);
    const [data, setData] = useState<Array<ISupport>>([])
    const history = useHistory();

    const {isLoading, incidences} = useTypeSelector(
        (state) => state.incidence
    )

    const {userLogged} = useTypeSelector(
        (state) => state.user
    )

    const submit = () => {
        history.push("/admin/support/chatbot")
    }

    const convertDate = (dateToConvert: Date) => {
        const date = new Date(dateToConvert);

        const viewDate = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        const viewMonth = date.getMonth() + 1;

        const viewDateHour = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
        const viewDateMinute = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`

        return viewDate + '/' + viewMonth + '/' + date.getFullYear() + ' as '+ viewDateHour +':'+ viewDateMinute;
    }

    const columnsTable = () => {
        if (user?.roles[0] !== "Collaborator"){
            return [
                {title: 'Titulo', field: 'title'},
                {title: 'Autor', field: 'author'},
                {title: 'Prioridade', field: 'priority'},
                {title: 'Estado', field: 'status'},
                {title: 'Data', field: 'date'},
                {title: 'Tecnico', field: 'technician'}
            ]
        }else{
            return [
                {title: 'Titulo', field: 'title'},
                {title: 'Prioridade', field: 'priority'},
                {title: 'Estado', field: 'status'},
                {title: 'Data', field: 'date'}
            ]
        }
    }

    useEffect(() => {
        const incidencesData : Array<ISupport> = [];

        if (incidences){
            setAllIncidences(incidences)
        }

        if (allIncidences.length > 0){
            allIncidences.forEach(function (incidence) {
                incidencesData.push({
                    id: incidence.id,
                    title: incidence.title,
                    type: incidence.incidenceType,
                    department: incidence?.collaborator?.department,
                    author: incidence.collaborator.fullName,
                    priority: incidence.incidencePriority,
                    status: incidence.incidenceStatus,
                    date: convertDate(incidence.date),
                    technician: incidence?.technician?.fullName
                })
            })

            setData(incidencesData);
        }

    },[incidences, allIncidences])

    useEffect(() => {
        dispatch(getUser())
        dispatch(getTechnicianWithIncidentNumbers())
    },[incidences,allIncidences, dispatch])


    useEffect(() => {
        if (userLogged){
            setUser(userLogged)
        }
    },[userLogged])

    const viewDetails = (id: number) => {
        history.push(`/admin/support/details/0${id}`)
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <CardTitle tag="h4" className="col-md-9">Lista de Incidecias</CardTitle>
                                    <Button className="ml-auto mr-3" onClick={submit} >
                                        <i className='fas fa-solid fa-plus mr-2'/>  Solicitar suporte
                                    </Button>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    isLoading={isLoading}
                                    title="Lista de Incidecias"
                                    columns={columnsTable()}
                                    data={data}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Solicitar suporte',
                                            isFreeAction: true,
                                            onClick: () => {submit()}
                                        },
                                        {
                                            icon: 'edit',
                                            tooltip: 'Editar',
                                            onClick: (event, rowData: any) => viewDetails(rowData.id)
                                        },
                                        {
                                            icon: 'engineering',
                                            tooltip: 'Cancelar',
                                            onClick: (event, rowData: any) => viewDetails(rowData.id)
                                        },
                                        {
                                            icon: 'cancel',
                                            tooltip: 'Cancelar',
                                            onClick: (event, rowData: any) => viewDetails(rowData.id)
                                        }
                                    ]}
                                    options={{
                                        exportButton: true,
                                        actionsColumnIndex: -1,
                                        loadingType: "overlay",
                                        showTitle: false,
                                        headerStyle: {fontWeight: 'bold'}
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ListSupport;
