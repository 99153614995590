
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";

import DemoNavbar from "../components/admin/Navbars/DemoNavbar";
import Footer from "../components/admin/Footer/Footer";
import Sidebar from "../components/admin/Sidebar/Sidebar";
import {ExtractRouteParams, RouteComponentProps, useHistory} from "react-router";
import routes from "../routes";

var ps: PerfectScrollbar;

interface IDashboardProps {
  props: RouteComponentProps<ExtractRouteParams<string,string>>,
}

function Admin({props} : IDashboardProps) {
  const mainPanel = React.useRef<PerfectScrollbar & HTMLDivElement>(null);
  const location = useLocation();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1 && mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (mainPanel.current){
      mainPanel.current.scrollTop = 0;
    }
    if(document.scrollingElement){
      document.scrollingElement.scrollTop = 0;
    }
  }, [location]);
  ////////////////////////////////////////////////////////////////////////////////
  const history = useHistory();

  React.useEffect(()=> {
    // reset basic parameters on reload
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {

      //redirect to dashboard and reload data to redux state
      history.push("/admin/dashboard");

      //add progress bar
      // loadProgressBar({ showSpinner: false }, api);
    }
  }, [history]);

  return (
    <div className="wrapper">
      <Sidebar
        props={props}
        routes={routes}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar props={props} />
        <Switch>
          {routes.map((prop, key) => {
            if(prop.layout === "/admin"){
              return (
                  <Route
                      path={prop.layout + prop.path}
                      component={prop.component}
                      key={key}
                  />
              );
            }else {
              return null
            }
          })}
        </Switch>
        <Footer />
      </div>
    </div>
  );
}

export default Admin;
