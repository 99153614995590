
import React, {useEffect, useState} from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col, Button,
} from "reactstrap";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {IUser} from "../../../models/user";

const Profile = () => {
  const [user, setUser] = useState<IUser>();
  const {userLogged} = useTypeSelector(
      (state) => state.user
  )

  useEffect(() => {
    if (userLogged){
      setUser(userLogged)
    }
  },[userLogged])

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Dados do Perfil</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Nome Completo</label>
                        <Input
                          disabled
                          defaultValue={user?.fullName}
                          placeholder="Nome Completo"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-5" md="5">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                            disabled
                            defaultValue={user?.email}
                            placeholder="Email"
                            type="email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Departamento</label>
                        <Input
                            disabled
                            defaultValue={user?.department}
                            placeholder="Departamento"
                            type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-5" md="5">
                      <FormGroup>
                        <label>Função</label>
                        <Input
                            disabled
                            defaultValue={user?.roles[0]}
                            placeholder="função"
                            type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>

                <Row>
                  <div className="ml-3">
                    <Button
                    >
                      Alterar Senha
                    </Button>
                  </div>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Profile;
