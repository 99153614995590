import Login from "./views/auth/Login";
import Recover from "./views/auth/Recover/Recover";
import UserPage from "./views/admin/Profile/Profile";
import Dashboard from "./views/admin/Dashboard/Dashboard";
import Settings from "./views/admin/Settings/Settings";
import Users from "./views/admin/Users/Users";
import Support from "./views/admin/Support/Support";
import FAQ from "./views/admin/FAQ/FAQ";
import ConfirmCode from "./views/auth/Recover/ConfirmCode";
import NewPassword from "./views/auth/Recover/NewPassword";
import RequestSupport from "./views/admin/Support/RequestSupport";
import DetailsSupport from "./views/admin/Support/DetailsSupport";
import SchedulerSupport from "./views/admin/Support/SchedulerSupport";
import ChatbotSupport from "./views/admin/Support/ChatbotSupport";
import Reports from "./views/admin/Reports/Reports";

const routes = [
	// auth routes
	{
		path: "/login",
		name: "Login",
		icon: "nc-icon nc-bank",
		component: Login,
		layout: "/auth",
		invisible: true,
	},
	{
		path: "/recover",
		name: "Recover",
		icon: "nc-icon nc-bank",
		component: Recover,
		layout: "/auth",
		invisible: true,
	},
	{
		path: "/code",
		name: "Code Recover",
		icon: "nc-icon nc-bank",
		component: ConfirmCode,
		layout: "/auth",
		invisible: true,
	},
	{
		path: "/newpassowrd",
		name: "New Password",
		icon: "nc-icon nc-bank",
		component: NewPassword,
		layout: "/auth",
		invisible: true,
	},

	// Admin routes
	{
		path: "/dashboard",
		name: "Página Inicial ",
		icon: "fas fa-home",
		component: Dashboard,
		layout: "/admin",
		invisible: false,
	},
	{
		path: "/user-page",
		name: "Meu Perfil",
		icon: "fas fa-user",
		component: UserPage,
		layout: "/admin",
		invisible: false,
	},
	{
		path: "/users",
		name: "Utilizadores",
		icon: "fa fa-ligth fa-users",
		component: Users,
		layout: "/admin",
		invisible: false,
	},
	{
		path: "/support",
		name: "Suporte",
		icon: "fas fa-solid fa-headset",
		component: Support,
		layout: "/admin",
		invisible: false,
	},
	{
		path: "/chatbot",
		name: "Requisição de Suporte",
		icon: "fas fa-solid fa-headset",
		component: ChatbotSupport,
		layout: "/admin/support",
		invisible: false,
	},
	{
		path: "/request",
		name: "Requisição de Suporte",
		icon: "fas fa-solid fa-headset",
		component: RequestSupport,
		layout: "/admin/support",
		invisible: false,
	},
	{
		path: "/details/:id",
		name: "Detalhes da Solicitação",
		icon: "fas fa-solid fa-headset",
		component: DetailsSupport,
		layout: "/admin/support",
		invisible: false,
	},
	{
		path: "/scheduler",
		name: "Agenda",
		icon: "far fa-calendar-alt",
		component: SchedulerSupport,
		layout: "/admin",
		invisible: false,
	},
	{
		path: "/reports",
		name: "Relatórios",
		icon: "nc-icon nc-single-copy-04",
		component: Reports,
		layout: "/admin",
		invisible: false,
	},
	{
		path: "/settings",
		name: "Configurações",
		icon: "fas fa-cog",
		component: Settings,
		layout: "/admin",
		invisible: false,
	},
	{
		path: "/faq",
		name: "Perguntas frequentes ",
		icon: "fas fa-question-circle",
		component: FAQ,
		layout: "/admin",
		invisible: false,
	}
];
export default routes;
