import {IError} from "../../models/error";
import {IRating} from "../../models/rating";

export enum RatingActionTypes {
    GET_RATINGS_LOADING = "GET_RATINGS_LOADING",
    GET_RATINGS_SUCCESS = "GET_RATINGS_SUCCESS",
    GET_RATINGS_FAIL = "GET_RATINGS_FAIL",
}

interface IGetRatingsLoading {
    type: RatingActionTypes.GET_RATINGS_LOADING
}

interface IGetRatingsSuccess {
    type: RatingActionTypes.GET_RATINGS_SUCCESS,
    payload: Array<IRating>
}

interface IGetRatingsFail {
    type: RatingActionTypes.GET_RATINGS_FAIL,
    payload: IError
}

export type RatingAction =
    IGetRatingsLoading | IGetRatingsSuccess | IGetRatingsFail;
