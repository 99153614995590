import {IIncidenceType} from "../../models/incidenceType";
import {IError} from "../../models/error";

export enum IncidenceTypeActionType {
    GET_INCIDENCE_TYPES_LOADING = "GET_INCIDENCE_TYPES_LOADING",
    GET_INCIDENCE_TYPES_SUCCESS = "GET_INCIDENCE_TYPES_SUCCESS",
    GET_INCIDENCE_TYPES_FAIL = "GET_INCIDENCE_TYPES_FAIL",
    ADD_INCIDENCE_TYPE_SAVING = "ADD_INCIDENCE_TYPE_SAVING",
    ADD_INCIDENCE_TYPE_SUCCESS = "ADD_INCIDENCE_TYPE_SUCCESS",
    ADD_INCIDENCE_TYPE_FAIL = "ADD_INCIDENCE_TYPE_FAIL",
    EDIT_INCIDENCE_TYPE_SAVING = "EDIT_INCIDENCE_TYPE_SAVING",
    EDIT_INCIDENCE_TYPE_SUCCESS = "EDIT_INCIDENCE_TYPE_SUCCESS",
    EDIT_INCIDENCE_TYPE_FAIL = "EDIT_INCIDENCE_TYPE_FAIL",
    REMOVE_INCIDENCE_TYPE_SAVING = "REMOVE_INCIDENCE_TYPE_SAVING",
    REMOVE_INCIDENCE_TYPE_SUCCESS = "REMOVE_INCIDENCE_TYPE_SUCCESS",
    REMOVE_INCIDENCE_TYPE_FAIL = "REMOVE_INCIDENCE_TYPE_FAIL",
}

interface IGetIncidenceTypesLoading {
    type: IncidenceTypeActionType.GET_INCIDENCE_TYPES_LOADING
}

interface IGetIncidenceTypesSuccess {
    type: IncidenceTypeActionType.GET_INCIDENCE_TYPES_SUCCESS,
    payload: Array<IIncidenceType>
}

interface IGetIncidenceTypesFail {
    type: IncidenceTypeActionType.GET_INCIDENCE_TYPES_FAIL,
    payload: IError
}

interface IAddIncidenceTypeSaving {
    type: IncidenceTypeActionType.ADD_INCIDENCE_TYPE_SAVING
}

interface IAddIncidenceTypeSuccess {
    type: IncidenceTypeActionType.ADD_INCIDENCE_TYPE_SUCCESS,
    payload: IIncidenceType
}

interface IAddIncidenceTypeFail {
    type: IncidenceTypeActionType.ADD_INCIDENCE_TYPE_FAIL,
    payload: IError
}

interface IEditIncidenceTypeSaving {
    type: IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_SAVING
}

interface IEditIncidenceTypeSuccess {
    type: IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_SUCCESS,
    payload: IIncidenceType
}

interface IEditIncidenceTypeFail {
    type: IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_FAIL,
    payload: IError
}

interface IRemoveIncidenceTypeSaving {
    type: IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_SAVING
}

interface IRemoveIncidenceTypeSuccess {
    type: IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_SUCCESS,
    payload: IIncidenceType
}

interface IRemoveIncidenceTypeFail {
    type: IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_FAIL,
    payload: IError
}

export type IncidenceTypeAction =
    IGetIncidenceTypesLoading | IGetIncidenceTypesSuccess | IGetIncidenceTypesFail |
    IAddIncidenceTypeSaving | IAddIncidenceTypeSuccess | IAddIncidenceTypeFail |
    IEditIncidenceTypeSaving | IEditIncidenceTypeSuccess | IEditIncidenceTypeFail |
    IRemoveIncidenceTypeSaving | IRemoveIncidenceTypeSuccess | IRemoveIncidenceTypeFail;

