import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    CardHeader, Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import {recoverConfirmCodeAction} from "../../../store/actionCreators/login.actionCreator";
import {useSnackbar} from "notistack";

const ConfirmCode = () => {
    const [code, setCode] = useState<string>("");
    const { isSaving, error, success, successMessage,
        emailRecover, codeRecoverConfirm, codeRecoverSent, errorMessage } = useTypeSelector(
        (state) => state.login
    );

    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const confirmCodeHandler = () => {
        dispatch(recoverConfirmCodeAction(emailRecover, code));
    };

    useEffect(() => {
        if (codeRecoverSent && codeRecoverConfirm){
            history.push("/auth/newpassowrd");
        }
    }, [history, codeRecoverSent, codeRecoverConfirm])

    useEffect(() => {
        const message = "Occoreu um erro na " +
            "confirmacao do codigo. Tente novamente!";

        if(success){
            enqueueSnackbar(successMessage, {
                variant: 'success'
            })
        }

        if (error && errorMessage === message){
            enqueueSnackbar(errorMessage, {
                variant: 'error'
            })
        }
    }, [success, error, errorMessage,successMessage, enqueueSnackbar ]);

    return (
        <>
            <Card className="h3  border ">
                <CardHeader>
                    <div className="text-center text-muted mt-2 mb-2">
                        <b style={{fontSize:"20px"}}>O Codigo de Recuperação foi enviado para o seu e-mail</b>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-3 py-lg-4">
                    <Form role="form">
                        <FormGroup className="mb-3">
                            <Input
                                placeholder="Digite o codigo de Recuperação"
                                type="number"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </FormGroup>

                        <div>
                            {
                                isSaving ?
                                    <Button
                                        block
                                        className="mt-4 p-2 text-capitalize"
                                        color="success"
                                        type="button"
                                        style={{fontSize:"17px", fontWeight:"400"}}
                                    >
                                        <Spinner color="light">
                                            Loading...
                                        </Spinner>
                                    </Button>
                                    :
                                    <Button
                                        block
                                        className="mt-4 p-2 text-capitalize"
                                        size="lg"
                                        color="success"
                                        type="button"
                                        onClick={confirmCodeHandler}
                                        style={{fontSize:"17px", fontWeight:"400"}}
                                    >
                                        Confirmar
                                    </Button>
                            }

                        </div>
                    </Form>
                </CardBody>
            </Card>
        </>
    );
};

export default ConfirmCode;
