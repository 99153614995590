import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    CardHeader, Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import auth from "../../../helpers/auth";
import {recoverNewPasswordAction} from "../../../store/actionCreators/login.actionCreator";
import {useSnackbar} from "notistack";

const NewPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { login, error, success, successMessage, emailRecover, isSaving, errorMessage } = useTypeSelector(
        (state) => state.login
    );

    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const newPasswordHandler = () => {
        if (password === confirmPassword){
            dispatch(recoverNewPasswordAction(emailRecover, password));
        }
    };

    useEffect(() => {
        if(auth.isAuthenticated()){
            history.push("/admin/dashboard")
        }
    },[history,login])

    useEffect(() => {
        if(success){
            enqueueSnackbar(successMessage, {
                variant: 'success'
            })
        }

        if (error){
            enqueueSnackbar(errorMessage, {
                variant: 'error'
            })
        }
    }, [success, error, errorMessage,successMessage, enqueueSnackbar ]);

    return (
        <>
            <Card className="h3  border ">
                <CardHeader>
                    <div className="text-center text-muted mt-2 mb-2">
                        <b style={{fontSize:"20px"}}> Nova senha</b>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-3 py-lg-4">
                    <Form role="form">
                        <FormGroup className="mb-3">
                            <Input
                                placeholder="Senha"
                                type="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Input
                                placeholder="Confirmar Senha"
                                type="password"
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </FormGroup>

                        <div>
                            {
                                isSaving ?
                                    <Button
                                        block
                                        className="mt-4 p-2 text-capitalize"
                                        color="success"
                                        type="button"
                                        style={{fontSize:"17px", fontWeight:"400"}}
                                    >
                                        <Spinner color="light">
                                            Loading...
                                        </Spinner>
                                    </Button>
                                    :
                                    <Button
                                        block
                                        className="mt-4 p-2 text-capitalize"
                                        size="lg"
                                        color="success"
                                        type="button"
                                        onClick={newPasswordHandler}
                                        style={{fontSize:"17px", fontWeight:"400"}}
                                    >
                                        Send
                                    </Button>
                            }
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </>

    );
};

export default NewPassword;
