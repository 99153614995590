import {IError} from "../../models/error";
import {IIncidenceMessage} from "../../models/incidenceMessage";

export enum IncidenceMessageActionType {
    GET_INCIDENCE_MESSAGE_LOADING = "GET_INCIDENCE_MESSAGE_LOADING",
    GET_INCIDENCE_MESSAGE_SUCCESS = "GET_INCIDENCE_MESSAGE_SUCCESS",
    GET_INCIDENCE_MESSAGE_FAIL = "GET_INCIDENCE_MESSAGE_FAIL",
}

interface IGetIncidenceMessageLoading {
    type: IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_LOADING
}

interface IGetIncidenceMessageSuccess {
    type: IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_SUCCESS
    payload: Array<IIncidenceMessage>
}

interface IGetIncidenceMessageFail {
    type: IncidenceMessageActionType.GET_INCIDENCE_MESSAGE_FAIL,
    payload: IError
}

export type IncidenceMessageAction =
    IGetIncidenceMessageLoading | IGetIncidenceMessageSuccess | IGetIncidenceMessageFail;
