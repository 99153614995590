import {IFaq} from "../../models/faq";
import {FaqAction, FaqActionTypes} from "../actionTypes/faq.actionTypes";

export interface IFaqState {
    faqs: Array<IFaq>,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: IFaqState = {
    faqs: [],
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: ''
}

const faqReducer = (state: IFaqState = initialState, action: FaqAction) => {
    switch (action.type) {
        case FaqActionTypes.GET_FAQS_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false,
                successMessage: '',
                errorMessage: ''
            }
        case FaqActionTypes.GET_FAQS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                error: false,
                success: false,
                faqs: action.payload
            }
        case FaqActionTypes.GET_FAQS_FAIL:
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                error: false,
                success: false,
                errorMessage: action.payload
            }

        case FaqActionTypes.ADD_FAQ_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case FaqActionTypes.ADD_FAQ_SUCCESS:
            return {
                ...state,
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Resposta de perguntas frequentes adicionada com successo!',
                faqs: [...state.faqs, action.payload]
            }
        case FaqActionTypes.ADD_FAQ_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao adicionar a resposta de perguntas frequentes. Tente novamente!',
                successMessage: '',
            }

        case FaqActionTypes.REMOVE_FAQ_SAVING:
            return {
                ...state,
                isSaving: true,
                error: false,
                success: false,
                errorMessage:'',
                successMessage: ''
            }
        case FaqActionTypes.REMOVE_FAQ_SUCCESS:
            return {
                isSaving: false,
                error: false,
                success: true,
                errorMessage:'',
                successMessage: 'Resposta de perguntas frequentes removida com successo!',
                faqs: state.faqs.filter(faq => faq.id !== action.payload.id)
            }
        case FaqActionTypes.REMOVE_FAQ_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                success: false,
                errorMessage:'Occorreu um erro ao remover a resposta de perguntas frequentes. Tente novamente!',
                successMessage: '',
            }

        default:
            return state;

    }
}

export default faqReducer;

