import {IIncidence} from "../../models/incidence";
import {IError} from "../../models/error";
import {IStatistic} from "../../models/statistic";
import {IIncidenceRatingStatistic} from "../../models/incidenceRatingStatistic";
import {ITechnicianWithIncidentNumbers} from "../../models/TechnicianWithIncidentNumbers";

export enum IncidenceActionType {
    GET_INCIDENCES_LOADING = "GET_INCIDENCES_LOADING",
    GET_INCIDENCES_SUCCESS = "GET_INCIDENCES_SUCCESS",
    GET_INCIDENCES_FAIL = "GET_INCIDENCES_FAIL",
    GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_LOADING = "GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_LOADING",
    GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_SUCCESS = "GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_SUCCESS",
    GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_FAIL = "GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_FAIL",
    ALLOCATE_TECHNICIAN_TO_INCIDENCE_SAVING = "ALLOCATE_TECHNICIAN_TO_INCIDENCE_SAVING",
    ALLOCATE_TECHNICIAN_TO_INCIDENCE_SUCCESS = "ALLOCATE_TECHNICIAN_TO_INCIDENCE_SUCCESS",
    ALLOCATE_TECHNICIAN_TO_INCIDENCE_FAIL = "ALLOCATE_TECHNICIAN_TO_INCIDENCE_FAIL",
    CHANGE_PRIORITY_SUPPORT_SAVING = "CHANGE_PRIORITY_SUPPORT_SAVING",
    CHANGE_PRIORITY_SUPPORT_SUCCESS = "CHANGE_PRIORITY_SUPPORT_SUCCESS",
    CHANGE_PRIORITY_SUPPORT_FAIL = "CHANGE_PRIORITY_SUPPORT_FAIL",
    REQUEST_SUPPORT_SAVING= "REQUEST_SUPPORT_SAVING",
    REQUEST_SUPPORT_SUCCESS = "REQUEST_SUPPORT_SUCCESS",
    REQUEST_SUPPORT_FAIL = "REQUEST_SUPPORT_FAIL",
    CANCEL_INCIDENCE_SAVING = "CANCEL_INCIDENCE_SAVING",
    CANCEL_INCIDENCE_SUCCESS = "CANCEL_INCIDENCE_SUCCESS",
    CANCEL_INCIDENCE_FAIL = "CANCEL_INCIDENCE_FAIL",
    START_SUPPORT_SAVING = "START_SUPPORT_SAVING",
    START_SUPPORT_SUCCESS = "START_SUPPORT_SUCCESS",
    START_SUPPORT_FAIL = "START_SUPPORT_FAIL",
    FINISH_SUPPORT_SAVING = "FINISH_SUPPORT_SAVING",
    FINISH_SUPPORT_SUCCESS = "FINISH_SUPPORT_SUCCESS",
    FINISH_SUPPORT_FAIL = "FINISH_SUPPORT_FAIL",
    SHEDULE_SUPPORT_SAVING = "SHEDULE_SUPPORT_SAVING",
    SHEDULE_SUPPORT_SUCCESS = "SHEDULE_SUPPORT_SUCCESS",
    SHEDULE_SUPPORT_FAIL = "SHEDULE_SUPPORT_FAIL",
    RESTART_SUPPORT_SAVING = "RESTART_SUPPORT_SAVING",
    RESTART_SUPPORT_SUCCESS = "RESTART_SUPPORT_SUCCESS",
    RESTART_SUPPORT_FAIL = "RESTART_SUPPORT_FAIL",
    GET_INCIDENCES_STATISTIC_WEEK_LOADING = "GET_INCIDENCES_STATISTIC_WEEK_LOADING",
    GET_INCIDENCES_STATISTIC_WEEK_SUCCESS = "GET_INCIDENCES_STATISTIC_WEEK_SUCCESS",
    GET_INCIDENCES_STATISTIC_WEEK_FAIL = "GET_INCIDENCES_STATISTIC_WEEK_FAIL",
    GET_INCIDENCES_STATISTIC_YEAR_LOADING = "GET_INCIDENCES_STATISTIC_YEAR_LOADING",
    GET_INCIDENCES_STATISTIC_YEAR_SUCCESS = "GET_INCIDENCES_STATISTIC_YEAR_SUCCESS",
    GET_INCIDENCES_STATISTIC_YEAR_FAIL = "GET_INCIDENCES_STATISTIC_YEAR_FAIL",
    ADD_INCIDENCE_RATING_SAVING = "ADD_INCIDENCE_RATING_SAVING",
    ADD_INCIDENCE_RATING_SUCCESS = "ADD_INCIDENCE_RATING_SUCCESS",
    ADD_INCIDENCE_RATING_FAIL = "ADD_INCIDENCE_RATING_FAIL",
    GET_INCIDENCES_RATING_STATISTIC_LOADING = "GET_INCIDENCES_RATING_STATISTIC_LOADING",
    GET_INCIDENCES_RATING_STATISTIC_SUCCESS = "GET_INCIDENCES_RATING_STATISTIC_SUCCESS",
    GET_INCIDENCES_RATING_STATISTIC_FAIL = "GET_INCIDENCES_RATING_STATISTIC_FAIL",
}

interface IGetIncidencesLoading {
    type: IncidenceActionType.GET_INCIDENCES_LOADING
}

interface IGetIncidencesSuccess {
    type: IncidenceActionType.GET_INCIDENCES_SUCCESS,
    payload: Array<IIncidence>
}

interface IGetIncidencesFail {
    type: IncidenceActionType.GET_INCIDENCES_FAIL,
    payload: IError
}


interface IGetTechnicianWithIncidentNumbersLoading {
    type: IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_LOADING
}

interface IGetTechnicianWithIncidentNumbersSuccess {
    type: IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_SUCCESS,
    payload: Array<ITechnicianWithIncidentNumbers>
}

interface IGetTechnicianWithIncidentNumbersFail {
    type: IncidenceActionType.GET_TECHNICIAN_WITH_INCIDENT_NUMBERS_FAIL,
    payload: IError
}

interface IAllocateTechnicianToIncidenceSaving{
    type: IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_SAVING
}

interface IAllocateTechnicianToIncidenceSuccess {
    type: IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_SUCCESS,
    payload: IIncidence
}

interface IAllocateTechnicianToIncidenceFail {
    type: IncidenceActionType.ALLOCATE_TECHNICIAN_TO_INCIDENCE_FAIL,
    payload: IError
}

interface IChangePrioritySupportSaving{
    type: IncidenceActionType.CHANGE_PRIORITY_SUPPORT_SAVING
}

interface IChangePrioritySupportSuccess {
    type: IncidenceActionType.CHANGE_PRIORITY_SUPPORT_SUCCESS,
    payload: IIncidence
}

interface IChangePrioritySupportFail {
    type: IncidenceActionType.CHANGE_PRIORITY_SUPPORT_FAIL,
    payload: IError
}

interface IRequestSupportSaving{
    type: IncidenceActionType.REQUEST_SUPPORT_SAVING
}

interface IRequestSupportSuccess {
    type: IncidenceActionType.REQUEST_SUPPORT_SUCCESS,
    payload: IIncidence
}

interface IRequestSupportFail {
    type: IncidenceActionType.REQUEST_SUPPORT_FAIL,
    payload: IError
}

interface ICancelIncidenceSaving {
    type: IncidenceActionType.CANCEL_INCIDENCE_SAVING
}

interface ICancelIncidenceSuccess {
    type: IncidenceActionType.CANCEL_INCIDENCE_SUCCESS,
    payload: IIncidence
}

interface ICancelIncidenceFail {
    type: IncidenceActionType.CANCEL_INCIDENCE_FAIL,
    payload: IError
}

interface IStartSupportSaving {
    type: IncidenceActionType.START_SUPPORT_SAVING
}

interface IStartSupportSuccess {
    type: IncidenceActionType.START_SUPPORT_SUCCESS,
    payload: IIncidence
}

interface IStartSupportFail {
    type: IncidenceActionType.START_SUPPORT_FAIL,
    payload: IError
}

interface IFinishSupportSaving{
    type: IncidenceActionType.FINISH_SUPPORT_SAVING
}

interface IFinishSupportSuccess {
    type: IncidenceActionType.FINISH_SUPPORT_SUCCESS,
    payload: IIncidence
}

interface IFinishSupportFail {
    type: IncidenceActionType.FINISH_SUPPORT_FAIL,
    payload: IError
}

interface ISheduleSupportSaving {
    type: IncidenceActionType.SHEDULE_SUPPORT_SAVING
}

interface ISheduleSupportSuccess {
    type: IncidenceActionType.SHEDULE_SUPPORT_SUCCESS,
    payload: IIncidence
}

interface ISheduleSupportFail {
    type: IncidenceActionType.SHEDULE_SUPPORT_FAIL,
    payload: IError
}

interface IRestartSupportSaving {
    type: IncidenceActionType.RESTART_SUPPORT_SAVING
}

interface IRestartSupportSuccess {
    type: IncidenceActionType.RESTART_SUPPORT_SUCCESS,
    payload: IIncidence
}

interface IRestartSupportFail {
    type: IncidenceActionType.RESTART_SUPPORT_FAIL,
    payload: IError
}

interface IGetIncidencesStatisticWeekLoading {
    type: IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_LOADING
}

interface IGetIncidencesStatisticWeekSuccess {
    type: IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_SUCCESS,
    payload: Array<IStatistic>
}

interface IGetIncidencesStatisticWeekFail {
    type: IncidenceActionType.GET_INCIDENCES_STATISTIC_WEEK_FAIL,
    payload: IError
}

interface IGetIncidencesStatisticYearLoading {
    type: IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_LOADING
}

interface IGetIncidencesStatisticYearSuccess {
    type: IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_SUCCESS,
    payload: Array<IStatistic>
}

interface IGetIncidencesStatisticYearFail {
    type: IncidenceActionType.GET_INCIDENCES_STATISTIC_YEAR_FAIL,
    payload: IError
}

interface IAddIncidenceRatingSaving {
    type: IncidenceActionType.ADD_INCIDENCE_RATING_SAVING
}

interface IAddIncidenceRatingSuccess {
    type: IncidenceActionType.ADD_INCIDENCE_RATING_SUCCESS,
    payload: IIncidence
}

interface IAddIncidenceRatingFail {
    type: IncidenceActionType.ADD_INCIDENCE_RATING_FAIL,
    payload: IError
}

interface IGetIncidencesRatingStatisticLoading {
    type: IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_LOADING
}

interface IGetIncidencesRatingStatisticSuccess {
    type: IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_SUCCESS,
    payload: IIncidenceRatingStatistic
}

interface IGetIncidencesRatingStatisticFail {
    type: IncidenceActionType.GET_INCIDENCES_RATING_STATISTIC_FAIL,
    payload: IError
}


export type IncidenceAction =
    IGetIncidencesLoading | IGetIncidencesSuccess | IGetIncidencesFail |
    IGetTechnicianWithIncidentNumbersLoading | IGetTechnicianWithIncidentNumbersSuccess | IGetTechnicianWithIncidentNumbersFail |
    IAllocateTechnicianToIncidenceSaving | IAllocateTechnicianToIncidenceSuccess | IAllocateTechnicianToIncidenceFail |
    IChangePrioritySupportSaving | IChangePrioritySupportSuccess | IChangePrioritySupportFail |
    IRequestSupportSaving | IRequestSupportSuccess | IRequestSupportFail |
    ICancelIncidenceSaving | ICancelIncidenceSuccess | ICancelIncidenceFail |
    IStartSupportSaving | IStartSupportSuccess | IStartSupportFail |
    IFinishSupportSaving | IFinishSupportSuccess | IFinishSupportFail |
    ISheduleSupportSaving | ISheduleSupportSuccess | ISheduleSupportFail |
    IRestartSupportSaving | IRestartSupportSuccess | IRestartSupportFail |
    IGetIncidencesStatisticWeekLoading | IGetIncidencesStatisticWeekSuccess | IGetIncidencesStatisticWeekFail |
    IGetIncidencesStatisticYearLoading | IGetIncidencesStatisticYearSuccess | IGetIncidencesStatisticYearFail |
    IAddIncidenceRatingSaving | IAddIncidenceRatingSuccess | IAddIncidenceRatingFail |
    IGetIncidencesRatingStatisticLoading | IGetIncidencesRatingStatisticSuccess | IGetIncidencesRatingStatisticFail;
