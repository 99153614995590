import {Dispatch} from "redux";
import {IncidenceTypeAction, IncidenceTypeActionType} from "../actionTypes/incidenceType.actionType";
import {Api} from "../../services/api";
import {IIncidenceType} from "../../models/incidenceType";

export const getIncidenceType = () => 
    async (dispatch: Dispatch<IncidenceTypeAction>) => {
        let api = new Api();    

        try {
            dispatch({
                type: IncidenceTypeActionType.GET_INCIDENCE_TYPES_LOADING
            })

            await api.get<IIncidenceType[]>("/incidenceType")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceTypeActionType.GET_INCIDENCE_TYPES_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceTypeActionType.GET_INCIDENCE_TYPES_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceTypeActionType.GET_INCIDENCE_TYPES_FAIL,
                payload: e
            })
        }
}

export const addIncidenceType = (description: string) =>
    async (dispatch: Dispatch<IncidenceTypeAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceTypeActionType.ADD_INCIDENCE_TYPE_SAVING
            })

            await api.post<IIncidenceType>("/incidenceType", {description})
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceTypeActionType.ADD_INCIDENCE_TYPE_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceTypeActionType.ADD_INCIDENCE_TYPE_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceTypeActionType.ADD_INCIDENCE_TYPE_FAIL,
                payload: e
            })
        }
    }

export const editIncidenceType = (id: number,description: string) =>
    async (dispatch: Dispatch<IncidenceTypeAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_SAVING
            })

            await api.put<IIncidenceType>(`/incidenceType/${id}`, {description})
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceTypeActionType.EDIT_INCIDENCE_TYPE_FAIL,
                payload: e
            })
        }
    }

export const deleteIncidenceType = (id: number) =>
    async (dispatch: Dispatch<IncidenceTypeAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_SAVING
            })

            await api.delete<IIncidenceType>(`/incidenceType/${id}`)
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_FAIL,
                        payload: e
                    })
                })

        }catch (e: any) {
            dispatch({
                type: IncidenceTypeActionType.REMOVE_INCIDENCE_TYPE_FAIL,
                payload: e
            })
        }
    }
