import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row, Spinner} from "reactstrap";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {convertToRaw, EditorState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useDispatch} from "react-redux";
import {requestSupport} from "../../../store/actionCreators/incidence.actionCreator";
import {useHistory} from "react-router";
import {getIncidencePriority} from "../../../store/actionCreators/IncidencePriority.actionCreator";
import {IIncidencePriority} from "../../../models/incidencePriority";

export interface IRequestSupport {
    title: string,
    details: string,
    incidencePriorityId: number
}

const RequestSupport = () => {
    const [title, setTitle] = useState<string>('');
    const [priority, setPriority] = useState<string>('');
    const [allIncidencePriority, setAllIncidencePriority] = useState<IIncidencePriority[]>([]);
    const [detailsDraft, setDetailsDraft] = useState<EditorState>(EditorState.createEmpty());
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getIncidencePriority())
    }, [dispatch])

    const {incidencePriority} = useTypeSelector(
        (state) => state.incidencePriority
    )

    const {isSaving, success} = useTypeSelector(
        (state) => state.incidence
    )

    useEffect(() => {
        if (incidencePriority){
            setAllIncidencePriority(incidencePriority);
        }
    },[incidencePriority])

    const onEditorStateChange = (editorState: EditorState) => {
        setDetailsDraft(editorState);
    }

    const submit = () => {
        const data = convertToRaw(detailsDraft.getCurrentContent())
        const convertTO_STRING = JSON.stringify(data);

        const requestData : IRequestSupport = {
            title,
            details: convertTO_STRING,
            incidencePriorityId: parseInt(priority)
        };

        dispatch(requestSupport(requestData))
    }
    const clear = () => {
        setPriority("hidden")
        setDetailsDraft(EditorState.createEmpty())
        setTitle('')
    }

    useEffect(() => {
        if(success){
            clear();
            history.push("/admin/support");
        }
    },[success, history])

    return(
        <>
            <div className="content">
                <Row>
                   <Col>
                       <Card>
                           <CardHeader>
                               <CardTitle tag="h4">Solicitar Suporte</CardTitle>
                               <p>Este é um formulário para solicitação de suporte ao DTICs. Preencha o
                                   formulário e envie nos seus problemas / duvidas / sugestões. Nosso
                                   suporte respondera o mais rápido possível.  </p>
                           </CardHeader>
                           <CardBody>
                                <Form>
                                    <Row>
                                        <Col className="pr-md-1" md="8">
                                            <FormGroup>
                                                <label style={{fontSize:'medium'}}>Título</label>
                                                <Input
                                                    disabled={isSaving}
                                                    placeholder="Título"
                                                    type="text"
                                                    maxLength={100}
                                                    value={title}
                                                    onChange={(e) => {setTitle(e.target.value);}}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-2" md="4">
                                            <FormGroup>
                                                <label style={{fontSize:'medium'}}>Prioridade</label>
                                                <Input
                                                    disabled={isSaving}
                                                    type={"select"}
                                                    value={priority}
                                                    onChange={(e) => {setPriority(e.target.value);}}
                                                >
                                                    <option value="" hidden>Selecione a prioridade</option>
                                                    {
                                                        allIncidencePriority.map((allIncidencePriority, key) => {
                                                            return (
                                                                <option value={allIncidencePriority.id} key={key}>{allIncidencePriority.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col className="col-md-12">
                                            <FormGroup>
                                                <label style={{fontSize:'medium'}}>Conteudo</label>
                                                <Editor
                                                    editorState={detailsDraft}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="card"
                                                    editorClassName="card-body"
                                                    onEditorStateChange={(e) => onEditorStateChange(e)}
                                                    toolbar={{
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        history: { inDropdown: true }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <div>
                                        {
                                            isSaving ?
                                                <Button
                                                    block
                                                    className="mt-4 p-2 text-capitalize col-md-2"
                                                    color="success"
                                                    type="button"
                                                    style={{fontSize:"17px", fontWeight:"400"}}
                                                >
                                                    <Spinner color="light">
                                                        Loading...
                                                    </Spinner>
                                                </Button>
                                                :
                                                <Button
                                                    block
                                                    className="col-md-2"
                                                    type="button"
                                                    disabled={title === '' || priority === ''}
                                                    onClick={submit}
                                                >
                                                    Solicitar
                                                </Button>
                                        }
                                    </div>

                                </Form>
                           </CardBody>
                       </Card>
                   </Col>
                </Row>
            </div>
        </>
    )
}
export default RequestSupport;
