import React from "react";
import {Link, NavLink} from "react-router-dom";
import { Nav, Badge } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {ExtractRouteParams, RouteComponentProps} from "react-router";
import {IRoute} from "../../../Interfaces/IRoutes";
import uemLogo from "../../../assets/img/logo-uem.png";
import {useTypeSelector} from "../../../hooks/useTypeSelector";

var ps : any;

interface ISideBarProps {
  props: RouteComponentProps<ExtractRouteParams<string,string>>,
  routes: Array<IRoute>
}

function Sidebar({props, routes} : ISideBarProps) {
  const sidebar = React.useRef<PerfectScrollbar & HTMLDivElement>(null);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1 && sidebar.current) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

    const {userLogged} = useTypeSelector(
        (state) => state.user
    );
    const {roles} = userLogged;
    const role = roles[0];

  return (
		<div className="sidebar" data-color="black" data-active-color="warning">
			<div className="logo">
                <Link
                    to="/admin/dashboard"
                    className="simple-text logo-mini mt-1"
                >
                    <Badge color="light">
                        <div className="logo-img">
                            <img alt="FEUEM logo" src={uemLogo} height="25"/>
                        </div>
                    </Badge>
                </Link>
                <Link
                    to="/admin/dashboard"
					className="simple-text logo-normal"
				>
					Suporte
				</Link>
			</div>
			<div className="sidebar-wrapper" ref={sidebar}>
				<Nav>
					{routes.map((prop, key) => {
						if (prop.layout === "/admin" && !prop.invisible) {
						    if (role === "Collaborator" && prop.path !== "/users" && prop.path !== "/settings" &&
                                prop.path !== "/scheduler" && prop.path !== "/reports"){
                                return (
                                    <li className={activeRoute(prop.path)} key={key}>
                                        <NavLink
                                            to={prop.layout + prop.path}
                                            className="nav-link"
                                            activeClassName="active"
                                        >
                                            <i className={prop.icon} />
                                            <p> {prop.name} </p>
                                        </NavLink>
                                    </li>
                                );
                            } else {
                                if (role === "SystemAdministrator" || role === "Technician"){
                                    return (
                                        <li className={activeRoute(prop.path)} key={key}>
                                            <NavLink
                                                to={prop.layout + prop.path}
                                                className="nav-link"
                                                activeClassName="active"
                                            >
                                                <i className={prop.icon} />
                                                <p> {prop.name} </p>
                                            </NavLink>
                                        </li>
                                    );
                                }else {
                                    return null;
                                }
                            }

						} else {
							return null;
						}
					})}
				</Nav>
			</div>
		</div>
	);
}

export default Sidebar;
