import React, {useEffect, useState} from 'react';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {useTypeSelector} from "../../../../hooks/useTypeSelector";
import {statisticYearHandle} from "../../../../variables/charts";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChartYear = () => {
    const [statisticProgressYear,setStatisticProgressYear] = useState<Array<number>>([])
    const [statisticCanceledYear,setStatisticCanceledYear] = useState<Array<number>>([])
    const [statisticTerminatedYear,setStatisticTerminatedYear] = useState<Array<number>>([])
    const [statisticTotalYear,setStatisticTotalYear] = useState<Array<number>>([])

    const {statisticYear} = useTypeSelector(
        (state) => state.incidence
    );

    useEffect(() => {
        let statisticData = statisticYearHandle(statisticYear)
        if (statisticData){
            setStatisticCanceledYear(statisticData.canceled)
            setStatisticTerminatedYear(statisticData.terminated)
            setStatisticTotalYear(statisticData.total)
            setStatisticProgressYear(statisticData.progress)
        }
    },[statisticYear])

    const data = {
        labels: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        datasets: [
            {
                data: statisticTotalYear ? statisticTotalYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#fbc658",
                backgroundColor: "transparent",
                pointBorderColor: "#fbc658",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
            {
                data: statisticProgressYear ? statisticProgressYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#FF5733",
                backgroundColor: "transparent",
                pointBorderColor: "#FF5733",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
            {
                data: statisticTerminatedYear ? statisticTerminatedYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#51CACF",
                backgroundColor: "transparent",
                pointBorderColor: "#51CACF",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
            {
                data: statisticCanceledYear ? statisticCanceledYear : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: false,
                borderColor: "#A3EBB1",
                backgroundColor: "transparent",
                pointBorderColor: "#A3EBB1",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
                tension: 0.4,
            },
        ]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: { display: false },
        },
    }

    return (
        <>
            <Line
                data={data}
                options={options}
                width={400}
                height={100}
            />
        </>
    )
}

export default LineChartYear;
