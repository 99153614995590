import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ListSupport from "./ListSupport";
import RequestSupport from "./RequestSupport";
import DetailsSupport from "./DetailsSupport";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {useSnackbar} from "notistack";
import ChatbotSupport from "./ChatbotSupport";

const Support = () => {
    const location = useLocation();
    const [incidenceNumber, setIncidenceNumber] = useState<string>('');
    const {enqueueSnackbar} = useSnackbar();

    const { success, error, errorMessage, successMessage} = useTypeSelector(
        (state) => state.incidence
    )

    useEffect(() => {
        let before = location.pathname.substring(location.pathname.indexOf('0'));
        setIncidenceNumber(before.replace('0',''))
    },[location.pathname])


    useEffect(() => {
        if(success) {
            enqueueSnackbar(successMessage, {
                variant: 'success',
            });
        }
        if (error){
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
        }
    },[error, successMessage, errorMessage, success, enqueueSnackbar])

    return (
        <>
            {
                location.pathname === "/admin/support" &&
                    <ListSupport/>
            }

            {
                location.pathname === "/admin/support/chatbot" &&
                <ChatbotSupport/>
            }

            {
                location.pathname === "/admin/support/request" &&
                    <RequestSupport/>
            }

            {
                location.pathname.includes("/admin/support/details") &&
                    <DetailsSupport id={incidenceNumber}/>
            }

        </>
    )
}

export default Support;
