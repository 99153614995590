import {IRole} from "../../models/role";
import {IError} from "../../models/error";

export enum RoleActionTypes {
    GET_ROLES_LOADING = "GET_ROLES_LOADING",
    GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS",
    GET_ROLES_FAIL = "GET_ROLES_FAIL"
}

interface IGetRolesLoading {
    type: RoleActionTypes.GET_ROLES_LOADING
}

interface IGetRolesSuccess {
    type: RoleActionTypes.GET_ROLES_SUCCESS,
    payload: Array<IRole>
}

interface IGetRolesFail {
    type: RoleActionTypes.GET_ROLES_FAIL,
    payload: IError
}

export type RoleAction =
    IGetRolesLoading | IGetRolesSuccess | IGetRolesFail;
