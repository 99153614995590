import {IDepartment} from "../../models/department";
import {IError} from "../../models/error";

export enum DepartmentActionTypes {
    GET_DEPARTMENTS_LOADING = "GET_DEPARTMENTS_LOADING",
    GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS",
    GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL",
    ADD_DEPARTMENT_SAVING = "ADD_DEPARTMENT_SAVING",
    ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS",
    ADD_DEPARTMENT_FAIL = "ADD_DEPARTMENT_FAIL",
    EDIT_DEPARTMENT_SAVING = "EDIT_DEPARTMENT_SAVING",
    EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS",
    EDIT_DEPARTMENT_FAIL = "EDIT_DEPARTMENT_FAIL",
    REMOVE_DEPARTMENT_SAVING = "REMOVE_DEPARTMENT_SAVING",
    REMOVE_DEPARTMENT_SUCCESS = "REMOVE_DEPARTMENT_SUCCESS",
    REMOVE_DEPARTMENT_FAIL = "REMOVE_DEPARTMENT_FAIL",
}

interface IGetDepartmentsLoading {
    type: DepartmentActionTypes.GET_DEPARTMENTS_LOADING
}

interface IGetDepartmentsSuccess {
    type: DepartmentActionTypes.GET_DEPARTMENTS_SUCCESS,
    payload: Array<IDepartment>
}

interface IGetDepartmentsFail {
    type: DepartmentActionTypes.GET_DEPARTMENTS_FAIL,
    payload: IError
}


interface IAddDepartmentSaving {
    type: DepartmentActionTypes.ADD_DEPARTMENT_SAVING
}

interface IAddDepartmentSuccess {
    type: DepartmentActionTypes.ADD_DEPARTMENT_SUCCESS,
    payload: IDepartment
}

interface IAddDepartmentFail {
    type: DepartmentActionTypes.ADD_DEPARTMENT_FAIL,
    payload: IError
}

interface IEditDepartmentSaving {
    type: DepartmentActionTypes.EDIT_DEPARTMENT_SAVING
}

interface IEditDepartmentSuccess {
    type: DepartmentActionTypes.EDIT_DEPARTMENT_SUCCESS,
    payload: IDepartment
}

interface IEditDepartmentFail {
    type: DepartmentActionTypes.EDIT_DEPARTMENT_FAIL,
    payload: IError
}

interface IRemoveDepartmentSaving {
    type: DepartmentActionTypes.REMOVE_DEPARTMENT_SAVING
}

interface IRemoveDepartmentSuccess {
    type: DepartmentActionTypes.REMOVE_DEPARTMENT_SUCCESS,
    payload: IDepartment
}

interface IRemoveDepartmentFail {
    type: DepartmentActionTypes.REMOVE_DEPARTMENT_FAIL,
    payload: IError
}


export type DepartmentAction =
        IGetDepartmentsLoading | IGetDepartmentsSuccess | IGetDepartmentsFail |
        IAddDepartmentSaving  | IAddDepartmentSuccess | IAddDepartmentFail |
        IEditDepartmentSaving  | IEditDepartmentSuccess | IEditDepartmentFail |
        IRemoveDepartmentSaving | IRemoveDepartmentSuccess | IRemoveDepartmentFail;

