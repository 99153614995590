import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col, Form, FormGroup, Input,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {IIncidence} from "../../../models/incidence";
import {convertFromRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {IUser} from "../../../models/user";
import {useDispatch} from "react-redux";
import {
    allocateTechnician,
    cancelIncidence,
    changeIncidencePriority,
    finishSupport, restartSupport, sheduleSupport,
    startSupport
} from "../../../store/actionCreators/incidence.actionCreator";
import {IIncidencePriority} from "../../../models/incidencePriority";
import {getIncidencePriority} from "../../../store/actionCreators/IncidencePriority.actionCreator";
import ChatSupport from "../../../components/Chat/ChatSupport";
import {getUser} from "../../../store/actionCreators/user.actionCreator";
import {IShedule} from "../../../models/Shedule";
import {getShedulesByTime} from "../../../store/actionCreators/shedule.actionCreator";
import RatingSupport from "../../../components/Rating/RatingSupport";
import {ITechnicianWithIncidentNumbers} from "../../../models/TechnicianWithIncidentNumbers";

export interface IDetailsSupportProps {
    id: string
}

const DetailsSupport = ({id}: IDetailsSupportProps) => {
    const [allIncidences, setAllIncidences] = useState<IIncidence[]>([]);
    const [allTechnicianWithIncidentNumbers, setAllTechnicianWithIncidentNumbers] = useState<ITechnicianWithIncidentNumbers[]>([]);
    const [incidenceSelected, setIncidenceSelected] = useState<IIncidence>();
    const [detailsDraft, setDetailsDraft] = useState<EditorState>(EditorState.createEmpty());
    const [user,setUser] = useState<IUser>();
    const [modalPriority, setModalPriority] = useState<boolean>(false);
    const [modalAllocateTechnician, setModalAllocateTechnician] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [modalStart, setModalStart] = useState<boolean>(false);
    const [modalFinish, setModalFinish] = useState<boolean>(false);
    const [modalText, setModalText] = useState<string>('');
    const [modalShedule, setModalShedule] = useState<boolean>(false);
    const [modalRestart, setModalRestart] = useState<boolean>(false);
    const [allIncidencePriority, setAllIncidencePriority] = useState<IIncidencePriority[]>();
    const [priority, setPriority] = useState<string>('');
    const [technician, setTechnician] = useState<string>('');
    const [sheduleByTime, setSheduleByTime] = useState<IShedule[]>()
    const [shedule, setShedule] = useState<string>('');
    const [sheduleStartDate, setSheduleStartDate] = useState<string>();
    const [sheduleEndDate, setSheduleEndDate] = useState<string>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getIncidencePriority())
    },[dispatch])

    useEffect(() => {
        dispatch(getUser())
    },[incidenceSelected?.incidenceStatus, dispatch])

    useEffect(() => {
        dispatch(getShedulesByTime())
    },[dispatch])

    const {isSaving, incidences, technicianWithIncidentNumbers} = useTypeSelector(
        (state) => state.incidence
    )

    const {isSavingShedule, shedulesByTime} = useTypeSelector(
        (state) => state.shedule
    )

    const {incidencePriority} = useTypeSelector(
        (state) => state.incidencePriority
    )

    const {userLogged} = useTypeSelector(
        (state) => state.user
    )

    useEffect(() => {
        if (userLogged){
            setUser(userLogged)
        }
    },[userLogged])

    useEffect(() => {

        if (incidences){
            setAllIncidences(incidences)
        }

        if (allIncidences.length > 0){
            allIncidences.forEach(function (incidence) {
              if (incidence.id === parseInt(id)){
                  setIncidenceSelected(incidence)
                  //Convert to JSON
                  const convertTO_JSON = EditorState.createWithContent(convertFromRaw(JSON.parse(incidence.details)));
                  setDetailsDraft(convertTO_JSON);
              }
            })
        }
    },[incidences, allIncidences, id])


    useEffect(() => {
        if (technicianWithIncidentNumbers){
            setAllTechnicianWithIncidentNumbers(technicianWithIncidentNumbers);
        }

        if (allTechnicianWithIncidentNumbers){
            allTechnicianWithIncidentNumbers.forEach(function (technician) {
                const technicianNumbers = `${incidenceSelected?.technician?.fullName} (${technician?.incidentsNumbers})`
                if (technicianNumbers === technician?.technicianName){
                    setTechnician(`${technician?.technicianId}`)
                }
            })
        }

    }, [technicianWithIncidentNumbers, allTechnicianWithIncidentNumbers, incidenceSelected?.technician])

    useEffect(() => {
        if (incidencePriority) {
            setAllIncidencePriority(incidencePriority);
        }

        if (allIncidencePriority){
            allIncidencePriority.forEach(function (priority) {
                if (incidenceSelected?.incidencePriority === priority.name){
                    setPriority(`${priority.id}`)
                }
            })
        }
    },[incidencePriority, allIncidencePriority, incidenceSelected?.incidencePriority])

    useEffect(() => {
       if (shedulesByTime){
           setSheduleByTime(shedulesByTime)
       }
    },[shedulesByTime])


    useEffect(() => {
        if (shedule !== '') {
            sheduleByTime?.forEach(function (shed) {
                if (shed.id === parseInt(shedule)){
                    setSheduleStartDate(convertDate(shed.startDate));
                    setSheduleEndDate(convertDate(shed.endDate));
                }
            })
        }
    },[shedule, sheduleByTime])

    const convertDate = (dateToConvert: Date = new Date()) => {
        const date = new Date(dateToConvert);
        const viewDate = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        const viewMonth = date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`

        const viewDateHour = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
        const viewDateMinute = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`

        return viewDate + '/' + viewMonth + '/' + date.getFullYear() + ' as '+ viewDateHour +':'+ viewDateMinute;
    }

    const convertDateShedule = (dateToConvert: Date = new Date()) => {
        const date = new Date(dateToConvert);
        const viewDate = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        const viewMonth = date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`

        const viewDateHour = date.getHours() > 9 ? date.getHours() + 2 : `0${date.getHours() +2}`
        const viewDateMinute = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`

        return viewDate + '/' + viewMonth + '/' + date.getFullYear() + ' as '+ viewDateHour +':'+ viewDateMinute;
    }

    const cancelHandler = () => {
        dispatch(cancelIncidence(parseInt(id)))
    }

    const startHandler = () => {
        dispatch(startSupport(parseInt(id)))
    }

    const finishHandler = () => {
        dispatch(finishSupport(parseInt(id)))
    }

    const modalHandler = (type: string) => {
        switch (type) {
            case "cancel":
                setModal(true)
                setModalCancel(true);
                setModalText("Cancelar Suporte");
                break;
            case "start":
                setModal(true)
                setModalStart(true);
                setModalText("Iniciar Suporte");
                break;
            case "finish":
                setModal(true)
                setModalFinish(true);
                setModalText("Terminar Suporte");
                break;
        }
    }

    const modalActionHandler = () => {
        if (modalStart){
            startHandler();
        }
        if (modalCancel){
            cancelHandler();
        }
        if (modalFinish){
            finishHandler();
        }
    }

    const modalSheduleHandler = () => {
        setModalShedule(!modalShedule);
    }

    const modalRestartSheduleHandler = () => {
        setModalRestart(!modalRestart)
    }

    const modalRestartActionHandler = () => {
        if (incidenceSelected?.id){
            dispatch(restartSupport(incidenceSelected?.id))
        }
    }

    const modalSheduleActionHandler = () => {
        if (incidenceSelected?.id){
            dispatch(sheduleSupport(incidenceSelected?.id,parseInt(shedule)))
        }
    }

    const toggle = () => {
        setModal(false)
        setModalStart(false)
        setModalCancel(false)
        setModalFinish(false)
        setModalShedule(false);
        setModalRestart(false);
        setModalText("");
    }

    const toggleAllocateTechnicianToIncidence = () => {
        setModalAllocateTechnician(!modalAllocateTechnician);
    }

    const togglePriority = () => {
        setModalPriority(!modalPriority);
    }

    const changePriority = () => {
        dispatch(changeIncidencePriority(parseInt(id), parseInt(priority)));
    }

    const allocateTechnicianToIncidence = () => {
       console.log('Tecnico', technician)

         if (incidenceSelected)
            dispatch(allocateTechnician(incidenceSelected.id, technician))
    }

    return(
        <>
            <div className="content">
                <Row>
                    <Col className="col-md-9">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="col-md-7">
                                        <CardTitle tag="h4"> Detalhes da solicitação de suporte </CardTitle>
                                    </Col>

                                    {
                                        incidenceSelected?.incidenceStatus === "Agendado" &&
                                            <Col className="mt-3">
                                                <p className="text-danger"> Suporte agendado para o dia {" "}
                                                    {convertDateShedule(incidenceSelected.incidenceSchedule.startDate)}</p>
                                            </Col>
                                    }

                                    {
                                        (incidenceSelected?.incidenceStatus === "Em andamento" &&
                                            incidenceSelected?.collaborator.email !== user?.email &&
                                            user?.roles[0] !== "Collaborator") &&
                                            <>
                                                <Col className="col-md-2"><></></Col>
                                                <Col>
                                                    <Button
                                                        color="warning "
                                                        onClick={()=> {modalSheduleHandler()}}
                                                    >
                                                        Agendar
                                                    </Button>
                                                </Col>
                                            </>
                                    }

                                    {
                                        (incidenceSelected?.incidenceStatus === "Agendado" &&
                                            incidenceSelected?.collaborator.email !== user?.email &&
                                            user?.roles[0] !== "Collaborator") &&
                                        <Col className="mr-4">
                                            <Button
                                                disabled={!user?.free}
                                                onClick={()=> {modalRestartSheduleHandler()}}
                                            >
                                                Re-Iniciar
                                            </Button>
                                        </Col>
                                    }
                                </Row>

                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col className="col-md-12">
                                        <p className="medium"> <strong>Titulo</strong> </p>
                                        <p>{incidenceSelected?.title}</p>
                                    </Col>

                                </Row>
                                <Row className="pt-md-4">
                                    <Col className="col-md-12">
                                        <Row>
                                            <p className="medium col-6"> <strong>Conteudo</strong> </p>
                                            <p className="ml-auto mr-auto"> <strong>Data:   </strong> {convertDate(incidenceSelected?.date)}</p>
                                        </Row>
                                        <Row>
                                            <Col className="col-md-10">
                                                <Editor
                                                    editorState={detailsDraft}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="card"
                                                    editorClassName="card-body"
                                                    readOnly={true}
                                                    toolbarHidden
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {
                                    incidenceSelected?.incidenceStatus !== "Pendente" &&
                                        <>
                                            <hr style={{width:"98%"}}/>
                                            <ChatSupport incidenceId={id} incidenceStatus={incidenceSelected?.incidenceStatus}/>
                                        </>
                                }

                                {
                                    (incidenceSelected?.incidenceStatus !== "Cancelado" &&
                                        incidenceSelected?.incidenceStatus !== "Terminado" &&
                                        incidenceSelected?.collaborator?.email === user?.email) &&
                                    <Row>
                                        <Col className="col-md-10"><></></Col>
                                        <Col>
                                            <Button
                                                onClick={()=> {modalHandler("cancel")}}
                                            >
                                                Cancelar
                                            </Button>
                                        </Col>
                                    </Row>
                                }

                                {
                                    (incidenceSelected?.incidenceStatus === "Pendente" &&
                                        incidenceSelected?.collaborator.email !== user?.email &&
                                        user?.roles[0] !== "Collaborator"
                                    ) &&
                                    <Row>
                                        <Col className="col-md-9"><></></Col>
                                        <Col>
                                            <Button
                                                disabled={!user?.free}
                                                onClick={()=> {modalHandler("start")}}
                                            >
                                                Iniciar Suporte
                                            </Button>
                                        </Col>
                                    </Row>
                                }

                                {
                                    (incidenceSelected?.incidenceStatus === "Em andamento" &&
                                        incidenceSelected?.collaborator.email !== user?.email &&
                                        user?.roles[0] !== "Collaborator") &&
                                    <Row>
                                        <Col className="col-md-9"><></></Col>

                                        <Col>
                                            <Button
                                                onClick={()=> {modalHandler("finish")}}
                                            >
                                                Terminar Suporte
                                            </Button>
                                        </Col>
                                    </Row>
                                }

                                {
                                    (incidenceSelected?.incidenceStatus === "Terminado" &&
                                        (incidenceSelected?.collaborator.email === user?.email || incidenceSelected?.ratingId)) &&
                                        <>
                                            <hr style={{width:"98%"}}/>
                                            <RatingSupport id={id} incidenceSelected={incidenceSelected}/>
                                        </>
                                }

                            </CardBody>
                        </Card>
                    </Col>

                    <Col className="mr-auto">
                        <Card>
                            <CardHeader>
                                <Row>
                                    {
                                        (user?.roles[0] !== "Collaborator") &&
                                        <Col className="md-4">
                                            <p className="medium"> <strong>Autor</strong> </p>
                                            <p>{incidenceSelected?.collaborator?.fullName}</p>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col className="col-md-12">
                                        <p className="medium"> <strong>Estado</strong> </p>
                                        <p className="col-md-7" style={{backgroundColor:`${incidenceSelected?.incidenceStatusColor}`,
                                            textAlign: 'center', borderRadius: '100px', color: '#FFFFFF'}}>
                                            {incidenceSelected?.incidenceStatus}</p>
                                    </Col>

                                    <Col className="mr-auto">
                                        <p className="medium"> <strong>Prioridade</strong> </p>
                                        <Row>
                                            <Col className="col-md-7">
                                                <p style={{backgroundColor: `${incidenceSelected?.incidencePriorityColor}`,
                                                    borderRadius: '100px', textAlign: 'center', color: '#FFFFFF'}}>
                                                    {incidenceSelected?.incidencePriority}
                                                </p>
                                            </Col>
                                            {
                                                (user?.roles[0] !== "Collaborator" &&
                                                    incidenceSelected?.collaborator.email !== user?.email &&
                                                    incidenceSelected?.incidenceStatus !== "Cancelado"
                                                    && incidenceSelected?.incidenceStatus !== "Terminado"
                                                ) &&
                                                <Col style={{bottom: '12px'}}>
                                                    <Button className="btn btn-sm" onClick={togglePriority}>Alterar</Button>
                                                </Col>
                                            }

                                        </Row>
                                    </Col>

                                    <Col className="col-md-12">
                                        <p className="medium"> <strong>Tecnico de Suporte</strong> </p>
                                        <Row>
                                            <Col className="col-md-7">
                                                <p>
                                                    {incidenceSelected?.technician?.fullName}
                                                </p>
                                            </Col>
                                            {
                                                (user?.roles[0] === "SystemAdministrator" &&
                                                    incidenceSelected?.collaborator.email !== user?.email &&
                                                    incidenceSelected?.incidenceStatus !== "Cancelado"
                                                    && incidenceSelected?.incidenceStatus !== "Terminado"
                                                ) &&
                                                <Col style={{bottom: '12px'}}>
                                                    <Button className="btn btn-sm" onClick={toggleAllocateTechnicianToIncidence}>Alocar</Button>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>

                                    {/*{ (user?.roles[0] !== "Collaborator") &&*/}
                                    {/*    <Col className="col-md-12">*/}
                                    {/*        <p className="medium"> <strong>Tipo</strong> </p>*/}
                                    {/*        <p>{incidenceSelected?.incidenceType}</p>*/}
                                    {/*    </Col>*/}
                                    {/*}*/}

                                </Row>
                            </CardHeader>
                            <CardBody>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>


            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalAllocateTechnician}
                toggle={toggleAllocateTechnicianToIncidence}
            >
                <ModalHeader
                    toggle={toggleAllocateTechnicianToIncidence}
                >
                    Alocar tecnico de Suporte
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <label style={{fontSize:'medium'}}>Tecnicos de Suporte</label>
                            <Input
                                disabled={isSaving}
                                type={"select"}
                                value={technician}
                                onChange={(e) => {setTechnician(e.target.value)}}
                            >
                                <option value="" hidden>Selecione o tecnico</option>
                                {
                                    allTechnicianWithIncidentNumbers?.map((p, key) => {
                                        return(
                                            <option value={p.technicianId} key={key}>{p.technicianName}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                 onClick={allocateTechnicianToIncidence}
                            >
                                Alocar
                            </Button>
                    }

                    <Button onClick={toggleAllocateTechnicianToIncidence} color="danger">Cancelar</Button>
                </ModalFooter>

            </Modal>

            {/*Modal for remove user*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                >
                    {modalText}
                </ModalHeader>
                <ModalBody>
                    <p>Deseja {modalText} ?</p>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={modalActionHandler}
                            >
                                Sim
                            </Button>
                    }

                    <Button onClick={toggle} color="danger">Não</Button>
                </ModalFooter>
            </Modal>


            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalPriority}
                toggle={togglePriority}
            >
                <ModalHeader
                    toggle={togglePriority}
                >
                    Alteração da Prioridade
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <label style={{fontSize:'medium'}}>Prioridade</label>
                            <Input
                                disabled={isSaving}
                                type={"select"}
                                value={priority}
                                onChange={(e) => {setPriority(e.target.value)}}
                            >
                                <option value="" hidden>Selecione a prioridade</option>
                                {
                                    allIncidencePriority?.map((p, key) => {
                                        return(
                                            <option value={p.id} key={key}>{p.name}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={changePriority}
                            >
                                Alterar
                            </Button>
                    }

                    <Button onClick={togglePriority} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>

            {/*Modal of Shedule*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalShedule}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                >
                    Agendar
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <label style={{fontSize:'medium'}}>Agenda</label>
                            <Input
                                disabled={isSavingShedule}
                                type={"select"}
                                value={shedule}
                                onChange={(e) => {setShedule(e.target.value)}}
                            >
                                <option value="" hidden>Selecione a agenda</option>
                                {
                                    sheduleByTime?.map((p, key) => {
                                        return(
                                            <option value={p.id} key={key}>{p.title}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Form>
                    <Row className="mt-4">
                        <Col className="col-md-10">
                            <p> Data de Inicio: {sheduleStartDate} </p>
                        </Col>
                        <Col className="col-md-10">
                            <p> Data de Termino: {sheduleEndDate}</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {
                        isSavingShedule ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={modalSheduleActionHandler}
                            >
                                Agendar
                            </Button>
                    }
                </ModalFooter>
            </Modal>

            {/*Modal support restart user*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalRestart}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                >
                   Re-Iniciar
                </ModalHeader>
                <ModalBody>
                    <p>Deseja reiniciar ?</p>
                </ModalBody>
                <ModalFooter>
                    {
                        isSavingShedule ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={modalRestartActionHandler}
                            >
                                Sim
                            </Button>
                    }

                    <Button onClick={toggle} color="danger">Não</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default DetailsSupport;
