import React, {useState} from "react";
import {Button, Col, Form, FormGroup} from "reactstrap";
import {Editor} from "react-draft-wysiwyg";
import {convertToRaw, EditorState} from "draft-js";

interface ISendMessageComponent {
    sendMessage: (message: string) => void
}

const SendMessageComponent = ({sendMessage}: ISendMessageComponent) => {
    const [detailsDraft, setDetailsDraft] = useState<EditorState>(EditorState.createEmpty());
    const onEditorStateChange = (editorState: EditorState) => {
        setDetailsDraft(editorState);
    }

    const sendMs = async () => {
        const data = convertToRaw(detailsDraft.getCurrentContent())
        const convertTO_STRING = JSON.stringify(data);
        await sendMessage(convertTO_STRING);
        setDetailsDraft(EditorState.createEmpty())
    }

    return(
        <>
            <Col className="mt-4 col-md-10">
                <Form>
                    <FormGroup>
                        <Editor
                            editorState={detailsDraft}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="card"
                            editorClassName="card-body"
                            onEditorStateChange={(e) => onEditorStateChange(e)}
                            toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true }
                            }}
                        />
                    </FormGroup>
                </Form>
            </Col>
            <Col className="mt-auto">
                <Button onClick={sendMs} disabled={!detailsDraft}>Enviar <i className="nc-icon nc-send" /></Button>
            </Col>
        </>
    )
}


export default SendMessageComponent;
