import React, {useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import {
    Scheduler,
    WeekView,
    Appointments,
    AppointmentTooltip,
    MonthView,
    Toolbar,
    DayView,
    ViewSwitcher,
    DateNavigator,
    TodayButton,
    AppointmentForm,
    ConfirmationDialog
} from '@devexpress/dx-react-scheduler-material-ui';
import {
    AppointmentModel,
    EditingState,
    IntegratedEditing,
    ViewState
} from '@devexpress/dx-react-scheduler';
import {useDispatch} from "react-redux";
import {addShedule, getShedules, removeShedule} from "../../../store/actionCreators/shedule.actionCreator";
import {useTypeSelector} from "../../../hooks/useTypeSelector";

export interface ISheduleData {
    id?: number,
    title: string,
    notes: string,
    startDate: any,
    endDate: any,
    allDay: boolean
}

const SchedulerSupport = () => {
    const [data, setData] = useState<Array<AppointmentModel>>([]);
    const [sheduleData,setSheduleData] = useState<Array<ISheduleData>>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getShedules())
    },[dispatch])

    const { shedules } = useTypeSelector(
        (state) => state.shedule
    );

    useEffect(() => {
        if (shedules){
            setSheduleData(shedules)
        }

    },[shedules])

    useEffect(() => {
        const sheduleAux: Array<ISheduleData> = [];

        if (sheduleData.length > 0){
            sheduleData?.forEach(function (shed) {
                const startDate = shed.startDate.toString();
                const endDate = shed.endDate.toString();

                sheduleAux.push({id: shed.id, title: shed.title,
                    startDate: startDate, endDate: endDate, notes: shed.notes,
                    allDay: shed.allDay})
            })

            if (sheduleAux.length > 0){
                setData(sheduleAux)
            }
        }

    },[sheduleData])


    const commitChanges = ({added, changed, deleted}: any) => {
        if (added){
            const startDate = added.startDate.toISOString();
            const endDate = added.endDate.toISOString();

            const add : ISheduleData = {title: added.title, notes: added.notes,
                startDate: startDate, endDate: endDate, allDay: added.allDay}

            dispatch(addShedule(add));
        }

        // if (changed) {
        // }

        if (deleted !== undefined) {
            dispatch(removeShedule(deleted));
        }
    }

    return(
        <>
            <div className="content">
                <Paper>
                    <Scheduler
                        data={data}
                        locale = "pt-MZ"

                    >
                        <ViewState
                            defaultCurrentViewName="Week"
                        />
                        <EditingState
                            onCommitChanges={commitChanges}

                        />
                        <IntegratedEditing />
                        <DayView
                            startDayHour={7}
                            endDayHour={16}
                        />
                        <WeekView startDayHour={7} endDayHour={16} />
                        <MonthView />
                        <Toolbar />
                        <DateNavigator />
                        <TodayButton />
                        <ViewSwitcher />
                        <Appointments />
                        <AppointmentTooltip
                            showCloseButton
                            showOpenButton
                        />
                        <ConfirmationDialog
                            ignoreCancel
                        />
                        <AppointmentForm />
                    </Scheduler>
                </Paper>

            </div>
        </>
    )
}

export default SchedulerSupport;
