import {IRating} from "../../models/rating";
import {RatingAction, RatingActionTypes} from "../actionTypes/rating.actionTypes";

export interface IRatingState {
    ratings: Array<IRating>,
    isLoading: boolean,
    isSaving: boolean,
    error: boolean,
    success: boolean,
    successMessage: string,
    errorMessage: string
}

const initialState: IRatingState = {
    ratings: [],
    isLoading: false,
    isSaving: false,
    error: false,
    success: false,
    errorMessage: '',
    successMessage: ''
}

const ratingReducer = (state: IRatingState = initialState, action: RatingAction) => {
    switch (action.type) {
        case RatingActionTypes.GET_RATINGS_LOADING:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                error: false,
                success: false,
                successMessage: '',
                errorMessage: ''
            }
        case RatingActionTypes.GET_RATINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                error: false,
                success: false,
                ratings: action.payload
            }
        case RatingActionTypes.GET_RATINGS_FAIL:
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                error: false,
                success: false,
                errorMessage: action.payload
            }

        default:
            return state;
    }
}

export default ratingReducer;
