import {Dispatch} from "redux";
import {SheduleAction, SheduleActionTypes} from "../actionTypes/shedule.actionType";
import {Api} from "../../services/api";
import {IShedule} from "../../models/Shedule";
import {ISheduleData} from "../../views/admin/Support/SchedulerSupport";

export const getShedules = () =>
    async (dispatch: Dispatch<SheduleAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: SheduleActionTypes.GET_SHEDULES_LOADING
            });

            await api.get<IShedule[]>("/shedules")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: SheduleActionTypes.GET_SHEDULES_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: SheduleActionTypes.GET_SHEDULES_FAIL,
                        payload: e
                    });
                })

        }catch (e: any) {
            dispatch({
                type: SheduleActionTypes.GET_SHEDULES_FAIL,
                payload: e
            });
        }

    }

export const getShedulesByTime = () =>
    async (dispatch: Dispatch<SheduleAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: SheduleActionTypes.GET_SHEDULES_BY_TIME_LOADING
            });

            await api.get<IShedule[]>("/shedules/time")
                .then(result => {
                    if (result.status === 200) {
                        dispatch({
                            type: SheduleActionTypes.GET_SHEDULES_BY_TIME_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: SheduleActionTypes.GET_SHEDULES_BY_TIME_FAIL,
                        payload: e
                    });
                })

        }catch (e: any) {
            dispatch({
                type: SheduleActionTypes.GET_SHEDULES_BY_TIME_FAIL,
                payload: e
            });
        }

    }

export const addShedule = (shedule: ISheduleData) =>
    async (dispatch: Dispatch<SheduleAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: SheduleActionTypes.ADD_SHEDULES_SAVING
            });

            await api.post<IShedule>("/shedules", shedule)
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: SheduleActionTypes.ADD_SHEDULES_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: SheduleActionTypes.ADD_SHEDULES_FAIL,
                        payload: e
                    });
                })

        }catch (e: any) {
            dispatch({
                type: SheduleActionTypes.ADD_SHEDULES_FAIL,
                payload: e
            });
        }

    }

export const editShedule = (id: number, shedule: ISheduleData) =>
    async (dispatch: Dispatch<SheduleAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: SheduleActionTypes.EDIT_SHEDULES_SAVING
            });

            await api.put<IShedule>(`/shedules/${id}`, shedule)
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: SheduleActionTypes.EDIT_SHEDULES_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: SheduleActionTypes.EDIT_SHEDULES_FAIL,
                        payload: e
                    });
                })

        }catch (e: any) {
            dispatch({
                type: SheduleActionTypes.EDIT_SHEDULES_FAIL,
                payload: e
            });
        }

    }

export const removeShedule = (id: number) =>
    async (dispatch: Dispatch<SheduleAction>) => {
        let api = new Api();

        try {
            dispatch({
                type: SheduleActionTypes.REMOVE_SHEDULE_SAVING
            });

            await api.delete<IShedule>(`/shedules/${id}`)
                .then(result => {

                    if (result.status === 200) {
                        dispatch({
                            type: SheduleActionTypes.REMOVE_SHEDULE_SUCCESS,
                            payload: result.data
                        });
                    }
                }).catch(e => {
                    dispatch({
                        type: SheduleActionTypes.REMOVE_SHEDULE_FAIL,
                        payload: e
                    });
                })

        }catch (e: any) {
            dispatch({
                type: SheduleActionTypes.REMOVE_SHEDULE_FAIL,
                payload: e
            });
        }

    }
