import { combineReducers } from "redux";
import loginReducer from "./login.reducer";
import userReducer from "./user.reducer";
import departmentReducer from "./department.reducer";
import roleReducer from "./role.reducer";
import incidenceTypeReducer from "./incidenceType.reducer";
import incidenceReducer from "./incidence.reducer";
import incidencePriorityReducer from "./incidencePriority.reducer";
import incidenceMessageReducer from "./incidenceMessage.reducer";
import sheduleReducer from "./shedule.reducer";
import faqReducer from "./faq.reducer";
import ratingReducer from "./rating";

const reducers = combineReducers({
   login: loginReducer,
   user: userReducer,
   role: roleReducer,
   department: departmentReducer,
   incidenceType: incidenceTypeReducer,
   incidence: incidenceReducer,
   incidencePriority: incidencePriorityReducer,
   incidenceMessage: incidenceMessageReducer,
   shedule: sheduleReducer,
   faq: faqReducer,
   rating: ratingReducer
});

export default reducers;
export type RootState = ReturnType<typeof reducers>
