import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col, Form, FormGroup, Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import {useDispatch} from "react-redux";
import {useTypeSelector} from "../../../hooks/useTypeSelector";
import {createUser, deleteUser, editUser, getUsers} from "../../../store/actionCreators/user.actionCreator";
import {IUser} from "../../../models/user";
import MaterialTable from "material-table";
import {getRoles} from "../../../store/actionCreators/role.actionCreator";
import {getDepartments} from "../../../store/actionCreators/department.actionCreator";
import {IRole} from "../../../models/role";
import {IDepartment} from "../../../models/department";
import {useSnackbar} from "notistack";

export interface IUserData {
    fullName: string,
    email: string,
    departmentId: number,
    roleId: number,
}

interface IData {
    name: string,
    email: string,
    department: string,
    role: string
}

const Users = () => {
    const dispatch = useDispatch();
    const [allUsers, setAllUsers] = useState<Array<IUser>>([]);
    const [allRoles, setAllRoles] = useState<Array<IRole>>([]);
    const [allDepartments, setAllDepartments] = useState<Array<IDepartment>>([]);
    const [data, setData] = useState<Array<IData>>([]);
    const [modal, setModal] = useState<boolean>(false)
    const [fullName, setFullName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [departmentSelectedOption,setDepartmentSelectedOption] = useState<string>("")
    const [roleSelectedOption,setRoleSelectedOption] = useState<string>("")
    const [edit, setEdit] = useState<boolean>(false);
    const [modalRemove, setModalRemove] = useState<boolean>(false);
    const [userEmailRemove, setUserEmailRemove] = useState<string>("");
    const {enqueueSnackbar} = useSnackbar()

    const clear = () => {
        setFullName("")
        setEmail("")
        setDepartmentSelectedOption("hidden");
        setRoleSelectedOption("hidden");
    }

    const toggle = () => {
        clear()
        setModal(!modal);
        setEdit(false)
    }

    const toggleRemove = () => {
        setModalRemove(!modalRemove);
        setUserEmailRemove("");
    }

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getRoles())
        dispatch(getDepartments())
    },[dispatch])

    const { isLoading, isSaving, success, error, successMessage, errorMessage, users } = useTypeSelector(
        (state) => state.user
    );

    const {userLogged} = useTypeSelector(
        (state) => state.user
    );
    const {roles} = userLogged;
    const role = roles[0];

    const {applicationRoles} = useTypeSelector(
        (state) => state.role
    )

    const {departments} = useTypeSelector(
        (state) => state.department
    )

    useEffect(() => {
        const userData : Array<IData> = [];

        if (users){
            setAllUsers(users);
        }

        if (allUsers.length > 0){
            allUsers.forEach(function (user) {
                userData.push({name: user.fullName, email: user.email, department: user.department,
                    role: user?.roles[0]})
            })

            setData(userData);
        }
    },[users, allUsers])

    useEffect(() => {
        if (applicationRoles) {
            setAllRoles(applicationRoles)
        }

        if (departments){
            setAllDepartments(departments)
        }
    },[applicationRoles,departments])

    const userHandler = () => {
        const userData : IUserData = {
            fullName,
            email,
            departmentId: parseInt(departmentSelectedOption) ,
            roleId: parseInt(roleSelectedOption)
        }

        if (edit){
            dispatch(editUser(userData))
        }else{
            dispatch(createUser(userData))
        }
    }

    useEffect(() => {
        if(success){
            enqueueSnackbar(successMessage, {
                variant: 'success'
            })
        }

        if (error){
            enqueueSnackbar(errorMessage, {
                variant: 'error'
            })
        }

    },[success, error, successMessage, errorMessage, enqueueSnackbar])


    useEffect(() => {
        if (success && !edit){
            clear();
        }
    },[success,edit])

    const editUserData = (email: string) => {
        allUsers.forEach((user) => {
            if (user.email === email){
                setFullName(user.fullName)
                setEmail(user.email)
                allDepartments.forEach((department) => {
                    if(department.description === user.department){
                        setDepartmentSelectedOption(department.id+'');
                    }
                })
                allRoles.forEach((rol) => {
                  if (rol.name === user.roles[0]){
                      setRoleSelectedOption(rol.id+"");
                  }
                })
            }
        })
        setModal(true);
        setEdit(true);
    }

    const removeUser = (email: string) => {
        setUserEmailRemove(email);
        setModalRemove(true);
    }

    const removeHandler = () => {
        dispatch(deleteUser(userEmailRemove));
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <CardTitle tag="h4" className="col-md-9">Lista de Utilizadores</CardTitle>
                                    <Button disabled={role !== "SystemAdministrator"} className="ml-auto mr-3" onClick={toggle}>
                                        <i className='fas fa-solid fa-plus mr-2'/>  Adicionar novo
                                    </Button>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    isLoading={isLoading}
                                    title="Lista de Utilizadores"
                                    columns={[
                                        {title: 'Nome', field: 'name'},
                                        {title: 'E-mail', field: 'email'},
                                        {title: 'Departamento', field: 'department'},
                                        {title: 'Função', field: 'role'}
                                    ]}
                                    data={data}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Adicionar utilizador',
                                            isFreeAction: true,
                                            disabled: role !== "SystemAdministrator",
                                            onClick: () => toggle()
                                        },
                                        {
                                            icon: 'edit',
                                            tooltip: 'Editar',
                                            disabled: role !== "SystemAdministrator",
                                            onClick: (event, rowData: any) => editUserData(rowData.email)
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            disabled: role !== "SystemAdministrator",
                                            onClick: (event, rowData:any) => removeUser(rowData.email)
                                        }
                                    ]}
                                    options={{
                                        exportButton: true,
                                        actionsColumnIndex: -1,
                                        loadingType: "overlay",
                                        showTitle: false,
                                        headerStyle: {fontWeight: 'bold'}
                                    }}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                >
                    {edit ?
                        <>Editar dados do utilizador</>
                        :
                        <>Adicior utilizador</>
                    }
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col className="pr-md-1" md="6">
                                <FormGroup>
                                    <label>Nome Completo</label>
                                    <Input
                                        placeholder="Nome Completo"
                                        type="text"
                                        value={fullName}
                                        onChange={(e) => {setFullName(e.target.value);}}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="pl-md-1" md="6">
                                <FormGroup>
                                    <label>Email</label>
                                    <Input
                                        disabled={edit}
                                        placeholder="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => {setEmail(e.target.value);}}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col className="pr-md-1" md="6">
                                <FormGroup>
                                    <label>Departamento</label>
                                    <Input
                                        type={"select"}
                                        value={departmentSelectedOption}
                                        onChange={(e) => {setDepartmentSelectedOption(e.target.value);}}
                                    >
                                        <option value="" hidden>Selecione departamento</option>

                                        {
                                            allDepartments.map((department,key) => {
                                                return (
                                                    <option value={department.id} key={key}>{department.description}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col className="pr-md-1" md="6">
                                <FormGroup>
                                    <label>Função</label>
                                    <Input
                                        type={"select"}
                                        value={roleSelectedOption}
                                        onChange={(e) => {setRoleSelectedOption(e.target.value);}}
                                    >
                                        <option value="" hidden>Selecione função</option>
                                        {
                                            allRoles.map((role,key) => {
                                                return (
                                                    <option key={key} value={role.id}>{role.description}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>

                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                disabled={!fullName || !email ||
                                !departmentSelectedOption || !roleSelectedOption}
                                onClick={userHandler}
                            >
                                {edit ?
                                    <>Editar</>
                                    :
                                    <>Adicionar</>
                                }
                            </Button>
                    }

                    <Button onClick={toggle} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>

            {/*Modal for remove user*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                isOpen={modalRemove}
                toggle={toggleRemove}
            >
                <ModalHeader
                    toggle={toggleRemove}
                >
                    Remover utilizador
                </ModalHeader>
                <ModalBody>
                    <p>Deseja remover utilizador ?</p>
                </ModalBody>
                <ModalFooter>
                    {
                        isSaving ?
                            <Button
                                block
                                className="mr-3 col-md-2"
                            >
                                <Spinner size="sm" color="light"/>
                            </Button>
                            :
                            <Button
                                className="mr-3"
                                onClick={removeHandler}
                            >
                                Remover
                            </Button>
                    }

                    <Button onClick={toggleRemove} color="danger">Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
};

export default Users;
